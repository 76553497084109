import React, { Component } from "react";
import PropTypes from 'prop-types';

//Defines the look of the table
import 'datatables.net-dt/css/dataTables.dataTables.min.css';
import 'datatables.net-searchbuilder-dt/css/searchBuilder.dataTables.min.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';

import "../../css/tables.css"

import $ from "jquery";

require('datatables.net-select-dt');
require('datatables.net-searchbuilder-dt');
require('datatables.net-buttons-dt');

// right mouse button when checking mouse down
const RIGHT_BUTTON = 3;

/**
 * This react class is a a wrapper around the table. Since the table is not
 * react based, its lifecycle needs to be managed manually.
 */
class DatatablesComponent extends Component
{
    static propTypes = {
        // The columns selected for this table; passed in from parent
        columns: PropTypes.arrayOf(PropTypes.object).isRequired,

        // The data to display, required except that we open the flight menu
        // table before the data gets here most of the time; passed in from 
        // parent
        data: PropTypes.arrayOf(PropTypes.object),

        // The tableOptions object is a collection of properties to be passed to
        // the datatable upon creation; passed in from parent
        tableOptions: PropTypes.object.isRequired,

        // Table group id; passed in from parent
        tableId: PropTypes.string.isRequired,
    };

    /**
     * Constructs the DataTable component with no table yet.
     *
     * @param {*}               props
     * @param {array of object} props.columns      all columns available for 
     *                                             this table
     * @param {array of object} props.data         data to display; may be
     *                                             omitted for the Flight Menu
     * @param {object}          props.tableOptions properties to use in creating
     *                                             the table
     * @param {string}          props.tableId      table identifier
     */
    constructor(props)
    {
        super(props);

        // Store the reference to the datatable
        this.dataTable = null;
    }

    /**
     * If this react component has been created, then make the table and set up
     * mouse listener.
     */
    componentDidMount()
    {
        this.$el = $(this.el);

        this.dataTable = this.$el.DataTable({
            columns: this.props.columns,
            data: this.props.data,
            // single selection for tables
            select: {
                info: false,
                style: "single",
            },
            headerCallback: function() {
                let api = this.api();
                api.columns().iterator("column", function(settings, column) {
                    if (settings.aoColumns[column].tooltip !== undefined)
                    {
                        api.column(column).header().setAttribute("title",
                            settings.aoColumns[column].tooltip);
                    }
                });
            },
            ...this.props.tableOptions,
        });

        // Select the row when right clicking as well
        let table = this.dataTable;
        table.on("mousedown", "tr", function(ev){
            // select row if right clicked
            if ((ev.originalEvent.which === RIGHT_BUTTON) &&
                ev.currentTarget.id)
            {
                table.row("#" + ev.currentTarget.id).select();
            }
        });

        /**
         * Wrap data table with a new div element to add horizontal scrolling to it instead of using the
         * default scrollX prop as this default one creates an overlay on the table which requires
         * dataTable.columns.adjust() function call whenever the table width changes.
         * This helps resize the table headers without having to call dataTable.columns.adjust() function.
         * https://datatables.net/forums/discussion/69061/header-does-not-resize-when-using-scrollx
         */
        const tableEl = this.$el[0];
        const customTableWrapper = document.createElement("div");
        customTableWrapper.className = "custom-datatable-wrapper";
        tableEl.parentNode.insertBefore(customTableWrapper, tableEl);
        customTableWrapper.appendChild(tableEl);
    }

    /**
     * If this react component will be removed, destroy the table, since it
     * is untracked.
     */
    componentWillUnmount()
    {
        this.dataTable.destroy(true);
    }

    /**
     * Clear the data in the table and populate it with new data, maintaining
     * position and selection.
     */
    reloadTableData = (data) => {
        const selectedRow = this.dataTable.row({ selected: true }).id(true);
        this.dataTable.clear();
        this.dataTable.rows.add(data);
        if (selectedRow)
        {
            this.dataTable.row(selectedRow).select();
        }
        this.dataTable.draw(false);
    };

    /**
     * Get the object representing the current SearchBuilder state.
     */
    getFilterDetails = () => {
        return this.dataTable.searchBuilder.getDetails();
    };

    /**
     * Set the search builder state to recreate a stored filter.
     *
     * @param {obj} filterState  The search builder state to recreate
     */
    setFilterDetails = (filterState) => {
        if (filterState) {
            // deep clone from immutable redux value
            const clonedState = JSON.parse(JSON.stringify(filterState));
            this.dataTable.searchBuilder.rebuild(clonedState);
        } else {
            this.dataTable.searchBuilder.rebuild({});
        }
    };

    /**
     * Gets currently set number of records per page of the table.
     *
     * @return {number} number of records per page
     */
    getPageLength = () => {
        return this.dataTable.page.len();
    };

    /**
     * Control when this react component should be updated. Default to false
     * since the table is what matters.
     *
     * @param {object} unusedNextProps    the properties for the next render
     * @param {object} unusedNextState    the state for the next render
     *
     * @return false, always
     */
    shouldComponentUpdate(unusedNextProps, unusedNextState)
    {
        return false;
    }

    /**
     * Set up the table for rendering.
     *
     * @return {JSX.element} The complete formatted table for rendering
     */
    render()
    {
        return (
          <div>
            <table
              className="cell-border compact display"
              id={this.props.tableId}
              width="100%"
              cellSpacing="0"
              ref={ (el) => (this.el = el) }
            />
          </div>
        );
    }
}

export default DatatablesComponent;
