import React, { Component } from "react"
import Table from "react-bootstrap/Table";
import PageHeader from "./PageHeader";

const vers = require("./version.json");


/*
 * Serves as the help page, providing explanations of various components and
 * terms.
 */
class Help extends Component
{
    /*
     * Props: none
     */
    constructor(props)
    {
        super(props);

        this.state = {};
    }

    /*
     * Set the title to help distinguish tabs between the tables and help page.
     */
    componentDidMount() {
      document.title = "Predeparture Rerouting Interface - Help";
    }

    /*
     * Set up the layout of the help page for rendering.
     */
    render()
    {
        return (
          <>
            <PageHeader titleOnly={true} />
              <div className="main-frame">

                <h2>Collaborative Digital Departure Rerouting (CDDR) Data Elements</h2>
                <ul>
                    <li> <a href="#cddr_main">Data Elements in the TOS Table</a></li>
                    <li> <a href="#main_filter">Additional Elements available for filtering TOS Table</a></li>
                    <li> <a href="#routemenu">Data Elements in the Flight&apos;s Route Menu</a></li>
                </ul>

                <br/>
                <h3 id="cddr_main">Data Elements in the TOS Table</h3>
                <p>NOTE: Use of <b>&ldquo;Top&rdquo;</b> indicates the TOS algorithm&apos;s 
                   best ranked alternative (i.e., not filed) route item that 
                   meets the criteria for candidacy as a TOS route.
                </p>
                <Table bordered size="sm" className="help-table">
                  <thead>
                    <tr>
                      <th>Column Header</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td>
                        <p>1st Z Sector ETC</p>
                        <p>2nd Z Sector ETC</p>
                        <p>3rd Z Sector ETC</p>
                        <p>4th Z Sector ETC</p>
                      </td>
                      <td>
                        <p>Indicates the Estimated Time of Crossing of a given route across a sector. The number
                        indicates the order of the sector the route is intersecting. These times reflect the
                        filed route unless the &ldquo;Route Types includes&rdquo; filter is set on the table,
                        in which case they reflect the &ldquo;top&rdquo; route of the filtered type.</p>
                        <p>If there is more than one route with the selected source, then the route selected
                        will be the one with the lowest delay + RTC value or, if multiple routes
                        have the same delay + RTC value, then the route with the lowest route distance.</p>
                        <p>See <a href="#route_types">Route Types</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>1st Z Sector Name</p>
                        <p>2nd Z Sector Name</p>
                        <p>3rd Z Sector Name</p>
                        <p>4th Z Sector Name</p>
                      </td>
                      <td>
                        <p>Indicates the name of the sector that a given route is intersecting. The number
                        indicates the order of the sector the route is intersecting. These names reflect the
                        filed route unless the &ldquo;Route Types includes&rdquo; filter is set on the table,
                        in which case they reflect the &ldquo;top&rdquo; route of the filtered type.</p>
                        <p>If there is more than one route with the selected source, then the route selected
                        will be the one with the lowest delay + RTC value or, if multiple routes
                        have the same delay + RTC value, then the route with the lowest route distance.</p>
                        <p>See <a href="#route_types">Route Types</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td>AC Type</td>
                      <td>International Civil Aviation Organization (ICAO) type
                        of aircraft, e.g. B738 for a Boeing model 737-800.
                      </td>
                    </tr>
                    <tr>
                      <td>Airline </td>
                      <td>Flight&apos;s airline</td>
                    </tr>
                    <tr>
                      <td>Altitude</td>
                      <td>Filed altitude for flight</td>
                    </tr>
                    <tr>
                      <td>AMA Taxi Duration</td>
                      <td>Duration of time on the AMA (Active Movement Area)
                        <ul>
                          <li>Departures: OFF time - spot time</li>
                          <li>Arrivals: spot time - ON time</li>
                        </ul>
                        OFF time, ON time, and spot time are the actual time of the flight when
                        it uses the runway or crossed the spot; otherwise these times are predicted
                        by the DIP system.
                      </td>
                    </tr>
                    <tr>
                      <td>Coord State</td>
                      <td>List of ATC and Flight Operator coordination states:
                        <ul>
                          <li>ATC Excluded - A route is excluded due to Traffic Management Initiatives
                            entered by ATC</li>
                          <li>ATC Unable - ATC was unable to approve a Flight Operator&apos;s route
                            request</li>
                          <li>Default - Flight Operator has not requested a reroute</li>
                          <li>Saved - The Flight Operator has saved routes to submit to SWIM</li>
                          <li>Reroute Requested - Flight Operator has requested a reroute (via a
                            flight&apos;s right mouse menu)</li>
                          <li>ATC Approved - ATC has approved a Flight Operator&apos;s route request
                            (via a flight&apos;s right mouse menu)</li>
                          <li>Reroute Filed - A new flight plan has been filed for a route that
                            was Requested and Approved</li>
                          <li>Pending - Temporary state until the CDDR Table is updated, after a
                            Flight Operator reroute request, ATC approval, or ATC unable has been
                            selected or undone</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>CPDLC</td>
                      <td>Indicates whether the flight is equipped and able to receive Controller-Pilot
                        Data Link Communication-Departure Clearance (CPDLC-DCL) clearances from the Tower.
                        <ul>
                          <li>Y - equipped</li>
                          <li>Blank - not equipped</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Dep Fix</td>
                      <td>Departure fix that aircraft is planned to fly to, or has flown to.</td>
                    </tr>
                    <tr>
                      <td>Dep Gate</td>
                      <td>Departure Gate that aircraft is predicted to fly to, or has flown to. If
                        departure fix is not mapped to a departure gate, then this column contains
                        the departure fix.</td>
                    </tr>
                    <tr>
                      <td>Dest</td>
                      <td>Destination airport of the flight.</td>
                    </tr>
                    <tr>
                      <td>EDCT</td>
                      <td>Expect Departure Clearance Time assigned to the flight as part of a
                        Ground Delay Program (GDP), Airspace Flow Program (AFP), or
                        Collaborative Trajectory Options Program (CTOP).</td>
                    </tr>
                    <tr>
                      <td>Eligibility State</td>
                      <td>List of eligibility states:
                        <ul>
                          <li>Excluded - Flight is excluded from CDDR by ATC</li>
                          <li>Potential - Default state</li>
                          <li>Candidate - a reroute that would be advantageous has been found</li>
                          <li>Expired - Flight&apos;s filed departure time is past the ATC
                            required minimum notification time; see
                            <a href="#time_to_expiration">Time to Expiration</a></li>
                          <li>Not Applicable - Not available for predeparture rerouting coordination</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>EOBT </td>
                      <td>Earliest Off-Block Time provided by the airline, providing the best prediction
                        of when a flight will be ready to push back.</td>
                    </tr>
                    <tr>
                      <td>Equipment</td>
                      <td>Displays equipment on board, as reported in the flight data, e.g. G, L.</td>
                    </tr>
                    <tr>
                      <td id="etot">ETOT</td>
                      <td>Estimated Take Off Time for the filed route as computed by the Terminal Scheduler.
                        <p />
                        This represents the system&apos;s best estimated time for take off.
                      </td>
                    </tr>
                    <tr>
                      <td>Flight ID</td>
                      <td>Callsign of the flight. Clicking the Flight ID will open a table of all route options.</td>
                    </tr>
                    <tr>
                      <td>Flight Status</td>
                      <td>Indicates the aircraft state and generalized location:
                        <ul>
                          <li>Cancelled - The flight has been cancelled by the airline</li>
                          <li>Departed - A departure aircraft is airborne</li>
                          <li>Enroute_Arr - An arrival aircraft is in the enroute airspace</li>
                          <li>In - An arrival aircraft is parked at the gate</li>
                          <li>In_Queue - A departure aircraft is inside the queue detection box of the assigned runway</li>
                          <li>In_Ramp - An arrival is taxiing in the Ramp</li>
                          <li>On - An arrival aircraft has landed</li>
                          <li>On_Final - An arrival aircraft is on final approach</li>
                          <li>Out - A departure has pushed back and/or released brakes and is taxiing in the Ramp</li>
                          <li>Pushback - A departure aircraft is pushing back</li>
                          <li>Return_to_Gate - A departure is returning to the gate</li>
                          <li>Scheduled_In - An arrival aircraft has not been tracked by DIP yet</li>
                          <li>Scheduled_Out - A departure aircraft has not pushed back out yet</li>
                          <li>Suspended - The flight should have already departed and no flight updates have been received</li>
                          <li>Taxiing_AMA - A departure aircraft is taxiing on the airport movement area</li>
                          <li>Term_Area_Arr - An arrival aircraft is inside the terminal airspace</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Ground Stop</td>
                      <td>Displays &ldquo;Y&rdquo; when a flight is subject to a ground stop, blank otherwise.</td>
                    </tr>
{/*                    <tr>
                      <td>Impact</td>
                      <td>Condition impacting the filed or initial route
                        <ul>
                          <li>NONE - No impact</li>
                          <li>ASH - Volcanic ash</li>
                          <li>CONV - Convective weather</li>
                          <li>ICE - Icing</li>
                          <li>IFR - Instrument flight rules</li>
                          <li>MTN OBSCN - Mountain obscuration</li>
                          <li>PCPN - Precipitation</li>
                          <li>TS - Thunderstorms</li>
                          <li>TURB - Turbulence</li>
                          <li>UNK - Unknown</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Impact Level</td>
                      <td>Severity of condition impacting the filed or initial route
                        <ul>
                          <li>NONE - No impact</li>
                          <li>LT-MOD - Light to Moderate</li>
                          <li>MOD - Moderate</li>
                          <li>MOD-SEV - Moderate to Severe</li>
                          <li>SEVERE - Severe impact (displayed with red background)</li>
                        </ul>
                      </td>
                    </tr>
*/}
                    <tr>
                      <td id="in_delay">IN Delay</td>
                      <td>Compares the flight&apos;s predicted IN to the scheduled IN of the filed route.
                        The predicted IN leverages the Flight Operator&apos;s en-route prediction which includes wind miles.<br/>
                          predictedInDelay = ETOT + (LIBT - LTOT) - SIBT<br/>
                          where:
                          <ul>
                            <li>ETOT = Estimated Take Off Time</li>
                            <li>LIBT = Latest In Block Time</li>
                            <li>LTOT = Latest Take Off TIme</li>
                            <li>SIBT = Scheduled In Block Time</li>
                          </ul>
                          A positive value means there is a delay while a negative value means the flight may arrive
                          earlier than expected. This value is only displayed for the filed route.
                      </td>
                    </tr>
                    <tr>
                      <td>Int&apos;l</td>
                      <td>Displays &ldquo;Y&rdquo; when the destination is international. Displays nothing
                        when it is not.</td>
                    </tr>
                    <tr>
                      <td>LOBT</td>
                      <td>Latest Off Block Time. The estimated pushback time received from an airline either
                        directly or through a CDM message to TFMS.</td>
                    </tr>
                    <tr>
                      <td>Long On Board </td>
                      <td>Elapsed time that an aircraft has been on the airport surface but not at a gate.
                        <ul>
                          <li>For arrivals, the timer starts when the flight lands (ALDT) and stops once the
                            flight arrives at the gate (AIBT).</li>
                          <li>For departures, the timer starts when the flight pushes back from the gate (AOBT)
                            and ends when the flight takes off (ATOT) or returns to the gate.</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Major Carrier</td>
                      <td>Indicate a group of flight operators managed by the same parent company.
                        For example, the following callsigns belong to American: AAL, JIA, ENY, ASH,
                        PSA (non-exhaustive list).</td>
                    </tr>
                    <tr>
                      <td>MIT</td>
                      <td>Displays &ldquo;xxM&rdquo; when a flight is subject to a Miles In Trail
                        restriction or &ldquo;xxT&rdquo; when a flight is subject to a Minutes In Trail restriction.</td>
                    </tr>
                    <tr>
                      <td>Num TOS Cand</td>
                      <td>Count of the number of TOS candidate routes detected.</td>
                    </tr>
                    <tr>
                      <td>Num TOS Sub</td>
                      <td>Count of the number of TOS reroutes that have been requested.</td>
                    </tr>
                    <tr>
                      <td id="off_delay">OFF Delay</td>
                      <td>ETOT minus Undelayed Departure Fix Time for the filed route
                        <p /><i>See <a href="#etot">ETOT</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Origin</td>
                      <td>Airport of origin for departure or arrival aircraft.</td>
                    </tr>
                    <tr>
                      <td>Parking Gate</td>
                      <td>Parking gate number assigned to the aircraft by the airline or user.</td>
                    </tr>
                    <tr>
                      <td>POBT</td>
                      <td>Proposed Off Block Time, also known as P-Time. This is the departure time
                        that is in the filed flight plan.</td>
                    </tr>
                    <tr>
                      <td>Route of Flight</td>
                      <td>Filed route reported by Federal Aviation Administration (FAA) system.</td>
                    </tr>
                    <tr>
                      <td id="route_types">Route Types</td>
                      <td>Displays the types of alternate route for this flight:
                        <ul>
                          <li>CDR - Coded Departure Route</li>
                          <li>Escape - route used during escape plan with expected lower altitude for a predefined route segment</li>
                          <li>ADSB - route requiring ADSB equipment</li>
                          <li>SWIM - comes from SWIM and doesn&apos;t match any CDR</li>
                        </ul> 
                      </td>
                    </tr>
                    <tr>
                      <td>Rwy</td>
                      <td>Estimated (e.g., &ldquo;E18L&rdquo;) or actual runway (e.g., &ldquo;18L&rdquo;)
                        based on flight plan, departure fix, actual location of aircraft, or user input.</td>
                    </tr>
                    <tr>
                      <td>Rwy Time</td>
                      <td>The DIP-predicted runway time or actual runway time for a flight.
                        <ul>
                          <li>For arrivals, this is landing time.</li>
                          <li>For departures, this is takeoff time.</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Scratch Pad</td>
                      <td>Indicates the latest text entry.<br/>
                          <em>Note: Clicking in the field will open the Scratch Pad
                            window that enables the user to enter free text as well
                            as see previous entries made by another facility</em> 
                      </td>
                    </tr>
                    <tr>
                      <td>SOBT</td>
                      <td>Scheduled Off Block Time. The time the flight is scheduled by the airline to leave the departure gate.</td>
                    </tr>
                    <tr>
                      <td>SWIM Status</td>
                      <td>Indicates if a flight has route options in the SWIM feed
                        <ul>
                          <li>Not Included - Route not submitted as TOS</li>
                          <li>Saved - Route is available to Flight Operator to send out to SWIM as TOS</li>
                          <li>Pending Save - Route option save has been requested</li>
                          <li>Pending Cancel - Route option has been requested removed</li>
                          <li>In SWIM - Route has been submitted to SWIM as TOS</li>
                          <li>Formerly Included - Route used to be sumitted as TOS. This route is no longer available in SWIM</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Taxi Time</td>
                      <td>The predicted or actual amount of time spent taxiing on the airport surface.
                        <ul>
                          <li>For arrivals, this is the amount of time between landing and arriving at the gate.</li>
                          <li>For departures, this is the amount of time from pushback to takeoff.</li>
                        </ul>
                        If predicted, the time will be prefixed with an &ldquo;E&rdquo; to represent that the time is estimated.
                      </td>
                    </tr>
                    <tr>
                      <td id="time_to_expiration">Time to Expiration</td>
                      <td>Countdown timer (in minutes) to the threshold prior to
                         P-time (POBT). Upon expiration at 0 min, flight Eligibility
                         State changes to Expired. Active for certain Center TOS Approvers
                         only.</td>
                    </tr>
                    <tr>
                      <td>TMI Info</td>
                      <td>Displays whether the flight is subject to an APREQ, EDCT, GS, MIT/MINIT, or Fix Closure.
                        <p />Sample values:
                        <ul>
                          <li>&ldquo;Ahh:mm&rdquo; - has Approval Request (APREQ) release time</li>
                          <li>&ldquo;Ehh:mm&rdquo; - has Expect Departure Clearance Time (EDCT)</li>
                          <li>&ldquo;APRQ&rdquo; - subject to APREQ but no release time</li>
                          <li>&ldquo;GS&rdquo; - subject to Ground Stop (GS)</li>
                          <li>&ldquo;nnM&rdquo; - subject to Miles-In-Trail (MIT)</li>
                          <li>&ldquo;nnT&rdquo; - subject to Minutes-In-Trail (MINIT)</li>
                          <li>&ldquo;FixClsd&rdquo; - subject to fix closure</li>
                        </ul>
                        <p />Kitchen sink examples:
                        <ul>
                          <li>E22:17 APRQ GS 10M FixClsd</li>
                          <li>A22:25 E22:17 GS 10M FixClsd</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Top Add nm</td>
                      <td>Additional nautical miles of the top alternative route compared to the filed route.
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Top Agg &lt;airport&gt; Del Sav</p>
                        <p>Top Agg &lt;carrier1&gt; Del Sav</p>
                        <p>Top Agg &lt;carrier2&gt; Del Sav</p>
                        <p>Top Agg &lt;fleet&gt; Del Sav</p>
                        <p>Top Agg &lt;tracon&gt; Del Sav</p>
                      </td>
                      <td>Aggregated runway delay savings for the airport, carrier, sub-carrier (if applicable), fleet, or TRACON.
                        <p />
                        This is the sum of predicted Delay Savings (minutes) for the flight and subsequent departures
                        within the airport, carrier, sub-carrier (if applicable), fleet, or TRACON. That is, rerouting this
                        flight on the given route is predicted to save a total of X minutes. (Applies to departures scheduled
                        to takeoff within 1 hour of this flight.)
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Top Avg&lt;airport&gt; Del Sav</p>
                        <p>Top Avg&lt;carrier1&gt; Del Sav</p>
                        <p>Top Avg&lt;carrier2&gt; Del Sav</p>
                        <p>Top Avg&lt;fleet&gt; Del Sav</p>
                        <p>Top Avg&lt;tracon&gt; Del Sav</p>
                      </td>
                      <td>Average runway delay savings for the airport, carrier, sub-carrier (if applicable), fleet, or TRACON.
                        <p />On average, each departure in the airport, carrier, sub-carrier (if applicable), fleet, or TRACON
                         which is scheduled to take off within an hour of this flight is predicted to save X minutes.
                      </td>
                    </tr>
                    <tr>
                      <td id="top_route_id">Top Alt Rte ID</td>
                      <td>The route ID (e.g. DFWSEA1N) of the top alternative route.
                        <br/>This effectively is displaying the most likely alternative route given the delay prediction.
                        <p />The top alternative route is chosen based on the following criteria:
                        <ol>
                          <li>Route approved by ATC, if one exists</li>
                          <li>Route requested by a Flight Operator, if one exists</li>
                          <li>Route with Candidate eligibility status, if one exists</li>
                          <li>Least worse alternate route, if one exists</li>
                        </ol>
                        <p>If there is more than one route within a given criteria (e.g. there are
                          multiple routes with Candidate eligibility status), then the route selected
                          will be the one with the lowest delay + RTC value or, if multiple routes
                          have the same delay + RTC value, then the route with the lowest route distance.</p>
                        <p> Example 1: Route 2 will be selected because it is a Candidate route
                        </p>
                        <pre style={{marginLeft: "10px"}}>Filed route delay = 500<br/>
                          TOS Route 1 - Potential : Distance = 1200nm, RTC = 110, delay = 400<br/>
                          TOS Route 2 - Candidate : Distance = 1000nm, RTC = 100, delay = 300
                        </pre>
                        <p>
                          Example 2: Route 1 will be selected because it has the lowest delay + RTC
                        </p>
                        <pre style={{marginLeft: "10px"}}>Filed route delay = 500<br/>
                          TOS Route 1 - Candidate : Distance = 1200nm, RTC = 100, delay = 300<br/>
                          TOS Route 2 - Candidate : Distance = 1000nm, RTC = 110, delay = 300
                        </pre>
                        <p>
                          Example 3: Route 2 will be selected because both routes have the same
                          lowest delay + RTC but Route 2&apos;s distance is lower
                        </p>
                        <pre style={{marginLeft: "10px"}}>Filed route delay = 500<br/>
                          TOS Route 1 - Candidate : Distance = 1200nm, RTC = 100, delay = 300<br/>
                          TOS Route 2 - Candidate : Distance = 1000nm, RTC = 100, delay = 300
                        </pre>
                        <p>
                          Note: When there are none of TOS Approved, TOS Requested, TOS Candidate,
                          or TOS Potential, this field remains empty. Also, the scheduler only
                          computes delays within an N-minute look-ahead window; any route outside
                          of this window will not have a Top Route.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Top Alt Route</td>
                      <td>Full procedure of the top alternative route.
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Top Dep Fix</td>
                      <td>Terminal Fix for the top alternative route.
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Top Dep Gate</td>
                      <td>Terminal Gate for the top alternative route.
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Top ETOT</td>
                      <td>Same as ETOT but for the top alternative route.
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a>, <a href="#etot">ETOT</a></i>
                      </td>
                    </tr>
{/*                    <tr>
                      <td>Top Impact</td>
                      <td>Indicates the type of hazard intersecting a route as reported by SIGMET or AIRMET (CWA):
                        <ul>
                          <li>NONE - No hazard is intersecting the route</li>
                          <li>ASH - Volcanic ash</li>
                          <li>CONV - Convective weather</li>
                          <li>ICE - Icing</li>
                          <li>IFR - Instrument flight rules</li>
                          <li>MTN OBSCN - Mountain obscuration</li>
                          <li>PCPN - Precipitation</li>
                          <li>TS - Thunderstorms</li>
                          <li>TURB - Turbulence</li>
                          <li>UNK - Unknown</li>
                        </ul>
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Top Impact Level</td>
                      <td>Indicates the level of the hazard intersecting a route as reported by SIGMET or AIRMET (CWA):
                        <ul>
                          <li>NONE - No hazard is intersecting the route</li>
                          <li>LT-MOD - Light to Moderate level</li>
                          <li>MOD - Moderate level</li>
                          <li>MOD-SEV - Moderate to Severe level</li>
                          <li>SEVERE - Severe level (displayed with red background)</li>
                        </ul>
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a></i>
                      </td>
                    </tr>
*/}
                    <tr>
                      <td>Top IN Delay</td>
                      <td>Adds the sum of the flight&apos;s predicted IN of the filed route and
                        the IN delay savings of the top alternative route. A positive value means
                        there is a delay while a negative value means the flight can arrive earlier
                        than expected.
                        <p />
                        <p>Example: If the flight has 20 minutes of predicted IN delay on the filed
                          route and can save 10 minutes on the TOS route, then the predicted IN delay
                          on the TOS route is 20 - 10 = 10 minutes.</p>
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a>, <a href="#in_delay">IN Delay</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Top IN Del Sav</td>
                      <td>Adds the OFF delay savings of the top alternative route and that route&apos;s
                        additional flight time (without wind miles); that is, 
                         <br/>additionalFlightTime = (altRouteDistance - filedRouteDistance) / filedSpeed.
                        <p />
                        <p>Example: If the flight&apos;s OFF delay savings is 10 minutes but the flight is
                          flying an additional 5 minutes of flight time, the IN delay savings = 10 - 5 = 5</p>
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a>, <a href="#top_off_del_sav">Top OFF Del Sav</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Top OFF Delay</td>
                      <td>Same as OFF Delay but for the top alternative route.
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a>, <a href="#off_delay">OFF Delay</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td id="top_off_del_sav">Top OFF Del Sav</td>
                      <td><p>Total delay savings at the runway for the top alternative route.  This equals
                        the residual delay that could be saved for the flight if that flight took the top
                        alternative route instead of the filed route, by taking into account the total
                        surface delay driven by both the terminal delay and the surface delay.</p>
                        <p>Computation: (ETOT for filed route - UTOT for filed route) minus (ETOT for alt route - UTOT for alt route)</p>
                        <p>where UTOT = Undelayed Take Off Time</p>
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a>, <a href="#etot">ETOT</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Top Prob Del Sav&nbsp;&gt;&nbsp;RTC</td>
                      <td>Probability that the Delay Savings will be above the Relative Trajectory Cost (RTC)
                        value for the top alternative route.
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Top Prob Del Sav&nbsp;&gt;&nbsp;0</td>
                      <td>Probability that the Delay Savings will be above 0 for the top alternative route.
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Top RTC</td>
                      <td>Relative Trajectory Cost computed for the top alternative route; minutes.
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Top RTC #</td>
                      <td>Relative Trajectory Cost as submitted by the Flight Operator to SWIM. This RTC is for informational purpose only.
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Top Rte Type</td>
                      <td>Source of the top alternative route. 
                        <ul>
                          <li>CDR - Coded Departure Route</li>
                          <li>Escape - route used during escape plan with expected lower altitude for a predefined route segment</li>
                          <li>ADSB - route requiring ADSB equipment</li>
                          <li>SWIM - comes from SWIM and doesn&apos;t match any CDR</li>
                        </ul> 
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Top Rwy</td>
                      <td>Runway for the top alternative route.
                        <p />
                        <i>See <a href="#top_route_id">Top Alt Rte ID</a></i>
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <br/>
                <h3 id="main_filter">Additional Elements available for filtering TOS Table</h3>
                <p>NOTE: All of the fields above can be used in filtering the table, these fields
                  can be used for filtering, but are not available for display.
                </p>
                <Table bordered size="sm" className="help-table">
                  <thead>
                    <tr>
                      <th>Column Header</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Has APREQ</td>
                      <td>Flight has an APREQ.</td>
                    </tr>
                    <tr>
                      <td>Has EDCT</td>
                      <td>Flight has an EDCT.</td>
                    </tr>
                    <tr>
                      <td>Has Scratch Pad</td>
                      <td>Flight&apos;s scratchpad contains entries.</td>
                    </tr>
                  </tbody>
                </Table>

                <br/>
                <h3 id="routemenu">Data Elements in the Flight&apos;s Route Menu</h3>

                <p>This table lists the routes for a specific flight, one row per route.
                  The first row will be populated with data related to the current filed
                  route and each additional row will be populated with data related to each
                  route. The row for the route corresponding to the &ldquo;Top Route&rdquo;,
                  if set, will be highlighted in mint green.</p>
                <Table bordered size="sm" className="help-table">
                  <thead>
                    <tr>
                      <th>Column Header</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td>
                        <p>1st Z Sector ETC</p>
                        <p>2nd Z Sector ETC</p>
                        <p>3rd Z Sector ETC</p>
                        <p>4th Z Sector ETC</p>
                      </td>
                      <td>Indicates the Estimated Time of Crossing of a given route across a sector. The number
                        indicates the order of the sector the route is intersecting.</td>
                    </tr>
                    <tr>
                      <td>
                        <p>1st Z Sector Name</p>
                        <p>2nd Z Sector Name</p>
                        <p>3rd Z Sector Name</p>
                        <p>4th Z Sector Name</p>
                      </td>
                      <td>Indicates the name of the sector that a given route is intersecting. The number
                        indicates the order of the sector the route is intersecting.</td>
                    </tr>
                    <tr>
                      <td>Add nm</td>
                      <td>Additional Nautical Miles of the given alternative route compared to the
                        Route of Flight.
                        <p />The field remains empty for the filed route of flight.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Agg &lt;airport&gt; Del Sav</p>
                        <p>Agg Carrier Del Sav</p>
                        <p>Agg &lt;fleet&gt; Del Sav</p>
                        <p>Agg &lt;tracon&gt; Del Sav</p>
                      </td>
                      <td>
                        <p>Aggregated runway delay savings for the airport, carrier, fleet, or TRACON</p>
                        <p>This is the sum of predicted Delay Savings (minutes) for the TOS flight and subsequent departures
                          for the airport, carrier, fleet, or TRACON. That is, rerouting this
                          flight on the given route is predicted to save flights a total of X minutes. (Applies to departures
                          scheduled to takeoff within 1 hour of this flight.)</p>
                      </td>
                    </tr>
                    <tr>
                      <td>Alt 4 RTC</td>
                      <td>Displays the initial capped altitude on an escape route.</td>
                    </tr>
                    <tr>
                      <td>Coord State</td>
                      <td>List of coordination state:
                      <ul>
                          <li>ATC Excluded - A route is excluded due to Traffic Management Initiatives
                            entered by ATC</li>
                          <li>ATC Unable - ATC was unable to approve a Flight Operator&apos;s route
                            request (via a flight&apos;s right mouse menu)</li>
                          <li>Default - Flight Operator has not requested a reroute</li>
                          <li>Saved - The Flight Operator has saved routes to submit to SWIM</li>
                          <li>Reroute Requested - Flight Operator has requested a reroute (via a
                            flight&apos;s right mouse menu)</li>
                          <li>ATC Approved - ATC has approved a Flight Operator&apos;s route request
                            (via a flight&apos;s right mouse menu)</li>
                          <li>Reroute Filed - A new flight plan has been filed for a route that
                            was Requested and Approved</li>
                          <li>Pending - Temporary state until the CDDR Table is updated, after a
                            Flight Operator reroute request, ATC approval, or ATC unable has been
                            selected or undone</li>
                        </ul>
                        The field remains empty for the filed/initial route of the flight.
                      </td>
                    </tr>
                    <tr>
                      <td>Dep Gate</td>
                      <td>Terminal area gate for the route.</td>
                    </tr>
                    <tr>
                      <td>Dist nm</td>
                      <td>Distance in nautical miles of the route.</td>
                    </tr>
                    <tr>
                      <td>Eligibility State</td>
                      <td>List of eligibility states:
                        <ul>
                          <li>Excluded - Flight is excluded from CDDR by ATC</li>
                          <li>Potential - Default state</li>
                          <li>Candidate - a reroute that would be advantageous has been found</li>
                          <li>Expired - Flight&apos;s filed departure time is past the ATC
                            required minimum notification time; see
                            <a href="#time_to_expiration">Time to Expiration</a></li>
                          <li>Not Applicable - Not available for predeparture rerouting coordination</li>
                        </ul>
                        <p>The field remains empty for the filed/initial route of the flight.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>ETOT</td>
                      <td>Estimated Take Off Time for the route as computed by the Terminal Scheduler.
                        <p />
                        This represents the system&apos;s best estimated time for take off.
                      </td>
                    </tr>
{/*                   <tr>
                      <td>Impact</td>
                      <td>Indicates the type of hazard intersecting a route as reported by SIGMET or AIRMET (CWA):
                        <ul>
                          <li>NONE - No hazard is intersecting the route</li>
                          <li>ASH - Volcanic ash</li>
                          <li>CONV - Convective weather</li>
                          <li>ICE - Icing</li>
                          <li>IFR - Instrument flight rules</li>
                          <li>MTN OBSCN - Mountain obscuration</li>
                          <li>PCPN - Precipitation</li>
                          <li>TS - Thunderstorms</li>
                          <li>TURB - Turbulence</li>
                          <li>UNK - Unknown</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Impact Level</td>
                      <td>Indicates the level of the hazard intersecting a route as reported by SIGMET or AIRMET (CWA):
                        <ul>
                          <li>NONE - No hazard is intersecting the route</li>
                          <li>LT-MOD - Light to Moderate level</li>
                          <li>MOD - Moderate level</li>
                          <li>MOD-SEV - Moderate to Severe level</li>
                          <li>SEVERE - Severe level (displayed with red background)</li>
                        </ul>
                      </td>
                    </tr>
*/}
                    <tr>
                      <td>IN Delay</td>
                      <td>For the filed route, displays the IN Delay (see the IN Delay description in the TOS CDDR above).
                        <p />
                        For an alternative route, this displays the sum of the flight&apos;s predicted IN of the
                        filed route and the IN delay savings of the top alternative route (see Top IN Delay description
                        in the CDDR Table above).
                      </td>
                    </tr>
                    <tr>
                      <td>IN Del Sav</td>
                      <td>Adds the OFF delay savings of the top alternative route and that route&apos;s additional
                        flight time (without wind miles) (see Top IN Del Sav description in the CDDR Table above).</td>
                    </tr>
                    <tr>
                      <td>Included in TOS</td>
                      <td>Display check boxes for users to select or unselect.
                        <p>Checking a box will include the route in a TOS message that
                          is then made available to the Flight Operator via a TOS submittal service.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>OFF Delay</td>
                      <td>ETOT minus UTOT for either the Route of Flight or the alternative route,
                        where UTOT = Undelayed Take Off Time.</td>
                    </tr>
                    <tr>
                      <td>OFF Del Sav</td>
                      <td>Delay savings at the runway, which equals the residual delay that could be saved for the
                        flight if that flight took the given alternative route instead of the filed route of flight.
                        <p />
                        Computation: (ETOT for filed route - UTOT for filed route) minus (ETOT for alt route - UTOT for alt route)
                        <p />
                        where UTOT = Undelayed Take Off Time
                      </td>
                    </tr>
                    <tr>
                      <td>Prob Del Sav &gt; RTC</td>
                      <td>Probability that the Delay Savings will be above the Relative Trajectory Cost (RTC) value.</td>
                    </tr>
                    <tr>
                      <td>Prob Del Sav &gt; 0</td>
                      <td>Probability that the Delay Savings will be above 0.</td>
                    </tr>
                    <tr>
                      <td>Route</td>
                      <td>Displays the route of either Route of Flight or the CDR.</td>
                    </tr>
                    <tr>
                      <td>Route ID</td>
                      <td>Displays the following values:
                        <ul>
                          <li>CDR code (e.g. DFWSEA1N)</li>
                          <li>Filed - when this route has been filed</li>
                          <li>Initial - when the route has not been filed yet</li>
                          <li>SWIM### - when the route doesn&apos;t match any CDR</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>RTC</td>
                      <td>Relative Trajectory Cost computed for the given alternative route (compared to filed route).
                        <p />
                        The field remains empty for the filed route of the flight.
                      </td>
                    </tr>
                    <tr>
                      <td>RTC #</td>
                      <td>Relative Trajectory Cost as submitted by the Flight Operator to SWIM. This RTC is for informational purpose only.</td>
                    </tr>
                    <tr>
                      <td>Rte Type</td>
                      <td>Displays the type of alternate route:
                        <ul>
                          <li>CDR - Coded Departure Route</li>
                          <li>Escape - route used during escape plan with expected lower altitude for a predefined route segment</li>
                          <li>ADSB - route requiring ADSB equipment</li>
                          <li>SWIM - comes from SWIM and doesn&apos;t match any CDR</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Rwy</td>
                      <td>Predicted runway for either the Route of Flight or the alternative route.</td>
                    </tr>
                    <tr>
                      <td>SWIM Status</td>
                      <td>List of SWIM states:
                        <ul>
                          <li>Not Included - Route not submitted as TOS</li>
                          <li>Saved - Route is available to Flight Operator to send out to SWIM as TOS</li>
                          <li>Pending Save - Route option save has been requested</li>
                          <li>Pending Cancel - Route option removal has been requested</li>
                          <li>In SWIM - Route has been submitted to SWIM as TOS</li>
                          <li>Formerly Included - Route used to be submitted as TOS. This route is no longer available in SWIM</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Valid Rte</td>
                      <td>Displays TRUE or FALSE to indicate whether the route that is listed matches
                        an agreed-upon route, such as a CDR. FALSE may be listed when the Flight Operator
                        submits a TOS route to SWIM that doesn&apos;t match an agreed-upon route.</td>
                    </tr>
                  </tbody>
                </Table>

                <version-text--color>Version {vers.number}</version-text--color>

            </div>
          </>
        )
    }
}

export default Help;
