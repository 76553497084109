import { LOGOUT } from 'tos-web-admin-client';

import  * as types from '../constants/ActionTypes';


/**
 * This file contains the Redux reducer for the Show Me table.
 */

/**
 * The default state for show me table reducer state object: everything empty.
 * The Client gufis are those that are being set by the D-STBO Client.  They
 * have to be stored separately because when the TWT is opened at the Client's
 * request, RESET_SETTINGS is eventually called - which wipes out the alerts.
 * We want the alertGufis to be cleared but not the Client gufis, so keep track
 * of them separately.
 */
const DEFAULT_SHOW_ME_REDUCER_STATE = {
    globalSearch: "",
    alertGufis: [],
    alertClientGufis: [],
    emptySearch: false,
}

/**
 * The reducer function used to update the Show Me table.
 *
 * @param {object} state   The current Redux state, with initial value set to
 *                         the default.
 * @param {object} action  The Redux action object containing the action type
 *                         and the data necessary for the specified action.
 */
export default function showMeReducer(state = DEFAULT_SHOW_ME_REDUCER_STATE,
                                      action)
{
    // Redux changes need to be immutable. So for every change three things
    // need to happen.
    // 1. Shallow copy of containing objects.
    // 2. Apply desired modification to copy.
    // 3. Bundle the changes in with the rest of the unmodified state and
    //    return it. The return should be a copy of the current state, but with
    //    the desired modification.
    switch (action.type)
    {
        case types.ADD_SHOW_ME_FLIGHTS: {
            const moreKeys = [ ...state.alertGufis, ...action.payload.gufis ];
            return {
                ...state,
                alertGufis: moreKeys,
            };
        }
        case types.ADD_SHOW_ME_CLIENT_FLIGHTS: {
            // Store the keys from the Client, but also add them to the
            // "normal" alert keys, making sure they aren't duplicating
            // what is already being alerted.
            const possibleNewKeys = [ ...action.payload.gufis ];
            const existingAlertKeys = [ ...state.alertGufis ];
            const existingClientKeys = [ ...state.alertClientGufis ];
            const newAlertKeys = possibleNewKeys.filter(possibleKey =>
                     !existingAlertKeys.includes(possibleKey));
            const newClientKeys = possibleNewKeys.filter(possibleKey =>
                     !existingClientKeys.includes(possibleKey));

            return {
                ...state,
                alertGufis: existingAlertKeys.concat(newAlertKeys),
                alertClientGufis: existingClientKeys.concat(newClientKeys),
            };
        }
        case types.REMOVE_SHOW_ME_FLIGHT: {
            const lessKeys = state.alertGufis.filter(
                val => val !== action.payload.gufi);
            const lessClientKeys = state.alertClientGufis.filter(
                val => val !== action.payload.gufi);
            return {
                ...state,
                alertGufis: lessKeys,
                alertClientGufis: lessClientKeys,
            };
        }
        case types.CLEAR_SHOW_ME_FLIGHTS: {
            return {
                ...state,
                alertGufis: [],
                alertClientGufis: [],
            };
        }
        case types.UPDATE_GLOBAL_SEARCH: {
            let isEmpty = state.emptySearch;
            // if nothing is being searched for, don't highlight the field
            // as nothing found
            if (action.payload.newValue === "")
            {
                isEmpty = false;
            }

            return {
                ...state,
                globalSearch: action.payload.newValue,
                emptySearch: isEmpty,
            };
        }
        case types.SET_EMPTY_SEARCH: {
            return {
                ...state,
                emptySearch: action.payload.emptySearch,
            };
        }
        case types.RESET_SETTINGS: {
            // Settings are reset when the window is loaded - which would wipe
            // out any Client gufis.  So don't clear the Client gufis, and
            // initialize the alert gufis with the Client gufis
            const clientGufis = [ ...state.alertClientGufis ];
            return {
                ...state,
                ...DEFAULT_SHOW_ME_REDUCER_STATE,
                alertGufis: [ ...clientGufis ],
                alertClientGufis: [ ...clientGufis ],
            };
        }
        case LOGOUT:
        {
            return {
                ...state,
                ...DEFAULT_SHOW_ME_REDUCER_STATE,
            };
        }

        default:
            return state;
    }
}
