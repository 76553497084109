import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import $ from "jquery";

import DatatablesComponent from "./DatatablesComponent";
import { updateFilter } from "../../actions";
import { DATA_TABLE_FILTER_COLUMNS } from "../../column_utils/columnDefaults";
import { COLUMNS } from "../../constants/ColumnField";
import { SEARCH_TYPE } from "../../constants/TableConstants";
import { makeQueryString } from "../../utils/tableUtils";

/**
 * This class is used to create and manage a single hidden table used to
 * trigger alerts.
 */
class AlertTable extends PureComponent
{
    static propTypes = {
        // The data to display; from redux
        departures: PropTypes.arrayOf(PropTypes.object).isRequired,

        // Filters defined for the table; from redux
        filters: PropTypes.object,

        // Action creator to update the filter for the table; from redux
        updateFilter: PropTypes.func,

        // id for alert table
        tableId: PropTypes.string,

        // id for alert table filter button
        buttonId: PropTypes.string,
    };

    /**
     * Constructs the AlertTable, which is used to generate a data table to
     * allow a filter to be created. If flights are in the table, they qualify
     * to alert the user.
     *
     * @param {*} props    none passed in from parent
     */
    constructor(props)
    {
        super(props);

        this.dataTableRef = React.createRef();
        this.allColumns = [];
        this.tableId = props.tableId;

        let saveProfile = this.saveProfile.bind(this);
        this.lastFilterSaved = "";

        // The tableOptions object is a collection of properties to be passed
        // to the datatable upon creation.
        this.tableOptions = {
            // Set the DOM layout, [B]utton, [f]ind, p[r]ocessing, [t]able,
            // [l]ength, and [p]agination.
            dom: '<"' + this.tableId + '"Bt>',
            // Scrolling and paging aren't normally used in this hidden table,
            // but are nice when showing the table during debugging
            scrollX: true,
            paging: true,
            pagingType: 'simple_numbers',
            buttons: {
                buttons: [
                    {
                        extend: "searchBuilder",
                        config: {
                            liveSearch: true,
                        },
                        attr: {
                            title: "Edit Filtered Flights filter",
                            id: props.buttonId,
                        },
                        // mostly copied from searchbuilder, but tweaked to
                        // show the builder without showing the table.
                        // e - event object that triggered the event
                        // dt - Datatables.net API instance for host table
                        // node - jQuery instance for button node
                        // config - button's configuration
                        action: function (e, dt, node, config) {  
                            config._searchBuilder.rebuild($("#" + props.tableId).DataTable().searchBuilder.getDetails())
                            
                            let sb = config._searchBuilder.getNode();
                            sb.css("position", "fixed");
                            this.popover(sb, {
                                closeButton : false,
                                span: 'container',
                                collectionLayout: 'fixed'
                            });
                            // Need to redraw the contents to calculate the
                            // correct positions for the elements
                            if (config._searchBuilder.s.topGroup !== undefined) {
                                config._searchBuilder.s.topGroup.dom.container.trigger("dtsb-redrawContents");
                            }
                            if (config._searchBuilder.s.topGroup.s.criteria.length === 0) {
                                $('.' + $.fn.dataTable.Group.classes.add).click();
                            }
                        },
                     }
                ],
                dom: {
                    collection: {
                        className: "alertFilter",
                    },
                },
            },
            columnDefs: [{
                targets: "_all",
                defaultContent: "",
                className: "dt-body-nowrap",
                searchable: false,
            }],
            rowId: "gufi",
            // We really just use half of the built-in state saving. Their
            // loading will end if it sees that the number of columns has
            // changed. But the saving is good for getting the current
            // configuration, which we pull off when we need it and use
            // redux to store and load things from there.
            stateSave: true,
            stateSaveCallback: function(unusedSettings, data) {
                saveProfile(data);
            },
            stateLoadCallback: function(unusedSettings) {
                // Don't load from the saved state, so don't bother loading
                return {};
            },
            stateLoadParams: function(unusedSettings, unusedData) {
                // Don't load from the saved state, use what was set in options
                return false;
            },
            language: {
                searchBuilder: {
                    button: "Edit Filter",
                    deleteTitle: "Delete row",
                    leftTitle: "Outdent row",
                    rightTitle: "Indent row",
                    title: 'Use the Indent (>) and Outdent (<) buttons to apply a combination of "and" and "or" qualifiers.',
                    add: "Add Field",
                },      
                paginate: {
                    previous: 'Previous',
                    next: 'Next',
                }
            },
        };
        this.updateColumns();
    }

    /**
     * Saves the settings information into redux.
     */
    saveProfile(data)
    {
        // keep updating redux until we've stabilized
        let currentFilter = makeQueryString(data.searchBuilder);
        if (this.lastFilterSaved !== currentFilter)
        {
            this.props.updateFilter(this.tableId, data.searchBuilder);
            this.lastFilterSaved = currentFilter;
        }
    }

    /**
     * Used to pass new data to the table.
     */
    updateTableData = () =>
    {
        this.dataTableRef.current.reloadTableData(this.props.departures);
    };

    /**
     * If this react component has been created, then add any saved filters.
     */
    componentDidMount()
    {
        // load any stored filters
        if (this.props.filters)
        {
            this.dataTableRef.current.setFilterDetails(this.props.filters);
        }
        else
        {
            this.dataTableRef.current.setFilterDetails({});
        }
    }

    /**
     * If props were updated, update the table data.
     *
     * @param {object} prevProps  the props object before the update
     */
    componentDidUpdate(prevProps)
    {
        // Need to refresh the table when departure info changes.
        if (this.props.departures !== prevProps.departures)
        {
            this.updateTableData();
        }
    }

    /**
     * Converts the saved list of columns to display to the column definitions
     * for Datatables. Adds other "filterable" columns that are not being
     * shown as hidden fields. Updates all columns to use "filter2" type to
     * retrieve their search value, to avoid being limited by "searchable"
     * attributes of the general search function.
     */
    updateColumns()
    {
        let filterColumns = [ COLUMNS.FLIGHT_ID_COLUMN,
            ...DATA_TABLE_FILTER_COLUMNS ];
        let allColumns = [];

        // Allow all filterable columns
        filterColumns.forEach(filterColumn => {
            if ((filterColumn.disabled === undefined) ||
                !filterColumn.disabled)
            {
                allColumns.push({
                    ...filterColumn,
                    searchBuilder: {
                        orthogonal: {
                            search: SEARCH_TYPE,
                        },
                    },
                });
            }
        });

        this.allColumns = allColumns;
    }

    /**
     * Set up the table wrapper for rendering. Although in this case, nothing is
     * actually shown.
     *
     * @return {JSX.element} The container/wrapper for the formatted table
     */
    render()
    {
        // Note: ref in DatatablesComponent initializes dataTableRef with access
        // to this DatatablesComponent instance.
        return (
            <div className="alertTableHidden">
                <DatatablesComponent
                    ref={this.dataTableRef}
                    columns={this.allColumns}
                    data={this.props.departures}
                    tableOptions={this.tableOptions}
                    tableId={this.tableId}
                />
            </div>
        );
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state The current redux state
 * @param {object} ownProps Currently passed in props
 *
 * @return {object} The desired redux state properties mapped to props
 */
const mapStateToProps = (state, ownProps) =>
{
    return {
        departures: state.dataReducer.departures,
        filters: state.tablesReducer.filterPerTable[ownProps.tableId],
    };
};

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    updateFilter,
};

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(AlertTable));
