import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { closeHotlineModal } from "../../actions";
import { HOTLINE } from "../../constants/NavBarConstants";


/**
 * Serves as a modal dialog for displaying hotline help.
 */
class HotlineModal extends Component
{
    static propTypes = {
        // Flag to indicate if the modal should be shown; from redux
        isOpen: PropTypes.bool.isRequired,

        // Action creator to close the hotline dialog; from redux
        closeHotlineModal: PropTypes.func.isRequired,
    };

    /**
     * Processes the close button being pressed by closing the modal.
     *
     * @param {object} unusedEvent    the button click event
     */
    processClose = (unusedEvent) => {
        this.props.closeHotlineModal();
    }

    /**
     * Displays the Hotline Modal.
     *
     * @return {JSX.element} A single modal dialog for rendering
     */
    render()
    {
        if (this.props.isOpen)
        {
            return (
                <Modal id="hotline-modal" show={true}
                    onHide={this.processClose} animation={false}
                >
                    <Modal.Header>
                        <Modal.Title>{HOTLINE}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <h4>For help, call or text:  817-601-5584</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                            onClick={this.processClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }
        else
        {
            return null;
        }
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state  The current redux state
 *
 * @return {object} The desired redux state properties mapped to props
 */
const mapStateToProps = (state) =>
{
    return {
        isOpen: state.modalReducer.hotlineModalOpen,
    };
};

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    closeHotlineModal,
};

export default connect(mapStateToProps, mapDispatchToProps) (HotlineModal);
