import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";

/**
 * Serves as a modal dialog for displaying system messages that can not be
 * cleared by the user.
 */
class MsgModal extends Component
{
    static propTypes = {
        // Message to display, from redux
        userMessage: PropTypes.string,
    };

    /**
     * Displays the Modal with our options and content. It will actually only
     * be open and shown if there is a message to show.
     *
     * @return {JSX.element} A single modal dialog for rendering
     */
    render()
    {
        if (this.props.userMessage.length > 0)
        {
            return (
                <Modal show={true} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>User Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.props.userMessage}</Modal.Body>
                </Modal>
            );
        }
        else
        {
            return null;
        }
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state The current redux state
 *
 * @return {object} The desired redux state properties mapped to props
 */
const mapStateToProps = (state) =>
{
    return {
        userMessage: state.dataReducer.userMessage,
    };
};

export default connect(mapStateToProps)(MsgModal);
