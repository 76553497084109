import $ from "jquery";

import { COLUMN_TYPES } from "../constants/ColumnTypes";

// This set contains the column types that utilize custom sorting logic.
let columnTypeWithCustomSortSet = new Set();

/**
 * This is a collection of data and functions used to sort the table row data
 * based on type associated with the column being sorted on.
 */

/**
 * Sorts ascending with null or blank at the end.
 */
function blankAsc(str1, str2) {
    if (str1 == null || str1 == "")
        return 1;
    if (str2 == null || str2 == "")
        return -1;
    return ((str1 < str2) ? -1 : ((str1 > str2) ? 1 : 0));
}

/**
 * Sorts descending with null or blank at the end.
 */
function blankDesc(str1, str2) {
    if (str1 == null || str1 == "")
        return 1;
    if (str2 == null || str2 == "")
        return -1;
    return ((str1 < str2) ? 1 : ((str1 > str2) ? -1 : 0));
}

/**
 * Adds any required custom sorting for dataTables columns. This would be any
 * pre-deformatting methods to convert into orderable values (preferred if
 * needed because it only has to modify each value once), or any custom
 * ascending and descending methods.
 */
export function addOrdering()
{
    // For sorting values with letters then numbers, pad the number portion
    // so they are all the same length (e.g. AAL123 becomes AAL0000123 and
    // AAL1423 becomes AAL0001423 for sorting).
    if (!$.fn.dataTable.ext.type.order[COLUMN_TYPES.ALPHA_NUM + "-pre"])
    {
        columnTypeWithCustomSortSet.add(COLUMN_TYPES.ALPHA_NUM);

        const zeros = "0000000";
        $.fn.dataTable.ext.type.order[COLUMN_TYPES.ALPHA_NUM + "-pre"] =
            function(data) 
            {
                let deform = data;
                if (data)
                {
                    const matched = data.match(/(\D*)(\d+)(\D?)/);
                    if (matched)
                    {
                        const padNumber = zeros + matched[2];
                        deform = matched[1] + padNumber.substring(padNumber.length -
                            zeros.length) + matched[3];
                    }
                }
                return deform;
            }
    }

    if (!$.fn.dataTable.ext.type.order[COLUMN_TYPES.ALPHA_NUM + "-asc"]) {
        $.fn.dataTable.ext.type.order[COLUMN_TYPES.ALPHA_NUM + "-asc"] = blankAsc;
    }
  
    if (!$.fn.dataTable.ext.type.order[COLUMN_TYPES.ALPHA_NUM + "-desc"]) {
        $.fn.dataTable.ext.type.order[COLUMN_TYPES.ALPHA_NUM + "-desc"] = blankDesc;
    }

    // for sorting runway names, convert L, C, R to number values (e.g 31L
    // becomes 310, while 31R becomes 312 for sorting).
    if (!$.fn.dataTable.ext.type.order[COLUMN_TYPES.RUNWAY + "-pre"])
    {
        columnTypeWithCustomSortSet.add(COLUMN_TYPES.RUNWAY);

        const runwayOrderArray = ["L", "C", "R"];
        $.fn.dataTable.ext.type.order[COLUMN_TYPES.RUNWAY + "-pre"] =
            function(data) {
                let newform = data;
                if (data)
                {
                    const lastChar = data.charAt(data.length - 1);
                    if (runwayOrderArray.includes(lastChar))
                    {
                        let newOrderNum = runwayOrderArray.indexOf(lastChar);
                        newform = data.slice(0, -1) + newOrderNum;
                    }
                }
                return newform;
            }
    }

    if (!$.fn.dataTable.ext.type.order[COLUMN_TYPES.RUNWAY + "-asc"]) {
        $.fn.dataTable.ext.type.order[COLUMN_TYPES.RUNWAY + "-asc"] = blankAsc;
    }

    if (!$.fn.dataTable.ext.type.order[COLUMN_TYPES.RUNWAY + "-desc"]) {
        $.fn.dataTable.ext.type.order[COLUMN_TYPES.RUNWAY + "-desc"] = blankDesc;
    }
}

/**
 * Adds the default sorting for the data table columns
 */
export function addDefaultOrdering(columnType)
{
    // The default ascending sorts logic.
    // This ensuring that rows with empty/null strings are placed at the bottom of the page.
    if (!$.fn.dataTable.ext.type.order[columnType + "-asc"]) {
        $.fn.dataTable.ext.type.order[columnType + "-asc"] = blankAsc;
    }

    // The default descending sorts logic.
    // This ensuring that rows with empty/null strings are placed at the bottom of the page.
    if (!$.fn.dataTable.ext.type.order[columnType + "-desc"]) {
        $.fn.dataTable.ext.type.order[columnType + "-desc"] = blankDesc;
    }
}

/**
 * Determine whether the given column type has the custom sort logic.
 * @param {string} columnType 
 * @returns {bool} True if the given column type has the custom sort logic. Otherwise, false.
 */
export function hasCustomSort(columnType)
{
    return columnTypeWithCustomSortSet.has(columnType);
}
