import React, { PureComponent } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import $ from "jquery";

import { closeAlertTypesModal, updateAlertTypes } from "../../actions";
import { ATC_APPROVER_ROLES, FO_ROLES } from "../../constants/Constants";
import { ALERT_FILTER_BUTTON,
         ALERT_FILTER_TABLE_ID,
         CANDIDATE_FILTER_BUTTON,
         CANDIDATE_FILTER_TABLE_ID } from "../../constants/TableConstants";
import { makeQueryString } from "../../utils/tableUtils";


/**
 * Serves as a modal dialog for updating alert type (visual or audible)
 * settings.
 */
class AlertTypesModal extends PureComponent
{
    static propTypes = {
        // The currenly logged in user information; from redux
        user: PropTypes.object.isRequired,

        // Flag to indicate if the modal should be shown; from redux
        isOpen: PropTypes.bool.isRequired,

        // Alert visual and audio setting; from redux
        alertTypes: PropTypes.objectOf(PropTypes.bool).isRequired,

        // Filter for the filtered flight alert; from redux
        alertFilter: PropTypes.object,

        // Filter for the candidate flight alert; from redux
        candidateFilter: PropTypes.object,

        // Action creator to close the alert dialog; from redux
        closeAlertTypesModal: PropTypes.func.isRequired,

        // Action creator to update the alert flags; from redux
        updateAlertTypes: PropTypes.func.isRequired,
    };

    /**
     * Constructs the alert type editor modal.
     *
     * @param {*} props  none passed from parent
     */
    constructor(props)
    {
        super(props);

        let user = this.props.user;
        let showCoordinationAlerts = true;
        let showCandidateAlerts = false;
        let showProposalAlerts = false;
        let showRequestAlerts = false;

        if (FO_ROLES.includes(user.role))
        {
            showCandidateAlerts = true;
            showProposalAlerts = true;
            if ((user.roleUser.endsWith("RAMP") || user.readOnly) && this.props.alertTypes.candidateVisual) {
                console.log("Ramp user, turning off candidate & proposal alerts");
                this.props.updateAlertTypes({
                    ...this.props.alertTypes,
                    candidateVisual: false,
                    proposalVisual: false,
                });
            }
        }
        else if (ATC_APPROVER_ROLES.includes(user.role))
        {
            showRequestAlerts = true;
        }
        else if (user.role === "ATC_TRACON")
        {
            showCoordinationAlerts = false;
        }

        this.state = {
            showCoordinationAlerts,
            coordinationVisual : this.props.alertTypes.coordinationVisual,
            coordinationAudible : this.props.alertTypes.coordinationAudible,
            showCandidateAlerts,
            candidateVisual: this.props.alertTypes.candidateVisual,
            candidateAudible: this.props.alertTypes.candidateAudible,
            showProposalAlerts,
            proposalVisual: this.props.alertTypes.proposalVisual,
            proposalAudible: this.props.alertTypes.proposalAudible,
            showRequestAlerts,
            requestVisual: this.props.alertTypes.requestVisual,
            requestAudible: this.props.alertTypes.requestAudible,
            filteredVisual: this.props.alertTypes.filteredVisual,
            filteredAudible : this.props.alertTypes.filteredAudible,
            scratchPadVisual: this.props.alertTypes.scratchPadVisual,
            scratchPadAudible : this.props.alertTypes.scratchPadAudible,
            filterString: "",
            filterButton: null,
            candidateFilterString: "",
            candidateFilterButton: null,
        };

        this.filterTable = false;
        this.candidateFilterTable = false;
        this.savedFilter = null;
        this.savedCandidateFilter = null;
    }

    /**
     * Processes the change when a checkbox is clicked. Check for dependent field if turning off.
     *
     * @param {object} event checkbox clicking information
     */
    handleChange = (event) =>
    {
        const target = event.target;
        const stateUpdate = {
            [target.name]: target.checked
        };
        // if there is a dependent field, reset it if necessary
        if (target.dataset.dependent && !target.checked)
        {
            stateUpdate[target.dataset.dependent] = false;
        }

        this.setState(stateUpdate);
    }

    /**
     * Processes the Apply button being pressed by dispatching the new settings,
     * then closing the modal.
     *
     * @param {object} unusedEvent   button click event
     */
    processApply = (unusedEvent) => {
        let newAlertTypes = { ...this.props.alertTypes };

        newAlertTypes.candidateVisual = this.state.candidateVisual;
        newAlertTypes.candidateAudible = this.state.candidateAudible;
        newAlertTypes.proposalVisual = this.state.proposalVisual;
        newAlertTypes.proposalAudible = this.state.proposalAudible;
        newAlertTypes.requestVisual = this.state.requestVisual;
        newAlertTypes.requestAudible = this.state.requestAudible;
        newAlertTypes.coordinationVisual = this.state.coordinationVisual;
        newAlertTypes.coordinationAudible = this.state.coordinationAudible;
        newAlertTypes.filteredVisual = this.state.filteredVisual;
        newAlertTypes.filteredAudible = this.state.filteredAudible;
        newAlertTypes.scratchPadVisual = this.state.scratchPadVisual;
        newAlertTypes.scratchPadAudible = this.state.scratchPadAudible;

        // Pass along the new flag values and close the modal
        this.props.updateAlertTypes(newAlertTypes);
        this.props.closeAlertTypesModal();
    }

    /**
     * Processes the cancel or close buttons being pressed by reverting the
     * filter and closing the modal.
     *
     * @param {object} unusedEvent   close button or background click, not used
     */
    processCancel = (unusedEvent) => {
        if (this.filterTable && this.savedFilter)
        {
            this.filterTable.DataTable().searchBuilder.rebuild(this.savedFilter);
        }
        if (this.candidateFilterTable && this.savedCandidateFilter)
        {
            this.candidateFilterTable.DataTable().searchBuilder.rebuild(this.savedCandidateFilter);
        }
        this.props.closeAlertTypesModal();
    }

    /**
     * If the props or state have changed, make sure the filter string is
     * updated and showing only if it should be.
     *
     * @param {object} prevProps properties from before the update
     */
    componentDidUpdate(prevProps)
    {
        // save some stuff when modal is opening
        if (!prevProps.isOpen && this.props.isOpen)
        {
          let filteredFlightAlertTable =
                  $.fn.DataTable.isDataTable("#" + ALERT_FILTER_TABLE_ID) &&
                  $("#" + ALERT_FILTER_TABLE_ID).DataTable();
          let filterButton = null;
          if (filteredFlightAlertTable)
          {
              this.savedFilter = filteredFlightAlertTable.searchBuilder.getDetails();
              filterButton = $("#" + ALERT_FILTER_BUTTON);
              $("#flightAlertFilter").append(filterButton);
              this.filterTable = $("#" + ALERT_FILTER_TABLE_ID);
          }

          let candidateFlightAlertTable =
                  $.fn.DataTable.isDataTable("#" + CANDIDATE_FILTER_TABLE_ID) &&
                  $("#" + CANDIDATE_FILTER_TABLE_ID).DataTable();
          let candidateFilterButton = null;
          if (candidateFlightAlertTable)
          {
              this.savedCandidateFilter = candidateFlightAlertTable.searchBuilder.getDetails();
              candidateFilterButton = $("#" + CANDIDATE_FILTER_BUTTON);
              $("#candidateAlertFilter").append(candidateFilterButton);
              this.candidateFilterTable = $("#" + CANDIDATE_FILTER_TABLE_ID);
          }

          // Gets the current redux values to initialize boxes when opening
          // the modal
          this.setState({
              candidateAudible : this.props.alertTypes.candidateAudible,
              candidateVisual : this.props.alertTypes.candidateVisual,
              proposalAudible : this.props.alertTypes.proposalAudible,
              proposalVisual : this.props.alertTypes.proposalVisual,
              requestAudible : this.props.alertTypes.requestAudible,
              requestVisual : this.props.alertTypes.requestVisual,
              coordinationVisual : this.props.alertTypes.coordinationVisual,
              coordinationAudible : this.props.alertTypes.coordinationAudible,
              filteredVisual : this.props.alertTypes.filteredVisual,
              filteredAudible : this.props.alertTypes.filteredAudible,
              scratchPadVisual : this.props.alertTypes.scratchPadVisual,
              scratchPadAudible : this.props.alertTypes.scratchPadAudible,
              filterButton: filterButton,
              candidateFilterButton: candidateFilterButton,
          });
        }

        this.setState({
            filterString: makeQueryString(this.props.alertFilter),
            candidateFilterString: makeQueryString(this.props.candidateFilter, "All candidates shown"),
        });

        // If there is flight filtering to do, show the div; otherwise hide it
        if (!this.state.filteredVisual && !this.state.filteredAudible)
        {
            $("#flightAlertFilter").hide();
        }
        else
        {
            $("#flightAlertFilter").show();
        }

        // put the buttons back for next time when closing modal
        if (prevProps.isOpen && !this.props.isOpen)
        {
            if (this.state.filterButton)
            {
                $("#AlertTable_wrapper .AlertTable .dt-buttons").append(this.state.filterButton);
            }

            if (this.state.candidateFilterButton)
            {
                $("#CandidateAlertTable_wrapper .CandidateAlertTable .dt-buttons").append(this.state.candidateFilterButton);
            }
            
            this.setState({
              filterButton: null,
              candidateFilterButton: null,
            });
        }
    }

    /**
     * Displays the Modal with our options and content.
     *
     * @return {JSX.element} A single modal dialog for rendering
     */
    render()
    {
        if (this.props.isOpen)
        {
            return (
                <Modal id="alert-type-modal" show={true}
                    onHide={this.processCancel} animation={false}
                >
                    <Modal.Header>
                        <Modal.Title>Alert Types</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert-types--container" >
                            {this.state.showCandidateAlerts &&
                              <>
                                <label>Candidates:</label>
                                <div className="alert-types--checkbox" >
                                    <input type="checkbox"
                                        checked={this.state.candidateVisual}
                                        onChange={this.handleChange}
                                        id="candidateVisual"
                                        name="candidateVisual"
                                        data-dependent="candidateAudible"
                                        disabled
                                    />
                                    <label htmlFor="candidateVisual">Visual</label>
                                </div>
                                <div className="alert-types--checkbox" >
                                    <input type="checkbox"
                                        checked={this.state.candidateAudible}
                                        onChange={this.handleChange}
                                        id="candidateAudible"
                                        name="candidateAudible"
                                        disabled={!this.state.candidateVisual}
                                    />
                                    <label htmlFor="candidateAudible">Audible</label>
                                </div>
                                <div id="candidateAlertFilter">
                                  <p>Current additional filter for candidates: { this.state.candidateFilterString }</p>
                                  { /* this.candidateFilterButton added by componentDidUpdate */ }
                                </div>
                              </>
                            }
                            {this.state.showProposalAlerts &&
                              <>
                                <label>Opportunities:</label>
                                <div className="alert-types--checkbox" >
                                    <input type="checkbox"
                                        checked={this.state.proposalVisual}
                                        onChange={this.handleChange}
                                        id="proposalVisual"
                                        name="proposalVisual"
                                        disabled
                                        data-dependent="proposalAudible"
                                    />
                                    <label htmlFor="proposalVisual">Visual</label>
                                </div>
                                <div className="alert-types--checkbox" >
                                    <input type="checkbox"
                                        checked={this.state.proposalAudible}
                                        onChange={this.handleChange}
                                        id="proposalAudible"
                                        name="proposalAudible"
                                        disabled={!this.state.proposalVisual}
                                    />
                                    <label htmlFor="proposalAudible">Audible</label>
                                </div>
                              </>
                            }
                            {this.state.showRequestAlerts &&
                              <>
                                <label>Reroute Requested:</label>
                                <div className="alert-types--checkbox" >
                                    <input type="checkbox"
                                        checked={this.state.requestVisual}
                                        onChange={this.handleChange}
                                        id="requestVisual"
                                        name="requestVisual"
                                        data-dependent="requestAudible"
                                        disabled
                                    />
                                    <label htmlFor="requestVisual">Visual</label>
                                </div>
                                <div className="alert-types--checkbox" >
                                    <input type="checkbox"
                                        checked={this.state.requestAudible}
                                        onChange={this.handleChange}
                                        id="requestAudible"
                                        name="requestAudible"
                                        disabled={!this.state.requestVisual}
                                    />
                                    <label htmlFor="requestAudible">Audible</label>
                                </div>
                              </>
                            }
                            {this.state.showCoordinationAlerts &&
                              <>
                                <label>Approvals / Unables:</label>
                                <div className="alert-types--checkbox" >
                                    <input type="checkbox"
                                        checked={this.state.coordinationVisual}
                                        onChange={this.handleChange}
                                        id="coordinationVisual"
                                        name="coordinationVisual"
                                    />
                                    <label htmlFor="coordinationVisual">Visual</label>
                                </div>
                                <div className="alert-types--checkbox" >
                                    <input type="checkbox"
                                        checked={this.state.coordinationAudible}
                                        onChange={this.handleChange}
                                        id="coordinationAudible"
                                        name="coordinationAudible"
                                    />
                                    <label htmlFor="coordinationAudible">Audible</label>
                                </div>

                                <label>Scratch Pad:</label>
                                <div className="alert-types--checkbox" >
                                    <input type="checkbox"
                                        checked={this.state.scratchPadVisual}
                                        onChange={this.handleChange}
                                        id="scratchPadVisual"
                                        name="scratchPadVisual"
                                    />
                                    <label htmlFor="scratchPadVisual">Visual</label>
                                </div>
                                <div className="alert-types--checkbox" >
                                    <input type="checkbox"
                                        checked={this.state.scratchPadAudible}
                                        onChange={this.handleChange}
                                        id="scratchPadAudible"
                                        name="scratchPadAudible"
                                    />
                                    <label htmlFor="scratchPadAudible">Audible</label>
                                </div>
                              </>
                            }

                            <label>Filtered Flights:</label>
                            <div className="alert-types--checkbox">
                                <input type="checkbox"
                                    checked={this.state.filteredVisual}
                                    onChange={this.handleChange}
                                    id="filteredVisual"
                                    name="filteredVisual"
                                />
                                <label htmlFor="filteredVisual">Visual</label>
                            </div>
                            <div className="alert-types--checkbox" >
                                <input type="checkbox"
                                    checked={this.state.filteredAudible}
                                    onChange={this.handleChange}
                                    id="filteredAudible"
                                    name="filteredAudible"
                                />
                                <label htmlFor="filteredAudible">Audible</label>
                            </div>
                        </div>
                        <div id="flightAlertFilter">
                            <p>Current Flight Filter: { this.state.filterString }</p>
                            {/* this.filterButton added in componentDidUpdate */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary"
                            onClick={this.processApply}
                        >
                            Apply
                        </Button>
                        <Button variant="secondary"
                            onClick={this.processCancel}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }
        else
        {
            return null;
        }
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state The current redux state
 *
 * @return {object} The desired redux state properties mapped to props
 */
const mapStateToProps = (state) =>
{
    return {
        user: state.authentication.user,
        isOpen: state.modalReducer.alertTypesModalOpen,
        alertTypes: state.modalReducer.alertTypes,
        alertFilter: state.tablesReducer.filterPerTable[ALERT_FILTER_TABLE_ID],
        candidateFilter: state.tablesReducer.filterPerTable[CANDIDATE_FILTER_TABLE_ID],
    };
};

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    closeAlertTypesModal,
    updateAlertTypes,
};

export default connect(mapStateToProps, mapDispatchToProps) (AlertTypesModal);
