import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

/**
 * This class is used to display a confirmation dialog with OK and Cancel buttons.
 */
class ConfirmDialog extends Component
{
    static propTypes = {
        // Indicates if the dialog is currently displayed; passed in from parent
        isShown: PropTypes.bool.isRequired,

        // The function used to process a button or click outside; passed in
        // from parent
        onSelection: PropTypes.func.isRequired,

        // The title to display in the dialog; passed in from parent
        title: PropTypes.string,

        // The message to display in the dialog (mutually exclusive with
        // 'children'); passed in from parent
        msg: PropTypes.string,

        // The child elements to display in the dialog (mutually exclusive with
        // 'msg'); passed in from parent
        children: PropTypes.element,
    };

    /**
     * Passes false back to the callback function, which should close the 
     * dialog.
     */
    closeCancel = () => {
        this.props.onSelection(false);
    }

    /**
     * Passes true back to the callback function, which should close the dialog.
     */
    closeCommit = () => {
        this.props.onSelection(true);
    }

    /**
     * Sets up the confirmation dialog for rendering.
     *
     * @return {JSX.element} The confirmation dialog
     */
    render()
    {
        if (this.props.isShown)
        {
            return (
                <Modal show={true} onHide={this.closeCancel}>
                    <Modal.Header>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.msg ? this.props.msg : this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.closeCommit}>
                            OK
                        </Button>
                        <Button variant="secondary" onClick={this.closeCancel}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }
        else
        {
            return null;
        }
    }
}

export default ConfirmDialog;
