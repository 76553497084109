import React from 'react';
import { createRoot } from 'react-dom/client';
import { enableMapSet } from 'immer';

import { Provider } from 'react-redux';
import { store } from './utils/store';

import { Hub } from 'aws-amplify';
import { configureAmplify } from 'tos-web-admin-client';

import { STORAGE_USER, STORAGE_JWT_COOKIE, COGNITO_AUTH_ENABLED } from './constants/Constants';

import App from './App';
// importing boostrap css first means we can override in our css files
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.css';

if( COGNITO_AUTH_ENABLED === 'true' )
{
  configureAmplify();

  // Listens for Authentication event messages
  // 1. reset the stored user and token when signed out
  Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
        case 'tokenRefresh':
          console.log( 'signIn or tokenRefresh' );
          break;
        case 'oAuthSignOut':
        case 'signOut':
        case 'tokenRefresh_failure':
          console.log('sign out')
          localStorage.removeItem(STORAGE_USER);
          localStorage.removeItem(STORAGE_JWT_COOKIE);
          // redirect to the home page
          window.location.href = '/';
          break;
        default:
          break;
      }
    });
}

enableMapSet(); // let immer handle maps and sets
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    // Wrapping with Provider makes the Redux store available to everything
    // within it.
    <Provider store={store}>
        <App />
    </Provider>
);
