/*
 * This file contains the action type constants for Redux
 */

// For tables reducer
export const ADD_TABLE = "ADD_TABLE";
export const REMOVE_TABLE = "REMOVE_TABLE";
export const MOVE_TABLE_DOWN = "MOVE_TABLE_DOWN";
export const MOVE_TABLE_UP = "MOVE_TABLE_UP";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const UPDATE_TABLE_LENGTH = "UPDATE_TABLE_LENGTH";
export const START_UPDATE = "START_UPDATE";
export const TABLE_UPDATED = "TABLE_UPDATED";

// For columns reducer
export const UPDATE_COLUMNS = "UPDATE_COLUMNS";
export const UPDATE_ORDER = "UPDATE_ORDER";

// For data reducer
export const UPDATE_TOS_STATUS = "UPDATE_TOS_STATUS";
export const UPDATE_DEPARTURES = "UPDATE_DEPARTURES";
export const UPDATE_FLIGHT_ROUTES = "UPDATE_FLIGHT_ROUTES";
export const ADD_FLIGHT_ROUTES = "ADD_FLIGHT_ROUTES";
export const UPDATE_ALERT_FLIGHTS = "UPDATE_ALERT_FLIGHTS";
export const UPDATE_TIME_MODE = "UPDATE_TIME_MODE";
export const UPDATE_DATA_TIMESTAMP = "UPDATE_DATA_TIMESTAMP";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const CLOSE_SCRATCH_MODAL = "CLOSE_SCRATCH_MODAL";
export const OPEN_SCRATCH_MODAL = "OPEN_SCRATCH_MODAL";
export const CLOSE_ALERT_TYPES_MODAL = "CLOSE_ALERT_TYPES_MODAL";
export const OPEN_ALERT_TYPES_MODAL = "OPEN_ALERT_TYPES_MODAL";
export const UPDATE_ALERT_TYPES = "UPDATE_ALERT_TYPES";
export const CLOSE_COLOR_ALERTS_MODAL = "CLOSE_COLOR_ALERTS_MODAL";
export const OPEN_COLOR_ALERTS_MODAL = "OPEN_COLOR_ALERTS_MODAL";
export const UPDATE_COLOR_ALERTS = "UPDATE_COLOR_ALERTS";
export const CLOSE_ERROR_MODAL = "CLOSE_ERROR_MODAL";
export const OPEN_ERROR_MODAL = "OPEN_ERROR_MODAL";
export const CLOSE_HOTLINE_MODAL = "CLOSE_HOTLINE_MODAL";
export const OPEN_HOTLINE_MODAL = "OPEN_HOTLINE_MODAL";
export const UPDATE_RUN_TIMES = "UPDATE_RUN_TIMES";
export const UPDATE_REFRESH_INTERVAL = "UPDATE_REFRESH_INTERVAL"

// For tracking flight menu gufis
export const ADD_FLIGHT_MENU_GUFI = "ADD_FLIGHT_MENU_GUFI";
export const REMOVE_FLIGHT_MENU_GUFI = "REMOVE_FLIGHT_MENU_GUFI";

// For general settings manipulation
export const RESET_SETTINGS = "RESET_SETTINGS";
export const RESET_SETTINGS_COMPLETE = "RESET_SETTINGS_COMPLETE";

// For working with the Show Me table
export const ADD_SHOW_ME_FLIGHTS = "ADD_SHOW_ME_FLIGHTS";
export const ADD_SHOW_ME_CLIENT_FLIGHTS = "ADD_SHOW_ME_CLIENT_FLIGHTS";
export const REMOVE_SHOW_ME_FLIGHT = "REMOVE_SHOW_ME_FLIGHT";
export const CLEAR_SHOW_ME_FLIGHTS = "CLEAR_SHOW_ME_FLIGHTS";
export const UPDATE_GLOBAL_SEARCH = "UPDATE_GLOBAL_SEARCH";
export const SET_EMPTY_SEARCH = "SET_EMPTY_SEARCH";

