import { TosSwimStatus } from "./TosEnum";

export const APP_NAME = "TosTables";

// tos-web-table-service data server access info
export const DATA_HOST = window.location.hostname;
export const DATA_PORT = "7474";
// For the DATA_URL variable, use environment variable "REACT_APP_TOS_SERVICE_URL" if it's set
export const DATA_URL = process.env.REACT_APP_TOS_SERVICE_URL || `http://${DATA_HOST}:${DATA_PORT}/tos-web-table-service`;
// Data update interval, 5 seconds, in milliseconds
export const UPDATE_INTERVAL = 1000 * 5;

// SHOW_ME query param key in the URL when TWT is opened from the Client
export const SHOW_ME_QUERY = "showMe";

// Name for the alert types
export const ALERT_TYPE_FILTER = "fltr";
export const ALERT_TYPE_COORDINATION = "coord";
export const ALERT_TYPE_SCRATCH_PAD = "scratch";
export const ALERT_TYPE_CANDIDATE = "candidate";
export const ALERT_TYPE_REQUEST = "request";
export const ALERT_TYPE_PROPOSAL = "proposal";

// Roles for flight operator vs atc allowed actions
export const FO_ROLES = [ "OPERATOR" ];
export const ATC_APPROVER_ROLES = [ "ATC_TOWER", "ATC_CENTER" ];
export const ALL_AIRPORT_CONFIG_ROLES = [ "ATC_TRACON", "ATC_CENTER" ];

// Key for saving the current settings and alerted flights in browser storage
export const STORAGE_SETTINGS = ":settingsName";
export const ACKED_ALERTS = ":ackedAlerts";

// Time modes
export const TIME_CURRENT = "Current";
export const TIME_FIXED = "Fixed";
export const TIME_NEXT = "Next";
export const TIME_PREVIOUS = "Previous";

// Colors for the Probability of Delay Savings > RTC
export const PROB_DELAY_SAVINGS_COLORS = {
    active: true,
    level1: { value: 60, color: "rgb(102,255,153)" },
    level2: { value: 50, color: "rgb(255,204,0)" },
    level3: { value: 0, color: "rgb(255,153,153)" },
};

export const STORAGE_USER = "user";
export const STORAGE_JWT_COOKIE = "jwtCookie";
export const COGNITO_AUTH_ENABLED = process.env.REACT_APP_DIP_PLATFORM_VERSION;
export const DIP_ENVIRONMENT = process.env.REACT_APP_DIP_PLATFORM_ENVIRONMENT;

export const IN_SWIM_STATUSES = [
  TosSwimStatus.SAVED.name,
  TosSwimStatus.PENDING_SAVE.name,
  TosSwimStatus.IN_SWIM.name];
