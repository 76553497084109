import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import $ from "jquery";

import { clearShowMeFlights,
         removeShowMeFlight,
         setEmptySearch,
         updateColumns,
         updateGlobalSearch } from "../../actions";
import Table from "./Table";
import { SHOW_ME_TABLE_ID,
         SHOW_ME_TABLE_TITLE } from "../../constants/TableConstants";
import { getUserDefaultDataTableColumns } from "../../column_utils/columnDefaults";


/**
 * This class is used to create and manage a single table to display flights
 * from alerts and global searches.
 */
class ShowMeTable extends PureComponent
{
    static propTypes = {
        // List of flights to alert; from redux
        alertGufis: PropTypes.arrayOf(PropTypes.string),

        // User-entered text from global search field; from redux
        globalSearch: PropTypes.string,

        // Action creator to clear the table; from redux
        clearShowMeFlights: PropTypes.func.isRequired,

        // Columns defined for this table, to verify they have been; from redux
        columns: PropTypes.arrayOf(PropTypes.string),

        // Action creator to remove a flight from the table; from redux
        removeShowMeFlight: PropTypes.func.isRequired,

        // Action creator to clear the global search field; from redux
        setEmptySearch: PropTypes.func.isRequired,

        // Action creator to set column names if not defined; from redux
        updateColumns: PropTypes.func.isRequired,

        // Action creator to update the global search field; from redux
        updateGlobalSearch: PropTypes.func.isRequired,

        // Navigation; provided by the system
        history: PropTypes.object.isRequired,
    };

    /**
     * Constructs the Show Me table, which is used to display flights for
     * which the user was alerted or which match the global search.
     *
     * @param {*} props   none passed in from parent
     */
    constructor(props)
    {
        super(props);
        this.state = {
            doRefresh: false,
        };

        this.wasLastSearchEmpty = true;

        // Set the DOM layout, [B]utton, [f]ind, p[r]ocessing, [t]able,
        // [l]ength, and [p]agination.
        this.tableDom = 'B<"' + SHOW_ME_TABLE_ID + '"<"tabName">rtlp>';
        this.createButtonsLeft = this.createButtonsLeft.bind(this);
        this.callRemoveTable = this.callRemoveTable.bind(this);
        this.departureFilter = this.departureFilter.bind(this);

        if (!this.props.columns)
        {
            const userColumns = getUserDefaultDataTableColumns();
            this.props.updateColumns(SHOW_ME_TABLE_ID, userColumns);
        }

    }

    /**
     * Creates the buttons associated with this table aligned on the top left.
     * None are used for this table.
     */
    createButtonsLeft()
    {
        let history = this.props.history;

        return {
            buttons: [
                {
                    text: "Columns",
                    action: function(unusedEvent, unusedDt, unusedNode,
                                unusedConfig) {
                        // Go to column selection page
                        history.push({ pathname: "/columnSelection",
                                       tableId: SHOW_ME_TABLE_ID });
                    },
                    titleAttr: "Select and adjust order of columns",
                },
            ],
        };
    }

    /**
     * Filters the full list of departures down to only those valid for this
     * table, which means either those flights that match global search or
     * those flights that match the alert filter.
     *
     * @param [*] allFlights   all the departure flights
     *
     * @return array of flights to show
     */
    departureFilter(allFlights)
    {
        let showFlights = [];
        let alertFlights = [];
        let searchFlights = [];
        const hasAlerts = this.props.alertGufis.length > 0;
        const hasSearch = this.props.globalSearch !== "";
        let emptySearch = false;

        if (hasSearch)
        {
            searchFlights = allFlights.filter((flight) => {
                return (
                    (flight.acid &&
                        flight.acid.includes(this.props.globalSearch)) ||
                    (flight.destination &&
                        flight.destination.includes(this.props.globalSearch)) ||
                    (flight.departureFix &&
                        flight.departureFix.includes(this.props.globalSearch)) ||
                    (flight.departureGate &&
                        flight.departureGate.includes(this.props.globalSearch)));
            });
            if (searchFlights.length === 0)
            {
                emptySearch = true;
            }
        }

        if (hasAlerts)
        {
            const fromAlerts = [ ...this.props.alertGufis ];

            fromAlerts.forEach((gufi) => {
                const alertFlight =
                    allFlights.find((flight) => flight.gufi === gufi);
                if (alertFlight)
                {
                    alertFlights.push(alertFlight);
                }
                else
                {
                    this.props.removeShowMeFlight(gufi);
                }
            });
        }

        if (alertFlights.length)
        {
            if (searchFlights.length)
            {
                showFlights = alertFlights.concat(
                    searchFlights.filter(
                        (searched) => !alertFlights.some(
                            (alerted) => alerted.gufi === searched.gufi)));
            }
            else
            {
                showFlights = alertFlights;
            }
        }
        else
        {
            showFlights = searchFlights;
        }

        if (emptySearch !== this.wasLastSearchEmpty)
        {
           this.wasLastSearchEmpty = emptySearch;
           this.props.setEmptySearch(emptySearch);
        }

        // Remove duplicates from the showFlights list
        showFlights = this.removeDuplicateFlights( showFlights );

        return showFlights;
    }

    /**
     * Method to remove duplicate flights
     */
    removeDuplicateFlights( showFlights )
    {
        let seenGufis = new Set();
        for( let i = showFlights.length - 1; i >= 0; i-- )
        {
            let flight = showFlights[i];
            if( !flight || !flight.gufi )
            {
                console.error("Undefined flight in showFlights list: " + flight);
            }
            else if( seenGufis.has( flight.gufi ) )
            {
                showFlights.splice(i, 1);
            }
            else
            {
                seenGufis.add( flight.gufi );
            }
        }

        return showFlights;
    }

    /**
     * Called when the table remove button is pressed. Clears the list of
     * flights from alerts and the global search field so that there is
     * nothing to display and this table will hide.
     *
     * @param {string} unusedTableId   table id; unused
     */
    callRemoveTable(unusedTableId)
    {
        this.props.clearShowMeFlights();
        if (this.wasLastSearchEmpty)
        {
            this.wasLastSearchEmpty = false;
            this.props.setEmptySearch(false);
        }
        this.props.updateGlobalSearch("");
    }

    /**
     * Checks if an update is caused by change to global search value or alert
     * gufi list, in which case we want to refresh the flight list now.
     *
     * @param {object} prevProps   previous iteration of properties
     */
    componentDidUpdate(prevProps)
    {
        if ((prevProps.globalSearch !== this.props.globalSearch) ||
            (prevProps.alertGufis !== this.props.alertGufis))
        {
            this.setState({ doRefresh: true });
            if ((prevProps.globalSearch.length < this.props.globalSearch.length) ||
                (prevProps.alertGufis.length < this.props.alertGufis.length))
            {
                $(".main-frame").scrollTop(0);
            }
        }
        else
        {
            this.setState({ doRefresh: false });
        }
    }

    /**
     * Set up the table wrapper for rendering.
     *
     * @return {JSX.element} The container/wrapper for the formatted table
     */
    render()
    {
        // If nothing is searched for or no alerts asked to be shown, don't
        // show the table
        if ((this.props.alertGufis.length === 0) &&
            (this.props.globalSearch === ""))
        {
            return null;
        }
        return (
            <Table tableId={SHOW_ME_TABLE_ID}
                confirmDelete={false}
                leftButtons={this.createButtonsLeft}
                tableDom={this.tableDom}
                tableTitle={SHOW_ME_TABLE_TITLE}
                departureFilter={this.departureFilter}
                callRemoveTable={this.callRemoveTable}
                refresh={this.state.doRefresh}
            />
        );
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state The current redux state
 *
 * @return {object} The desired redux state properties mapped to props
 */
const mapStateToProps = (state) =>
{
    return {
        alertGufis: state.showMeReducer.alertGufis,
        globalSearch: state.showMeReducer.globalSearch,
        columns: state.columnsReducer.columnsPerTable[SHOW_ME_TABLE_ID],
    };
};

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    clearShowMeFlights,
    removeShowMeFlight,
    setEmptySearch,
    updateColumns,
    updateGlobalSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShowMeTable));
