import { LOGOUT } from "tos-web-admin-client";

import * as types from "../constants/ActionTypes";
import { CoordinationState, EligibilityState } from "../constants/TosEnum";


/**
 * This file contains the Redux reducer for storing and distributing
 * flags and data related to modal dialogs.
 */

/**
 * The default alert types for flight actions (submit/approve/unable),
 * new scratch pad entries, and for filtered flights.
 */
export const DEFAULT_ALERT_TYPES = {
    candidateVisual: true,
    candidateAudible: false,
    requestVisual: true,
    requestAudible: false,
    proposalVisual: true,
    proposalAudible: false,
    coordinationVisual: false,
    coordinationAudible: false,
    filteredVisual: false,
    filteredAudible: false,
    scratchPadVisual: false,
    scratchPadAudible: false,
};

/**
 * The default color alerts for table fields
 */
export const DEFAULT_COLOR_ALERTS = [
    {field: "coordinationStatus",
     condition: "=",
     value: CoordinationState.REROUTE_REQUESTED.name,
     rgb: {r:147, g:205, b:221},
     whiteText: false },
    {field: "coordinationStatus",
     condition: "=",
     value: CoordinationState.REROUTE_PROPOSED.name,
     rgb: {r:255, g:190, b:0},
     whiteText: false },
    {field: "coordinationStatus",
     condition: "One Of",
     valueOneOf: [CoordinationState.ATC_APPROVED.name, CoordinationState.OPERATOR_APPROVED.name],
     rgb: {r:146, g:208, b:80},
     whiteText:false},
    {field: "coordinationStatus",
     condition: "=",
     value: CoordinationState.REROUTE_FILED.name,
     rgb: {r:200, g:208, b:200},
     whiteText:false},
    {field: "coordinationStatus",
     condition: "One Of",
     valueOneOf: [CoordinationState.ATC_UNABLE.name, CoordinationState.OPERATOR_UNABLE.name],
     rgb: {r:190, g:190, b:190},
     whiteText:false},
    {field: "coordinationStatus",
     condition: "One Of",
     valueOneOf: [CoordinationState.ATC_EXCLUDED.name, CoordinationState.OPERATOR_EXCLUDED.name],
     rgb: {r:199, g:194, b:157},
     whiteText:false},
    {field: "coordinationStatus",
     condition: "One Of",
     valueOneOf: [CoordinationState.PENDING_ACTION.name, CoordinationState.PENDING_EXCLUDE.name],
     rgb: {r:175, g:175, b:175},
     whiteText:false},
    {field: "eligibilityStatus",
     condition: "=",
     value: EligibilityState.POTENTIAL.name,
     rgb: {r:255, g:249, b:149},
     whiteText:false},
    {field: "eligibilityStatus",
     condition: "=",
     value: EligibilityState.CANDIDATE.name,
     rgb: {r:250, g:192, b:144},
     whiteText:false},
    {field: "eligibilityStatus",
     condition: "=",
     value: EligibilityState.EXCLUDED.name,
     rgb: {r:199, g:194, b:157},
     whiteText:false},
    {field: "eligibilityStatus",
     condition: "=",
     value: EligibilityState.PENDING_EXCLUDE.name,
     rgb: {r:175, g:175, b:175},
     whiteText:false},
    {field: "topProbabilityDelaySavingsRtc",
     condition: "Between",
     value: "0",
     value2: "50",
     rgb: {r:175, g:175, b:175},
     whiteText:false},
    {field: "topProbabilityDelaySavingsRtc",
     condition: "Between",
     value: "50",
     value2: "60",
     rgb: {r:255, g:204, b:0},
     whiteText:false},
    {field: "topProbabilityDelaySavingsRtc",
     condition: ">=",
     value: "60",
     rgb: {r:102, g:255, b:153},
     whiteText:false},
    {field: "topProbabilityDelaySavingsZero",
     condition: "Between",
     value: "0",
     value2: "50",
     rgb: {r:175, g:175, b:175},
     whiteText:false},
    {field: "topProbabilityDelaySavingsZero",
     condition: "Between",
     value: "50",
     value2: "60",
     rgb: {r:255, g:204, b:0},
     whiteText:false},
    {field: "topProbabilityDelaySavingsZero",
     condition: ">=",
     value: "60",
     rgb: {r:102, g:255, b:153},
     whiteText:false},
    {field: "topImpactLevel",
     condition: "=",
     value: "SEVERE",
     rgb: {r:207, g:0, b:0},
     whiteText: true},
    {field: "impactLevel",
     condition: "=",
     value: "SEVERE",
     rgb: {r:207, g:0, b:0},
     whiteText: true}
];

/**
 * The default state for data reducer
 */
const DEFAULT_MODAL_REDUCER_STATE = {
    alertTypesModalOpen: false,
    alertFlights: [],
    alertTypes: { ...DEFAULT_ALERT_TYPES },
    colorAlertsModalOpen: false,
    colorAlerts: [ ...DEFAULT_COLOR_ALERTS ],
    errorModalOpen: false,
    errorModalText: "",
    errorModalTitle: "",
    hotlineModalOpen: false,
    scratchModalOpen: false,
    scratchModalGufi: "",
    scratchModalAcid: "",
    scratchModalEntries: [],
};

/**
 * The reducer used to update and store modal data.
 *
 * @param {object} state   The current Redux state
 * @param {object} action  The Redux action object containing the action type
 *                         and the data necessary for the specified action.
 */
export default function modalReducer(
    state = DEFAULT_MODAL_REDUCER_STATE, action = {}) {

    // Redux changes need to be immutable. So for every change three things
    // need to happen.
    // 1. Shallow copy of containing objects.
    // 2. Apply desired modification to copy.
    // 3. Bundle the changes in with the rest of the unmodified state and
    //    return it. The return should be a copy of the current state, but with
    //    the desired modification.
    switch (action.type)
    {
        case types.CLOSE_ALERT_TYPES_MODAL:
        {
            return {
                ...state,
                alertTypesModalOpen: false,
            };
        }

        case types.CLOSE_COLOR_ALERTS_MODAL:
        {
            return {
                ...state,
                colorAlertsModalOpen: false,
            };
        }

        case types.CLOSE_ERROR_MODAL:
        {
            return {
                ...state,
                errorModalOpen: false,
                errorModalText: "",
                errorModalTitle: "",
            };
        }

        case types.CLOSE_HOTLINE_MODAL:
        {
            return {
                ...state,
                hotlineModalOpen: false,
            };
        }

        case types.CLOSE_SCRATCH_MODAL:
        {
            return {
                ...state,
                scratchModalOpen: false,
                scratchModalGufi: "",
                scratchModalAcid: "",
                scratchModalEntries: [],
            };
        }

        case types.OPEN_ALERT_TYPES_MODAL:
        {
            return {
                ...state,
                alertTypesModalOpen: true,
            };
        }

        case types.OPEN_COLOR_ALERTS_MODAL:
        {
            return {
                ...state,
                colorAlertsModalOpen: true,
            };
        }

        case types.OPEN_ERROR_MODAL:
        {
            return {
                ...state,
                errorModalOpen: true,
                errorModalText: action.payload.errMsg,
                errorModalTitle: action.payload.title,
            };
        }

        case types.OPEN_HOTLINE_MODAL:
        {
            return {
                ...state,
                hotlineModalOpen: true,
            };
        }

        case types.OPEN_SCRATCH_MODAL:
        {
            return {
                ...state,
                scratchModalOpen: true,
                scratchModalGufi: action.payload.gufi,
                scratchModalAcid: action.payload.acid,
                scratchModalEntries: action.payload.entries,
            };
        }

        case types.UPDATE_ALERT_FLIGHTS:
        {
            return {
                ...state,
                alertFlights: [ ...action.payload.alertFlights ],
            };
        }

        case types.UPDATE_ALERT_TYPES:
        {
            return {
                ...state,
                alertTypes: { ...action.payload.alertTypes },
            };
        }

        case types.UPDATE_COLOR_ALERTS:
        {
            return {
                ...state,
                colorAlerts: [ ...action.payload.colorAlerts ],
            };
        }

        case LOGOUT:
        {
            return {
                ...state,
                ...DEFAULT_MODAL_REDUCER_STATE,
            };
        }

        case types.RESET_SETTINGS:
        {
            return {
                ...state,
                alertTypes: { ...DEFAULT_ALERT_TYPES },
                colorAlerts: [ ...DEFAULT_COLOR_ALERTS ],
            };
        }

        // By default return an unmodified state
        default:
            return state;
    }
}
