import React from "react";
import PropTypes from "prop-types";

/**
 * To prevent JavaScript errors inside a component from crashing the entire app,
 * error boundaries are React components that catch JavaScript errors anywhere
 * in their child component tree, log those errors, and display a fallback UI
 * instead of the component tree that crashed.  Error boundaries catch errors
 * during rendering, in lifecycle methods, and in constructors of the whole
 * tree below them; they don't catch errors inside event handlers since event
 * handlers don't happen during rendering (error handling in event handlers
 * should use try/catch).
 *
 * This class and the information above was copied from
 * https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends React.Component
{
    /**
     * Constructs the error boundary.
     *
     * @param {*}                props           properties
     * @param {arrayOf{element}} props.children  child elements being wrapped
     */
    constructor(props)
    {
        super(props);
        this.state = { hasError: false };
    }

    /**
     * Updates the state so the next render will show the fallback UI.
     *
     * @param unusedError  unused
     */
    static getDerivedStateFromError(unusedError)
    {
        return { hasError: true };
    }

    /**
     * Logs the error imformation.
     *
     * @param {object} error     the error that occured
     * @param {object} errorInfo the stack trace
     */
    componentDidCatch(error, errorInfo)
    {
         console.log("+++++++++++++ ERROR: ", error);
         console.log("+++++++++++++ ERROR INFO", errorInfo);
    }

    /**
     * Renders a fallback UI if an error occured.
     */
    render()
    {
        if (this.state.hasError)
        {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    // The children components which are wrapped in this error boundary
    children: PropTypes.arrayOf(PropTypes.element),
};

export default ErrorBoundary;
