import { LOGOUT } from 'tos-web-admin-client';

import  * as types from '../constants/ActionTypes';
import { SHOW_ME_TABLE_ID } from '../constants/TableConstants';

/**
 * This file contains the Redux reducer for the arrays of flight menus.
 */

/**
 * The default state for flight menus reducer state object: show me table
 * with empty list of gufis.
 */
const DEFAULT_FLIGHT_MENU_REDUCER_STATE = {
    flightGufis: {
        [SHOW_ME_TABLE_ID]: [],
    },
}

/**
 * The flight menu reducer used to update the list of gufis.
 *
 * @param {object} state   The current Redux state, with initial value set to
 *                         the default tables array.
 * @param {object} action  The Redux action object containing the action type
 *                         and the data necessary for the specified action.
 */
export default function flightMenuReducer(
    state = DEFAULT_FLIGHT_MENU_REDUCER_STATE, action)
{
    // Redux changes need to be immutable. So for every change three things
    // need to happen.
    // 1. Shallow copy of containing objects.
    // 2. Apply desired modification to copy.
    // 3. Bundle the changes in with the rest of the unmodified state and
    //    return it. The return should be a copy of the current state, but with
    //    the desired modification.
    switch (action.type)
    {
        case types.ADD_FLIGHT_MENU_GUFI: {
             // Copy flight gufi array in state
            let addedFlightGufis = { ...state.flightGufis };

            if (!addedFlightGufis[action.payload.tableId].includes(
                    action.payload.gufi))
            {   // Append the new gufi
                addedFlightGufis[action.payload.tableId].push(
                    action.payload.gufi);
            }

            // Return current state with modified array.
            return {
                ...state,
                flightGufis: addedFlightGufis,
            };
        }
        case types.REMOVE_FLIGHT_MENU_GUFI: {
            let newFlightGufis = { ...state.flightGufis };

             // filter to new array without target gufi
            let removedFlightGufis =
                newFlightGufis[action.payload.tableId].filter(
                    (value) => value !== action.payload.gufi );
            newFlightGufis[action.payload.tableId] = removedFlightGufis;

            // Return current state with modified array.
            return {
                ...state,
                flightGufis: newFlightGufis,
            };
        }
        case types.ADD_TABLE: {
            let newFlightMenusForNewTable = { ...state.flightGufis };

            // Append a new default columns array
            newFlightMenusForNewTable[action.payload.tableId] = [];

            // Return current state with modified columns array
            return {
                ...state,
                flightGufis: newFlightMenusForNewTable,
            };
        }

        case types.REMOVE_TABLE: {
            let newFlightMenusRemoveTable = { ...state.flightGufis };

            delete newFlightMenusRemoveTable[action.payload.tableId];

            return {
                ...state,
                flightGufis: newFlightMenusRemoveTable,
            };
        }

        case types.RESET_SETTINGS:
        case LOGOUT:
        {
            return DEFAULT_FLIGHT_MENU_REDUCER_STATE;
        }

        default:
            return state;
    }
}
