import React from "react";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateTime } from "luxon";

import FlashyAlertModal from "./FlashyAlertModal";
import { addShowMeFlights } from "../../actions";
import { ALERT_TYPE_PROPOSAL } from "../../constants/Constants";
import proposalSoundFile from "../../sounds/AlertSounds.wav";
import { CoordinationState, FlightStatus } from "../../constants/TosEnum";
import { getColorForEnum, getCssColor } from "../../column_utils/columnColors";
import { COLUMNS } from "../../constants/ColumnField";

/**
 * Serves as a modal dialog for alerting the user about proposal flights from ATC
 */
class ProposalAlertModal extends FlashyAlertModal
{
    static propTypes = {
        // The callback function to indicate acknowledgement; passed in
        onAck: PropTypes.func.isRequired,

        // Callsigns and trigger fields of flights to alert; from Redux
        fullAlertFlightList: PropTypes.arrayOf(PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.object]))).isRequired,

        // Flag to indicate if alert is triggerable; from Redux
        showProposal: PropTypes.bool.isRequired,

        // Flag to indicate if audible alert is triggerable; from Redux
        playProposal: PropTypes.bool.isRequired,

        // Action creator to add flights to the Show Me table; from Redux
        addShowMeFlights: PropTypes.func.isRequired,

        // Current Show Me alert flight gufis; from Redux
        alertGufis: PropTypes.arrayOf(PropTypes.string),
    };

    /**
     * Constructs the ProposalAlertModal class. This is used to alert the user to
     * flights of interest based on being proposed for reroute by ATC.
     *
     * @param {*}    props
     * @param {func} props.onAck   function to call to acknowledge flights
     */
    constructor(props)
    {
        super(props);
        this.state = {
            proposalFlightList: [],
        };
        this.proposalSound = new Audio(proposalSoundFile);
    }

    /**
     * Calls the callback function to acknowledge the flights have been seen.
     */
    clearAlert = () => {
        this.props.onAck(this.state.proposalFlightList);
        this.setState({
          proposalFlightList: [],
        });
    }

    /**
     * Calls the callback function to acknowledge the flights have been seen and
     * adds the alerted flights to the list for the Show Me table.
     */
    showFlight = () => {
        const allGufis = this.state.proposalFlightList.map(alertRec => alertRec.gufi);
        if (this.props.alertGufis && this.props.alertGufis.length)
        {
            const newGufis = allGufis.filter(alertGufi => 
                !this.props.alertGufis.includes(alertGufi));
            if (newGufis.length)
            {
                this.props.addShowMeFlights(newGufis);
            }
        }
        else
        {
            this.props.addShowMeFlights(allGufis);
        }

        this.clearAlert();
    }

    /**
     * Plays the defined sound to get the users attention for the proposal
     * alert.
     */
    playProposalSound = () => {
        if (navigator.userActivation.hasBeenActive) {
            this.proposalSound.play();
        } else {
            console.debug("No interaction, no sound played");
        }
    }

    /**
     * If this component did update, then make the appropriate changes to
     * the list.
     *
     * @param {object} prevProps   the props object before the update
     */
    componentDidUpdate(prevProps)
    {
        // only make updates if properties changed, so we don't loop when
        // setting state.
        if (this.props !== prevProps)
        {
            const newPropFlightList = 
                this.props.fullAlertFlightList.filter((flight) => (
                    flight.type === ALERT_TYPE_PROPOSAL
                ));
            const withRoutesList = newPropFlightList.map((proposal) => {
                const proposed = this.props.flightRoutes.find(fr => {
                    return (fr.gufi === proposal.gufi) &&
                        (fr.coordinationStatus === CoordinationState.REROUTE_PROPOSED.name);
                });
                if (proposed)
                {
                    return {
                        ...proposal,
                        info: {
                            ...proposal.info,
                            tosEtot: proposed["estimatedTakeOffTime-luxon"],
                            tosGate: proposed.departureFixGate,
                            routeId: proposed.id,
                        },
                    };
                } else {
                    return proposal;
                }
            });

            if (this.props.playProposal &&
                !this.state.proposalFlightList.length &&
                newPropFlightList.length)
            {
                this.playProposalSound();
                console.log("Playing Candidate alert", newPropFlightList);
            }

            // Save the alerted flights
            this.setState({
                proposalFlightList: withRoutesList,
            });
        }
    }

    /**
     * Displays the ReactModal with our options and content. It will actually
     * only be open and shown if there are flights in the list to show.
     *
     * @return {JSX.element} A single modal dialog for rendering
     */
    render()
    {
        let showModal = this.props.showProposal && this.state.proposalFlightList?.length;

        if (showModal)
        {
            this.flashTab("Reroute Opportunity");
            const nowHhmm = DateTime.utc().set({ seconds: 0, milliseconds: 0 });
            const colorSpec = getColorForEnum(CoordinationState.REROUTE_PROPOSED.name, 
                COLUMNS.TOS_DT_COORDINATION_STATE_COLUMN.key) || 
                getCssColor({ rgb: {r:255, g:190, b:0}, whiteText: false });

            return (
                <Modal show={true} onHide={this.clearAlert} scrollable={true} size="md">
                    <Modal.Header>
                        <Modal.Title>ATC Reroute Opportunity Alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {
                        <ListGroup variant="flush" id="proposalFlightAlerts">
                            { this.state.proposalFlightList.map((flight) => {
                                const flightEobt = DateTime.fromISO(flight.info.eobt).set({ seconds: 0, milliseconds: 0});
                                let minDiff = flightEobt.diff(nowHhmm, "minutes").as("minutes");
                                if (Number.isNaN(minDiff)) {
                                    minDiff = "??";
                                }

                                return (
                                    <ListGroup.Item key={flight.acid}
                                        style={{color: colorSpec.textColor,
                                            backgroundColor:
                                                colorSpec.backgroundColor}}
                                        className="proposal-flight-alert--color"
                                    >
                                      <div className="proposal-info">
                                        <div className="proposal-info-top-line">
                                          {flight.acid} to {flight.info.dest} &mdash; Push in {minDiff}min
                                        </div>
                                        <div>TOS</div>
                                        <div>{flight.info.tosEtot?.toFormat("HH:mm")}</div>
                                        <div>{flight.info.tosGate}</div>
                                        <div>Filed</div>
                                        <div>{flight.info.etot?.toFormat("HH:mm")}</div>
                                        <div>{flight.info.gate}</div>
                                        <div>Flight State</div>
                                        <div>&nbsp;</div>
                                        <div>{FlightStatus[flight.info.flightStatus].display}</div>
                                        <div>Route ID</div>
                                        <div>&nbsp;</div>
                                        <div>{flight.info.routeId}</div>
                                      </div>
                                    </ListGroup.Item>
                                )
                              })
                            }
                        </ListGroup>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showFlight}>
                            Show
                        </Button>
                        <Button variant="secondary" onClick={this.clearAlert}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }
        else
        {
            this.endFlash();
            return null;
        }
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state  The current Redux state
 *
 * @return {object} The desired Redux state properties mapped to props
 */
const mapStateToProps = (state) =>
{
    return {
        fullAlertFlightList : state.modalReducer.alertFlights,
        showProposal : state.modalReducer.alertTypes.proposalVisual,
        playProposal: state.modalReducer.alertTypes.proposalAudible,
        alertGufis: state.showMeReducer.alertGufis,
        flightRoutes: state.dataReducer.flightRoutes,
    };
};

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    addShowMeFlights,
};

export default connect(mapStateToProps, mapDispatchToProps) (ProposalAlertModal);
