import $ from "jquery";

import { getColumnByLabel } from "../constants/ColumnField";
import { ALERT_FILTER_TABLE_ID,
         CANDIDATE_FILTER_TABLE_ID,
         SEARCH_TYPE } from "../constants/TableConstants";
import { CoordinationState } from "../constants/TosEnum";

/**
 * Determines if two table filters have the same contents.  See the header of
 * makeQueryStringRecurse for the contents of a filter.
 *
 * @param {object} filterA    searchBuilder filter object from table A
 * @param {object} filterB    searchBuilder filter object from table B
 *
 * @return {boolean} true if the filters match
 */
export function equalFilters(filterA, filterB)
{
    if (!filterA || !filterB)
    {
        return filterA === filterB;
    }

    // Neither one has criteria (all flights shown)
    if (!filterA.criteria && !filterB.criteria)
    {
        return true;
    }

    return checkCriteria(filterA, filterB);
}

/**
 * Determines if two table filter criteria have the same contents.  See the
 * header of makeQueryStringRecurse for the contents of a filter.
 *
 * @param {object} filterA    searchBuilder filter object from table A
 * @param {object} filterB    searchBuilder filter object from table B
 *
 * @return {boolean} true if the filter criteria match
 */
function checkCriteria(filterA, filterB)
{
    // One has criteria and the other doesn't
    if ((filterA.criteria && !filterB.criteria) ||
        (filterB.criteria && !filterA.criteria))
    {
        return false;
    }

    // Both have criteria, but different numbers of criteria
    if (filterA.criteria.length !== filterB.criteria.length)
    {
        return false;
    }

    // Both have criteria, but different logic (and/or)
    if (filterA.logic !== filterB.logic)
    {
        return false;
    }

    // Same number of criteria, so check each pair of criteria
    let stillSame = true;
    for (let idx = 0; idx < filterA.criteria.length && stillSame; idx++)
    {
        let critA = filterA.criteria[idx];
        let critB = filterB.criteria[idx];

        // Case A: this criteria node contains a child criteria node
        if (critA.criteria)
        {
            stillSame = checkCriteria(critA, critB);
        }
        // Case B: this criteria node contains a condition/data/value leaf
        else
        {
            stillSame = checkConditionDataValue(critA, critB);
        }
    }

    return stillSame;
}

/**
 * Determines if the condition and data children of a criteria match.
 * Sample data:
 *      "condition": "=",
 *      "data": "Coord State",
 *      "value": [
 *          "DEFAULT"
 *      ]
 *
 * @param {object} critA    condition/data/value leaf of criteria A
 * @param {object} critB    condition/data/value leaf of criteria B
 *
 * @return {boolean} true if they are the same
 */
function checkConditionDataValue(critA, critB)
{
    let stillSame = ((critA.data === critB.data) &&
                     (critA.condition === critB.condition));

    if (stillSame)
    {
        if (critA.value?.length !== critB.value?.length)
        {
            stillSame = false;
        }
        else
        {
            for (let idx = 0; idx < critA.value.length && stillSame; idx++)
            {
                stillSame = (critA.value[idx] === critB.value[idx]);
            }
        }
    }

    return stillSame;
}

/**
 * Makes a string to explain the current filter criteria.
 *
 * @param {object} filters           searchBuilder filter object
 * @param {array}  filters.criteria  searchBuilder criteria or subgroup array
 * @param {string} filters.logic     searchBuilder logic type (and / or)
 * @param {string} [defaultText=All flights shown] text for no filter
 *
 * @return {string} string form of this criteria
 */
export function makeQueryString(filters, defaultText="All flights shown")
{
    let query = "";

    // if we have a filter, parse it recursively
    if (filters && filters.criteria)
    {
        query = makeQueryStringRecurse(filters.criteria, filters.logic);
    }
    else
    {
        query = defaultText;
    }

    // if we just have one empty criteria, easier to check and fix here
    if (query === "(undefined undefined)")
    {
      query = defaultText;
    }

    return query;
}

/**
 * Recursive method to traverse the searchBuilder filter and parse it out
 * to a string. For example,
 *
 * "criteria": [
 *     {
 *         "condition": "=",
 *         "data": "Coord State",
 *         "value": [
 *             "DEFAULT"
 *         ]
 *     },
 *     {
 *         "criteria": [
 *             {
 *                 "condition": "=",
 *                 "data": "Eligibility State",
 *                 "value": [
 *                     "POTENTIAL"
 *                 ]
 *             },
 *             {
 *                 "condition": "=",
 *                 "data": "Eligibility State",
 *                 "value": [
 *                     "CANDIDATE"
 *                 ]
 *             }
 *         ],
 *         "logic": "OR"
 *     }
 * ],
 * "logic": "AND"
 *
 * becomes (Coord State = Default) AND ((Eligibility State = Potential) OR (Eligibility State = Candidate))
 *
 * @param {array}  criteria  searchBuilder criteria or subgroup array
 * @param {string} logic     searchBuilder logic type (and / or)
 *
 * @return {string} string form of this criteria
 */
function makeQueryStringRecurse(criteria, logic)
{
    let query = "";

    // each entry in criteria will be a sub-group criteria (indented on the
    // filter panel), or an individual criteria with column name, condition,
    // and possible option(s).
    criteria.forEach((c, idx, array) => {
        // a subgroup will be recursively parsed, in parenthesis
        if (c.criteria)
        {
            query += `(${makeQueryStringRecurse(c.criteria, c.logic)})`;
        }
        else
        {
            query += makeCriterionString(c);
        }

        // if there are more conditions, add the logic connector (and/or)
        if (idx !== array.length - 1)
        {
            query += ` ${logic} `;
        }
    });

    return query;
}

/**
 * Stringifies an individual SearchBuilder criterion.
 *
 * @param {object} criterion single SearchBuilder criterion
 *
 * @return {string} string form of criterion in parenthesis
 */

function makeCriterionString(criterion)
{
    let query = `(${criterion.data} ${criterion.condition}`;

    // condition without values, such as isTrue can skip this
    if (criterion.value.length !== 0)
    {
        let values = criterion.value;
        const col = getColumnByLabel(criterion.data);
        // if we have a render function to convert the value, use
        // that to make the string as well.
        if (col && col.render && (col.render instanceof Function))
        {
            if (!Array.isArray(values))
            {
                values = [ criterion.value ];
            }
            let newValues = values.map((val) => {
                // use the render function, passing true for any
                // functions that care this is for the title string
                return col.render(val, "display", null, null, true);
            });
            values = newValues;
        }

        query += " "; // add space before value
        if (Array.isArray(values))
        {
            query += values.join(", ");
        }
        else
        {
            query += values.toString();
        }
    }

    query += ")";
    return query;
}

/**
 * Makes a filter to select out any departure records that match values
 * for the user for TOS coordination status alerts.
 *
 * @param {string} userRole                    user type
 * @param {object} userConfig                  user configuration values
 * @param {string} [userConfig.alertFleet]     alert fleet name
 * @param {string} [userConfig.alertExclude]   carrier code to exclude from
 *                                             fleet alerts
 * @param {string} [userConfig.alertCarrier]   carrier code to alert
 */
export function makeCoordinationAlertFilter(userRole, userConfig)
{
    // TRACON never gets alerts
    if (userRole === "ATC_TRACON")
    {
        return function(unusedRecord) {
            return false;
        };
    }
    else
    {
        const coordStates =
            (userRole === "OPERATOR") ?
                [ CoordinationState.ATC_APPROVED.name,
                      CoordinationState.ATC_UNABLE.name,
                      CoordinationState.REROUTE_PROPOSED.name ] :
                [ CoordinationState.REROUTE_REQUESTED.name,
                      CoordinationState.OPERATOR_APPROVED.name,
                      CoordinationState.OPERATOR_UNABLE.name ];

        return function(record) {
            let includeState = (coordStates.includes(record.coordinationStatus));
            let includeFleetOrCarrier = false;

            if (includeState)
            {
                includeFleetOrCarrier = checkIncludeFleetOrCarrier(
                    userConfig, record);
            }

            return includeState && includeFleetOrCarrier;
        };
    }
}

/**
 * Makes a filter to select out any departure records that are candidates.
 *
 * @param {string} userRole                    user type
 * @param {object} userConfig                  user configuration values
 * @param {string} [userConfig.alertFleet]     alert fleet name
 * @param {string} [userConfig.alertExclude]   carrier code to exclude from
 *                                             fleet alerts
 * @param {string} [userConfig.alertCarrier]   carrier code to alert
 */
export function makeCandidateAlertFilter(userRole, userConfig)
{
    // only OPERATOR gets Candidate alerts
    if (userRole !== "OPERATOR")
    {
        return function(unusedRecord) {
            return false;
        };
    }
    else
    {
        return function(record) {
            let passing = false;

            if ((record.eligibilityStatus === 'CANDIDATE') &&
              (record.coordinationStatus === CoordinationState.DEFAULT.name) &&
              checkIncludeFleetOrCarrier(userConfig, record)) {
                let alertTable = $("#" + CANDIDATE_FILTER_TABLE_ID).DataTable();

                // selecting by id ignores the search option, so get the
                // filtered rows first, then see if the gufi is there
                let alertable = alertTable.rows({ search:'applied' })
                    .ids().toArray();
                passing = alertable.includes(record.gufi);
            }

            return passing;
        };
    }
}

/**
 * Makes a filter to select out any departure records that match the user's
 * flight alert filter values.
 *
 * @param {string} userRole                    user type
 * @param {object} userConfig                  user configuration values
 * @param {string} [userConfig.alertFleet]     alert fleet name
 * @param {string} [userConfig.alertExclude]   carrier code to exclude from
 *                                             fleet alerts
 * @param {string} [userConfig.alertCarrier]   carrier code to alert
 */
export function makeFlightAlertFilter(userRole, userConfig)
{
    return function(record) {
        let passing = false;
        if (checkIncludeFleetOrCarrier(userConfig, record))
        {
            let alertTable = $("#" + ALERT_FILTER_TABLE_ID).DataTable();

            // selecting by id ignores the search option, so get the
            // filtered rows first, then see if the gufi is there
            let alertable = alertTable.rows({ search:'applied' })
                .ids().toArray();
            passing = alertable.includes(record.gufi);
        }

        return passing;
    };
}

/**
 * Builds up a string containing the fields and current values from the flight
 * that cause the alert filter to be passed. Assumes the gufi has already
 * passed the flight alert filter.
 *
 * @param {string} gufi flight identifier used to pull data from the alert table
 *
 * @return string of values for filter pieces that are true
 */
export function getFilterReason(gufi, tableId)
{
    const alertTable = $("#" + tableId).DataTable();
    const filterDetails = alertTable.searchBuilder.getDetails();
    let reason = "";

    if (filterDetails)
    {
        // The datatable cell allows access to the cell data, as well as
        // to the table api for getting a different cell. So just get the first
        // cell in the row with this gufi to start things off.
        let alertCell= alertTable.cell('#' + gufi, 0);
        reason = confirmFilter(alertCell, filterDetails);
    }

    // just in case something weird is going on
    if (reason.length === 0)
    {
        reason = "Filtered Flight";
    }

    return reason;
}

/**
 * Recursively determines if a filter is true, returning the true pieces as a
 * string if it is. The filter should be in the structure defined in the
 * searchBuilder.preDefined option, containing either a simple criterion with
 * condition, data, type, and value, or a criteria array and logic property to
 * join them.
 *
 * @param {object} alertCell cell in the alert table from the row containing
 *                           the flight to check
 * @param {object} filter    filter to check the flight info against
 *
 * @return {string} if flight matches this criteria, contains fields and values
 *                  for true conditions, empty string if it does not
 */
function confirmFilter(alertCell, filter)
{
    let reason = "";

    if (filter.condition)
    {
        reason = confirmCondition(alertCell, filter)
    }
    else if (filter.criteria)
    {
        if (filter.logic === 'OR')
        {
            for (let criteriaIdx in filter.criteria)
            {
                let partial = confirmFilter(alertCell, filter.criteria[criteriaIdx]);
                if (partial)
                {
                    if (reason)
                    {
                        reason += "; ";
                    }
                    reason += partial;
                }
            }
        }
        else // logic === AND
        {
            for (let criteriaIdx in filter.criteria)
            {
                let partial = confirmFilter(alertCell, filter.criteria[criteriaIdx]);
                if (!partial)
                {
                    // if any part is not true, end with empty string
                    return "";
                }
                if (reason)
                {
                    reason += "; ";
                }
                reason += partial;
            }
        }
    }

    return reason;
}

/**
 * Confirm an individual criterion against the flight information.
 *
 * @param {object} alertCell table cell from the row containing the flight
 * @param {object} filter simple criterion to check against the flight data
 *
 * @return {string} if flight matches this criterion return string with current
 *                  value for the field, empty string if it does not
 */
function confirmCondition(alertCell, filter)
{
    // Get the condition set from searchBuilder based on the field type of the
    // filter. Check custom/overriden first, then defaults, then fall back to
    // string. Find more information about searchBuilder conditions
    // at https://datatables.net/extensions/searchbuilder/customConditions
    let cond = $.fn.dataTable.ext.searchBuilder.conditions[filter.type];
    if (cond == undefined)
    {
        cond = $.fn.dataTable.SearchBuilder.defaults.conditions[filter.type];
    }
    if (cond == undefined)
    {
        cond = $.fn.dataTable.SearchBuilder.defaults.conditions.string;
    }

    // Gets the row index from alert cell, then update it to the proper column
    // and get the search value.
    const thisCell = alertCell.cell(alertCell.index().row, filter.data + ":name");
    const val = thisCell.render(SEARCH_TYPE);

    // Shouldn't be the case, since it was successfully used in the first filter
    if (!cond[filter.condition]?.search)
    {
        console.log("Invalid condition in filter", filter);
        return "";
    }

    // Call the "search" filter method from this particular named condition of
    // the condition set. If it returns true, return a string with the current
    // value as displayed in the table.
    if (cond[filter.condition].search(val, filter.value))
    {
        return "(" + filter.data + " = " + thisCell.render("display") + ")";
    }
    else
    {
        return "";
    }
}

/**
 * Makes a filter to find scratch pad entries.
 *
 * @param {string} userName                    the user name
 * @param {object} userConfig                  user configuration values
 * @param {string} [userConfig.alertFleet]     alert fleet name
 * @param {string} [userConfig.alertExclude]   carrier code to exclude from
 *                                             fleet alerts
 * @param {string} [userConfig.alertCarrier]   carrier code to alert
 * @param {string} role                        user role
 */
export function makeScratchPadAlertFilter(userName, userConfig, role)
{
    return function(record) {
        let passing = false;

        // If the scratch pad exists, it will be in an array
        if (checkIncludeFleetOrCarrier(userConfig, record) &&
            Array.isArray(record.scratchPad) && record.scratchPad.length)
        {
            // Look for a scratch pad entry which was not submitted by
            // this user
            const rcpt = record.scratchPad.at(-1).recipient;
            passing = record.scratchPad.some(entry =>
                (userName !== entry.user)) &&
                (!rcpt || (rcpt === role));
        }

        return passing;
    };
}

/**
 * Checks if a record should be allowed based on the flight's airline or
 * major carrier.
 *
 * @param {object} userConfig                  user configuration values
 * @param {string} [userConfig.alertFleet]     alert fleet name; optional
 * @param {string} [userConfig.alertExclude]   carrier code to exclude from
 *                                             fleet alerts; optional
 * @param {string} [userConfig.alertCarrier]   carrier code to alert; optional
 * @param {object} record                      flight record to check carrier and airline
 *
 * @return {boolean} false if the flight should be excluded based on the
 *                   alert fleet or carrier; otherwise true
 */
function checkIncludeFleetOrCarrier(userConfig, record)
{
    const majorCarrier = userConfig.alertFleet;
    const excludedCarrier = userConfig.alertExclude;
    const carrier = userConfig.alertCarrier;

    let includeFleet = true;
    let includeCarrier = true;

    if (majorCarrier)
    {
        includeFleet = (majorCarrier === record.majorCarrier);
        if (includeFleet && excludedCarrier)
        {
            includeFleet = (excludedCarrier !== record.airline);
        }
    }

    if (carrier)
    {
        includeCarrier = (carrier === record.airline);
    }

    return (includeFleet || includeCarrier);
}

/**
 * Makes a deep copy of the table ordering 2D array. This avoid unintential
 * mutations to the data as it's passed around with Redux and Datatables.
 *
 * @param {array of arrays} sortArray  2D array from DataTables.net ordering
 *
 * @return new array of new arrays with the same leaf values as the given array
 */
export function copySort(sortArray)
{
    let newOrder;
    if (sortArray)
    {
        newOrder = [];
        sortArray.forEach(colSort => newOrder.push([ ...colSort ]));
    }
    return newOrder;
}

/**
 * Determines if two table ordering arrays have the same contents.
 *
 * @param {array of arrays} sortA  2D array from DataTables.net ordering
 * @param {array of arrays} sortB  2D array from DataTables.net ordering
 *
 * @return true if the columns and directions for sorting match
 */
export function equalSort(sortA, sortB)
{
    if (!sortA || !sortB)
    {
        return !sortA && !sortB;
    }

    if (sortA.length !== sortB.length)
    {
        return false;
    }

    return sortA.every((val, ind) => {
       return (val[0] === sortB[ind][0]) && (val[1] === sortB[ind][1])});
}

/**
 * Converts the Datatables.net sort array into a string.  There is one element
 * in the array for each sort.  Each element in the array is itself an array:
 * first element is column number, second element is ascending/descending.
 *
 * @param {array of arrays} sortOrder  2D array from DataTables.net ordering
 *
 * @return (string} e.g. for input [[7,asc], [6,desc]], returns "7,asc;6,desc;"
 */
export function makeSortString(sortOrder)
{
    let desc = "";
    if (sortOrder && (sortOrder.length > 0))
    {
        sortOrder.forEach(entry =>
            desc = desc + entry[0] + "," + entry[1] + ";");
    }

    return desc;
}

export function getDescendantProp(obj, desc) {
    const arr = desc.split(".");
    while (arr.length && obj) {
        obj = obj[arr.shift()];
    }
    return obj;
}

export function setDescendantProp(obj, desc, value) {
    const arr = desc.split(".");
    while (arr.length > 1) {
        obj = obj[arr.shift()];
    }
    return (obj[arr[0]] = value);
}
