import React from "react";
import PropTypes from "prop-types";

import { coloredConditionMap } from "../../../constants/ColorAlertConstants";


/**
 * This functional component provides a user interface to select the condition
 * (e.g. "=", ">", etc.) of an alert to be color-coded.
 *
 * @param {string} selectedFieldType    the data type of the field being color
 *                                      coded; may be undefined if the field
 *                                      hasn't been set yet
 * @param {string} selectedCondition    the previously-selected condition to
 *                                      select by default; may be undefined
 * @param {string} setSelectedCondition callback function to notify when a
 *                                      condition has been selected
 * @param {number} index                index of the alert being edited in the
 *                                      list of alerts
 */
const ColorAlertCondition = ({selectedFieldType, selectedCondition,
    setSelectedCondition, index}) =>
{
    const optionToSelect = selectedCondition ?? "Condition"

    /**
     * Handles the condition selection event.  The selected condition is
     * passed back to the caller.
     *
     * @param {object} event   selection event; event.target.value contains
     *                         the condition
     */
    const handleCondition = (event) => {
        setSelectedCondition(event.target.value, index);
    };

    // Get the conditions allowed based on the field's data type
    let availableConditions = (selectedFieldType ?
             coloredConditionMap[selectedFieldType] : []);

    // Displays condition options in a drop-down list
    return (
        <div>
            <select name="selectCondition" id="selectCondition"
                value={optionToSelect}
                onChange={handleCondition}
            >
                <option value="Condition" key="Condition" disabled hidden>
                    Condition
                </option>
                {availableConditions.map(condition => (
                    <option value={condition} key={condition}>
                        {condition}
                    </option>
                ))}
            </select>
        </div>
    );
};

ColorAlertCondition.propTypes = {
    // The data type of the currently selected field, if any; passed from parent
    selectedFieldType: PropTypes.string,

    // The currently selected condition, if any; passed from parent
    selectedCondition: PropTypes.string,

    // Callback when a condition is selected; passed in from parent
    setSelectedCondition: PropTypes.func.isRequired,

    // Index of this alert in the list of alerts; passed in from parent
    index: PropTypes.number.isRequired,
};

export default ColorAlertCondition;
