import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { closeErrorModal } from "../../actions";

/**
 * Serves as a modal dialog for displaying errors with authorization
 * and authentication.
 */
class ErrorModal extends Component
{
    static propTypes =
    {
        // Flag to indicate if the modal should be shown; from redux
        isOpen: PropTypes.bool.isRequired,

        // Window title; from redux
        title: PropTypes.string,

        // Message to display; from redux
        errMsg: PropTypes.string,

        // Action creator to clear any error message; from redux
        closeErrorModal: PropTypes.func.isRequired
    };

    /**
     * Displays the Modal with customizable content. It will actually only
     * be open and shown if there is a message to display. The message passed
     * in via the props will be split on newline character. Everything before it
     * will be presented in a h3 tag, anything after it will be presented in an
     * h5 tag.
     *
     * @return {JSX.element} A modal dialog for rendering an error message
     */
    render()
    {
        if (this.props.isOpen && this.props.errMsg &&
           (this.props.errMsg.length > 0))
        {
            // Split on newline; first line will be emphasized
            const pieces = this.props.errMsg.split(/\n/);

            return (
                <Modal show={true} onHide={this.props.closeErrorModal} >
                    <Modal.Header>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {pieces[0] ?
                            <h3 className="text-danger">{pieces[0]}</h3> : ""}
                        {pieces[1] ?
                            <h5 className="text-danger">{pieces[1]}</h5> : ""}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                            onClick={this.props.closeErrorModal}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }
        else
        {
            return null;
        }
    }
}

/**
 * Adds the specified global state variables into props for easy access.
 *
 * @param {object} state   the current redux state
 *
 * @return {object} The desired redux state properties mapped to props
 */
const mapStateToProps = (state) =>
{
    return {
        isOpen: state.modalReducer.errorModalOpen,
        title: state.modalReducer.errorModalTitle,
        errMsg: state.modalReducer.errorModalText,
    };
};

/**
 * Adds the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    closeErrorModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
