import { Component } from "react";

/**
 * Serves as a base modal dialog for flashing the tab when alerting the user.
 */
class FlashyAlertModal extends Component
{
    static flashInterval = null;
    static titleList;
    static titleIndex;
    static iconLink;
    static baseIcon;
    static bellIcon;

    /**
     * Constructs the CandidatesAlertModal class. This is used to alert the user to
     * flights of interest based on being candidate for CDDR.
     *
     * @param {*}    props
     * @param {func} props.onAck   function to call to acknowledge flights
     */
    constructor(props)
    {
        super(props);
        if (!FlashyAlertModal.titleList) {
            FlashyAlertModal.titleList = [document.title];
            FlashyAlertModal.titleIndex = 0;
            FlashyAlertModal.iconLink = document.querySelector("link[rel~='icon']");
            FlashyAlertModal.baseIcon = FlashyAlertModal.iconLink.href;
            const canvas = document.createElement('canvas');
            canvas.height = 64;
            canvas.width = 64;
            const ctx = canvas.getContext('2d');
            ctx.font = '64px sans-serif';
            ctx.fillText("\u{1F514}", 0, 64);
            FlashyAlertModal.bellIcon = canvas.toDataURL();
        }
    }

    /**
     * Start an interval to alternate the tab title and icon between
     * its normal value and the given message with bell.
     * 
     * @param {string} message Text for title spot
     */
    flashTab(message)
    {
        this.alertMsg = message;
        FlashyAlertModal.titleList = Array.from((new Set(FlashyAlertModal.titleList)).add(message));
        if (FlashyAlertModal.flashInterval == null)
        {
            FlashyAlertModal.flashInterval = window.setInterval(() => {
                if (document.hidden)
                {
                    FlashyAlertModal.titleIndex = ++FlashyAlertModal.titleIndex %
                        FlashyAlertModal.titleList.length;
                    if (FlashyAlertModal.titleIndex === 0)
                    {
                        FlashyAlertModal.iconLink.href = FlashyAlertModal.baseIcon;
                    } else {
                        FlashyAlertModal.iconLink.href = FlashyAlertModal.bellIcon;
                    }
                    document.title = FlashyAlertModal.titleList[FlashyAlertModal.titleIndex];
                }
                else if (FlashyAlertModal.titleIndex !== 0)
                {
                    FlashyAlertModal.iconLink.href = FlashyAlertModal.baseIcon;
                    FlashyAlertModal.titleIndex = 0;
                    document.title = FlashyAlertModal.titleList[FlashyAlertModal.titleIndex];
                }
            }, 500);
        }
    }

    /**
     * Ends the flashing, clearing the interval and resetting title
     * and icon to base values.
     */
    endFlash()
    {
        if (FlashyAlertModal.flashInterval)
        {
            FlashyAlertModal.titleList = FlashyAlertModal.titleList.filter(msg => msg !== this.alertMsg);
            if (FlashyAlertModal.titleList.length === 1) {
                clearInterval(FlashyAlertModal.flashInterval);
                document.title = FlashyAlertModal.titleList[0];
                FlashyAlertModal.titleIndex = 0;
                FlashyAlertModal.iconLink.href = FlashyAlertModal.baseIcon;
                FlashyAlertModal.flashInterval = null;
                console.log("Ending flash", this.alertMsg);
            }
        }
    }
}

export default FlashyAlertModal;
