import { DateTime } from "luxon";

import { SEARCH_TYPE,
         TIME_TO_EXPIRATION_UNSET } from "../constants/TableConstants";
import { CoordinationState,
         EligibilityState,
         FlightStatus,
         TosSwimStatus,
         ImpactType,
         ImpactLevel } from "../constants/TosEnum";

/**
 * When {code}type{code} is {code}display{code}, returns the altitude 
 * directly up to 18000ft and as flight level (FL) above that; all other
 * values for {code}type{code} return the data.
 *
 * @param {object} data  the altitude from the cell
 * @param {string} type  type of render being done, 'display', 'sort',
 *                       'filter', or 'type'
 * @param {object} unusedRow   complete object from the row
 *
 * @return {string} representation to use
 */
export function formatAltitude(data, type, unusedRow)
{
    if (type === "display")
    {
        if (data && (data > 18000))
        {
            return "FL" + (data / 100);
        }
        else if (data || (data === 0))
        {
            return data;
        }
        else
        {
            return "UNKN";
        }
    }

    return data;
}


/**
 * When {code}type{code} is {code}display{code}, returns the data formatted as
 * "Y" if the data is true, or an emptry string if the data is false; all
 * other values for {code}type{code} return the data.
 *
 * @param {object} data        the boolean data from the cell
 * @param {string} type        type of render being done, 'display', 'sort',
 *                             'filter', 'filter2', or 'type'
 * @param {object} unusedRow   complete object from the row
 *
 * @return {string} representation to use
 */
export function formatBoolean(data, type, unusedRow)
{
    if (type === "display")
    {
        if (data === true)
        {
            return "Y";
        }
        return "";
    }
    else if (type === SEARCH_TYPE)
    {
        if (data === true)
        {
            return "true";
        }
        return "false";
    }

    return data;
}

/**
 * When {code}type{code} is {code}display{code}, returns the data formatted as
 * a checked box if the data is true, or an emptry box if the data is false; filter2
 * type will return the strings "true" or "false", all
 * other values for {code}type{code} return the data.
 *
 * @param {object} data        the boolean data from the cell
 * @param {string} type        type of render being done, 'display', 'sort',
 *                             'filter', 'filter2', or 'type'
 * @param {object} row         complete object from the row
 *
 * @return {string} representation to use
 */
export function formatInSwimCheckbox(data, type, row)
{
    if (type === "display")
    {
        const props = (row.isFiledRoute || row.isInitialRoute || !row.editing ? " disabled" : "") +
            ' data-route-id="' + row.id + '"';
        if (data === true)
        {
            return '<input type="checkbox" checked' + props + ' />'
        }
        else
        {
            return '<input type="checkbox"' + props + ' />'
        }
    }
    else if (type === SEARCH_TYPE)
    {
        if (data === true)
        {
            return "true";
        }
        return "false";
    }

    return data;
}

/**
 * When {code}type{code} is {code}display{code}, returns the coordination
 * state formatted with the appropriate text; when {code}type{code} is
 * {code}sort{code}, returns a sort order number; all other values for
 * {code}type{code} return the data.
 *
 * @param {object} data  the coordination state from the cell
 * @param {string} type  type of render being done, 'display', 'sort',
 *                       'filter', or 'type'
 * @param {object} row   complete object from the row
 *
 * @return {string} representation to use
 */
export function formatCoordinationState(data, type, row)
{
    if (type === "display")
    {
        if (data)
        {
            if (CoordinationState[data])
            {
                return CoordinationState[data].display;
            }
            else
            {
                logMissingData("Coordination State", data, row);
                return "UNKN";
            }
        }
        else
        {
            if (row.isFiledRoute || row.isInitialRoute)
            {
                return "";
            }
            else
            {
                logMissingData("Coordination State", data, row);
                return "UNKN";
            }
        }
    }
    else if (type === "sort")
    {
        if (data && CoordinationState[data])
        {
            return CoordinationState[data].sortOrder;
        }
        else
        {
            if ( !(row.isFiledRoute || row.isInitialRoute) )
            {
                logMissingData("Coordination State", data, row);
                return "UNKN";
            }
            return 0;
        }
    }

    return data;
}

/**
 * When {code}type{code} is {code}display{code}, returns the eligibility
 * state formatted with the appropriate text; when {code}type{code} is
 * {code}sort{code}, returns a sort order number; all other values for
 * {code}type{code} return the data.
 *
 * @param {object} data  the eligibility state from the cell
 * @param {string} type  type of render being done, 'display', 'sort',
 *                       'filter', or 'type'
 * @param {object} row   complete object from the row
 *
 * @return {string} representation to use
 */
export function formatEligibilityState(data, type, row)
{
    if (type === "display")
    {
        if (data)
        {
            if (EligibilityState[data])
            {
                return EligibilityState[data].display;
            }
            else
            {
                logMissingData("Eligibility State", data, row);
                return "UNKN";
            }
        }
        else
        {
            if (row.isFiledRoute || row.isInitialRoute)
            {
                return "";
            }
            else
            {
                logMissingData("Eligibility State", data, row);
                return "UNKN";
            }
        }
    }
    else if (type === "sort")
    {
        if (data && EligibilityState[data])
        {
            return EligibilityState[data].sortOrder;
        }
        else
        {
            if ( !(row.isFiledRoute || row.isInitialRoute) )
            {
                logMissingData("Eligibility State", data, row);
            }
            return 0;
        }
    }

    return data;
}

/**
 * When {code}type{code} is {code}display{code}, returns the flight status
 * state formatted with the appropriate text; when {code}type{code} is
 * {code}sort{code}, returns a sort order number; all other values for
 * {code}type{code} return the data.
 *
 * @param {object} data  the flight status from the cell
 * @param {string} type  type of render being done, 'display', 'sort',
 *                       'filter', or 'type'
 * @param {object} row   complete object from the row
 *
 * @return {string} representation to use
 */
export function formatFlightStatus(data, type, row)
{
    if (type === "display")
    {
        if (data && FlightStatus[data])
        {
            return FlightStatus[data].display;
        }
        else
        {
            logMissingData("Flight Status", data, row);
            return "UNKN";
        }
    }
    else if (type === "sort")
    {
        if (data && FlightStatus[data])
        {
            return FlightStatus[data].sortOrder;
        }
        else
        {
            logMissingData("Flight Status", data, row);
            return 0;
        }
    }

    return data;
}

/**
 * When {code}type{code} is {code}display{code}, returns the TOS SWIM
 * state formatted with the appropriate text; when {code}type{code} is
 * {code}sort{code}, returns a sort order number; all other values for
 * {code}type{code} return the data.
 *
 * @param {object} data  the coordination state from the cell
 * @param {string} type  type of render being done, 'display', 'sort',
 *                       'filter', or 'type'
 * @param {object} row   complete object from the row
 *
 * @return {string} representation to use
 */
export function formatSwimState(data, type, row)
{
    return formatTosEnum(TosSwimStatus, data, type, row);
}

/**
 * When {code}type{code} is {code}display{code}, returns the IMPACT TYPE
 * state formatted with the appropriate text, empty string when null; when {code}type{code} is
 * {code}sort{code}, returns a sort order number; all other values for
 * {code}type{code} return the data.
 *
 * @param {object} data  the coordination state from the cell
 * @param {string} type  type of render being done, 'display', 'sort',
 *                       'filter', or 'type'
 * @param {object} row   complete object from the row
 *
 * @return {string} representation to use
 */
export function formatImpactType(data, type, row)
{
    return formatTosEnum(ImpactType, data, type, row, "");
}

/**
 * When {code}type{code} is {code}display{code}, returns the IMPACT LEVEL
 * state formatted with the appropriate text, empty string when null; when {code}type{code} is
 * {code}sort{code}, returns a sort order number; all other values for
 * {code}type{code} return the data.
 *
 * @param {object} data  the coordination state from the cell
 * @param {string} type  type of render being done, 'display', 'sort',
 *                       'filter', or 'type'
 * @param {object} row   complete object from the row
 *
 * @return {string} representation to use
 */
export function formatImpactLevel(data, type, row)
{
    return formatTosEnum(ImpactLevel, data, type, row, "");
}

/**
 * When {code}type{code} is {code}display{code}, returns the enum
 * state formatted with the appropriate text; when {code}type{code} is
 * {code}sort{code}, returns a sort order number; all other values for
 * {code}type{code} return the data.
 *
 * @param {object} enum  the enum constant to check against
 * @param {object} data  the coordination state from the cell
 * @param {string} type  type of render being done, 'display', 'sort',
 *                       'filter', or 'type'
 * @param {object} row   complete object from the row
 *
 * @return {string} representation to use
 */
export function formatTosEnum(enumType, data, type, row, def="UNKN")
{
    if (type === "display")
    {
        if (data)
        {
            if (enumType[data])
            {
                return enumType[data].display;
            }
            else
            {
                return def;
            }
        }
        else
        {
            if (row.isFiledRoute || row.isInitialRoute)
            {
                return "";
            }
            else
            {
                return def;
            }
        }
    }
    else if (type === "sort")
    {
      console.log("Sorting", data, enumType[data]);
        if (data && enumType[data])
        {
            return enumType[data].sortOrder;
        }
        else
        {
            if ( !(row.isFiledRoute || row.isInitialRoute) )
            {
                return ""; // empty string to sort to the end
            }
            return 0;
        }
    }

    return data;
}

/**
 * Logs when data is unexpectedly missing
 *
 * @param {string} what  label to identify the missing data
 * @param {object} data  the data from the cell
 * @param {object} row   complete object from the row
 */
function logMissingData(what, data, row)
{
    let who = "";
    if (row && row.gufi)
    {
        who = "for " + row.gufi;
    }
    console.log("ERROR: Unknown", what, data, who);
}

/**
 * When {code}type{code} is {code}display{code}, returns the given value
 * formatted to include a "+" for positive numbers; all other values for 
 * {code}type{code} return the data.
 *
 * @param {object} data        the number from the column data option
 * @param {string} type        type of render being done, 'display', 'sort',
 *                             'filter', 'filter2', or 'type'
 * @param {object} unusedRow   complete object from the row
 *
 * @return {string} representation to use
 */
export function formatNumberWithSign(data, type, unusedRow)
{
    if ((type === "display") || (type === SEARCH_TYPE))
    {
        // Don't test using "if (data)" because that excludes 0
        if ((data !== null) && (data !== undefined) && !isNaN(data) &&
            (data !== ""))
        {
            // Convert the cell data to a number
            const value = data * 1;

            // Format the data
            let prefix = "";
            if (value > 0)
            {
                prefix = "+";
            }
            if (type === "display")
            {
                return prefix + data;
            }
            else
            {
                return data;
            }
        }
        else
        {
            return "";
        }
    }

    return data;
}

/**
 * When {code}type{code} is {code}display{code}, returns the given value
 * formatted to one decimal place including a "+" for positive numbers;
 * all other values for {code}type{code} return the data.
 *
 * @param {object} data         the number from the column data option
 * @param {string} type         type of render being done, 'display', 'sort',
 *                              'filter', 'filter2', or 'type'
 * @param {object} row          complete object from the row
 * @param {boolean} includePlus optional boolean to indicate if positive values
 *                              should have the '+' prefix, defaults to true
 * @param {string} suffix       optional suffix to add to end of value for
 *                              display, defaults to empty
 *
 * @return {string} representation to use
 */
export function formatNumberOneDecimal(data, type, row, includePlus=true,
    suffix="")
{
    if ((type === "display") || (type === SEARCH_TYPE))
    {
        // Don't test using "if (data)" because that excludes 0
        if ((data !== null) && (data !== undefined) && !isNaN(data))
        {
            let rounded = (Number(data)).toFixed(1).toString();
            let prefix = "";
            if ((data > 0.) && includePlus)
            {
                prefix = "+";
            }
            if (type === "display")
            {
                return prefix + rounded + suffix;
            }
            else
            {
                return rounded;
            }
        }
        return "";
    }

    return data;
}

/**
 * When {code}type{code} is {code}display{code}, returns the elasped seconds
 * value formatted as "mm:ss" or "hh:mm:ss"; all other values for
 * {code}type{code} return the data.
 *
 * @param {object}  data        the elapsed seconds from the cell
 * @param {string}  type        type of render being done, 'display', 'sort',
 *                              'filter', or 'type'
 * @param {object}  row         complete object from the row
 * @param {boolean} forceHour   true to force formatting of the hour
 *
 * @return {string} representation to use
 */
export function formatSecondsHHMMSS(data, type, row, forceHour)
{
    if (type === 'display')
    {
        if (data)
        {
            if(data < 0)
            {
                return "00:00"
            }
            else 
            {
                let date = new Date(data * 1000);
                let hour = addZero(date.getUTCHours());
                let min = addZero(date.getUTCMinutes());
                let sec = addZero(date.getUTCSeconds());

                if (forceHour || (date.getUTCHours() > 0))
                {
                    return hour + ":" + min + ":" + sec;
                }
                return min + ":" + sec;
            }
        }
        return "";
    }

    return data;
}

/**
 * When {code}type{code} is {code}display{code}, returns the time formatted as
 * "hh:mm"; all other values for {code}type{code} return the data.
 *
 * @param {object} data        the luxon DateTime from the cell
 * @param {string} type        type of render being done, 'display', 'sort',
 *                             'filter', or 'type'
 * @param {object} unusedRow   complete object from the row
 *
 * @return {string | object} representation to use
 */
export function formatDateTimeHHMM(data, type, unusedRow)
{
    if (data && (type === "display") && (data instanceof DateTime))
    {
        return data.toFormat("HH:mm");
    }

    return data;
}

/**
 * When {code}type{code} is {code}display{code}, returns the given value
 * truncated to the given length if needed, with a tooltip containing the
 * full value.
 *
 * @param {object} data  the string from the column data option
 * @param {string} type  type of render being done, 'display', 'sort',
 *                       'filter', or 'type'
 * @param {object} row   complete object from the row
 * @param {number} len   length to allow
 *
 * @return {string} representation to use
 */
export function formatLongString(data, type, row, len)
{
    if (type === "display")
    {
        if (data)
        {
            // see if the data is too wide for the column
            if (data.length > len)
            {
                // truncate the data to fit in the column
                let shorter = data.substring(0, len - 3) + "...";

                // div title automatically becomes a tooltip
                return '<div title="' + data + '">' + shorter;
            }
            return data;
        }
        return "";
    }

    return data;
}

/**
 * When {code}type{code} is {code}display{code}, returns a read only
 * input field with the most recent value of {code}data{code} truncated to
 * the given length if needed, with a tooltip containing the full value.
 *
 * @param {object} data  the scratch pad data, usually an array of strings
 * @param {string} type  type of render being done, 'display', 'sort',
 *                       'filter', or 'type'
 * @param {object} row   complete object from the row
 * @param {number} len   length to allow
 *
 * @return {string} html input representation to use
 */
export function formatScratch(data, type, row, len)
{
    var dataLine = data;

    // if sending full scratch pad, just display last message here
    if (Array.isArray(data))
    {
        if (data.length)
        {
            dataLine = data[data.length - 1].scratchPad;
        }
        else
        {
            dataLine = null;
        }
    }

    if (type === "display")
    {
        let shortData = dataLine;
        // see if the data is too wide for the column
        if (dataLine)
        {
            if (dataLine.length > len)
            {
                // truncate the data to fit in the column
                shortData = dataLine.substring(0, len - 3) + "...";
            }
        }
        else
        {
            shortData = "";
            dataLine = "";
        }

        return (row === null) ? dataLine : '<input type="text" class="scratch" name="' + row.gufi +
            '" value="' + shortData + '" title="' + dataLine + '" readonly />';
    }

    return dataLine;
}

/**
 * When {code}type{code} is {code}display{code}, returns the time to
 * expiration as-is; when {code}type{code} is {code}sort{code} or
 * {code}filter/filter2{code}, returns the time or Number.NEGATIVE_INFINITY
 * if the time is unset.
 *
 * @param {object} data        the flight status from the cell
 * @param {string} type        type of render being done, 'display', 'sort',
 *                             'filter', or 'type'
 * @param {object} unusedRow   complete object from the row
 *
 * @return {number} value to use
 */
export function formatTimeToExpiration(data, type, unusedRow)
{
    if (data)
    {
        // ** IMPORTANT NOTE **
        // columnConditions depends on the UNSET value being converted
        // to NEGATIVE_INFINITY for filtering purposes
        if (type === "sort")
        {
            if (data === TIME_TO_EXPIRATION_UNSET)
            {
                return Number.NEGATIVE_INFINITY;
            }
            else
            {
                return Number.parseInt(data);
            }
        }
        else if ((type === "filter") || (type === SEARCH_TYPE))
        {
            if (data === TIME_TO_EXPIRATION_UNSET)
            {
                return Number.NEGATIVE_INFINITY;
            }
            else
            {
                return Number.parseInt(data);
            }
        }
    }

    return data;
}

/**
 * When {code}type{code} is {code}display{code}, return the array as
 * a comma delimited string;
 *
 * @param {object} data        the flight status from the cell
 * @param {string} type        type of render being done, 'display', 'sort',
 *                             'filter', or 'type'
 * @param {object} unusedRow   complete object from the row
 *
 * @return {string} string value to use
 */
export function formatArray(data, type, unusedRow)
{
    if (data && (type === 'display'))
    {
        if (Array.isArray(data))
        {
            return data.join(", ");
        }
        else
        {
            return data.toString();
        }
    }

    return data;
}

/**
 * Adds a '0' to the given number if it's smaller than 10.
 *
 * @param {number} num  The number to which '0' will be prepended
 *
 * @return {string} The number with a leading '0' added
 */
export function addZero(num)
{
    if (num < 10)
    {
        num = "0" + num;
    }
    return num;
}
