import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import $ from "jquery";
//Defines the look of the context menu
import "jquery-contextmenu/dist/jquery.contextMenu.min.css";

import DatatablesComponent from "./DatatablesComponent";

import { addRouteInformation,
         removeFlightMenuGufi,
         updateOrdering } from "../../actions";
import { getColumnByValue } from "../../constants/ColumnField";
import { FIXED_ROUTE_COLUMNS } from "../../column_utils/columnDefaults";
import { ATC_APPROVER_ROLES,
         FO_ROLES,
         IN_SWIM_STATUSES } from "../../constants/Constants";
import { FLIGHT_MENU_ID } from "../../constants/TableConstants";
import { tosClient } from "../../utils/tosClient";
import { copySort, equalSort } from "../../utils/tableUtils";
import { actionUtils } from "../../utils/actionUtils";
import userConfig from "../../config/user_config.json";

import "../../css/tables.css";
import { AtcAction, FoAction } from "../../constants/TosEnum";

require("jquery-contextmenu");

// The tableOptions object is a collection of properties to be passed to the
// datatable upon creation.
const tableOptions = {
    // default all columns to empty string with no word wrap
    columnDefs: [{
        targets: "_all",
        defaultContent: "",
        className: "dt-body-nowrap",
        searchable: false,
    }],

    // Below in mapStateToProps we force the first column to be the initial/filed route and second column to be the top option
    // Force the sort to keep the initial/filed route fixed as first row (TOS_FM_CURRENT_ROUTE_COLUMN)
    orderFixed: [0, "desc"],
    pageLength: -1,
    rowId: function(row) {
        return row.id ? row.id : "current";
    },
    createdRow: function(row, data, unusedDataIndex) {
        if (data.id)
        {
            $(row).addClass("route-option");
        }
        if (data.isTopRoute)
        {
            $(row).addClass("top-option");
        }
        $(row).data({
            "eligibilityStatus": data.eligibilityStatus,
            "coordinationStatus": data.coordinationStatus,
        });
    },
    // We really just use half of the built-in state saving. Their
    // loading will end if it sees that the number of columns has
    // changed. But the saving is good for getting the current
    // sort configuration, which we pull off into redux. stateSaveCallback
    // is defined in the constructor, where it has access to props.
    stateSave: true,
    stateLoadCallback: function(unusedSettings) {
        // Don't load from the saved state, so don't bother loading
        return {};
    },
    stateLoadParams: function(unusedSettings, unusedData) {
        // Don't load from the saved state, use what was set in options
        return false;
    },
    deferRender: true,
};

/**
 * This class is used to create and manage a single Flight Menu table.
 * It also enables the various interactive features like highlighting on
 * selection and sorting by column.
 */
class FlightMenu extends Component
{
    static propTypes = {
        // Flight key displayed in this table; passed in from parent
        gufi: PropTypes.string.isRequired,

        // Flight acid of this table; extracted from gufi (above)
        tableName: PropTypes.string.isRequired,

        // Parent table group id; passed in from parent
        tableGroupId: PropTypes.string.isRequired,

        // Function to access the column selection page; passed in from parent
        colSelection: PropTypes.func.isRequired,

        // Function to close the flight menu; passed in from parent
        onClose: PropTypes.func.isRequired,

        // Context menu update handler; passed in from parent
        handleContextMenuUpdate: PropTypes.func,

        // Data to display; from redux
        flightRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,

        // Columns selected for this table; from redux
        columns: PropTypes.arrayOf(PropTypes.object),

        // Sorting definition for this table; from redux
        ordering: PropTypes.arrayOf(PropTypes.array),

        // Currently logged-in user; from redux
        user: PropTypes.object,

        // Flight-level data for this flight; from redux
        flightLevelInfo: PropTypes.object,

        // Flag to indicate if the user can submit route options; from redux
        readOnly: PropTypes.bool,

        // Function to get the flight route information; from redux
        addRouteInformation: PropTypes.func.isRequired,

        // Action creator to remove a flight menu; from redux
        removeFlightMenuGufi: PropTypes.func.isRequired,

        // Action creator to update the table sort order; from redux
        updateOrdering: PropTypes.func.isRequired,
    };

    /**
     * Constructs the FlightMenu class, which is used to generate a data table
     * with the given options in props.
     *
     * @param {*}      props
     * @param {string} props.gufi           flight key of flight displayed in
     *                                      the table
     * @param {string} props.tableGroupId   ID of this table's parent table
     * @param {func}   props.colSelection   function to access column selection
     * @param {func}   props.onClose        function to close this table
     */
    constructor(props)
    {
        super(props);
        // 'lfrtip' is the default layout for the datatable.net components,
        // but we only want any p[r]ocessing message (which we
        // don't ever have) and the [t]able.
        tableOptions.dom = '<"' + this.props.tableName + '"rt>';

        let updateSortOrder = this.updateSortOrder.bind(this);
        // need props to set the state save callback function
        tableOptions.stateSaveCallback = (settings, data) => {
            updateSortOrder(data.order);
        };

        // ref for datatable
        this.flightMenuTableRef = React.createRef();

        // increment the index every time we create a flight menu
        this.constructor.counter = (this.constructor.counter || 0) + 1;
        this.tableIndex = this.constructor.counter;

        // set sort if one has been defined
        if (this.props.ordering && this.props.ordering.length)
        {
            tableOptions.order = copySort(this.props.ordering);
        }

        // Bind these accessors for use in event handlers
        this.getFlightInfo = this.getFlightInfo.bind(this);
        this.isReadOnly = this.isReadOnly.bind(this);
        this.localInSwim = {};
        this.swimInclusionListener = this.swimInclusionListener.bind(this);
        this.postSingleOptionUpdate = this.postSingleOptionUpdate.bind(this);

        this.state = {
            editMode: false,
            fullList: null,
        };
    }

    /**
     * If the sort order of the table has changed from what we have in redux,
     * updates redux.
     */
    updateSortOrder(tableOrder)
    {
        if (!equalSort(this.props.ordering, tableOrder))
        {
            this.props.updateOrdering(FLIGHT_MENU_ID, tableOrder);
        }
    }

    /**
     * Gets the flight level info object from redux.
     *
     * @return {object} flight level info saved off for access in flight menu
     */
    getFlightInfo()
    {
        return this.props.flightLevelInfo;
    }

    /**
     * Used to pass new data to the table.
     */
    updateTableData()
    {
        let localData = this.props.flightRoutes.map((data) => ({
            ...data,
            editing: this.state.editMode,
            hasFiled: this.props.flightLevelInfo?.hasFiled,
            localInSwim: data.isFiledRoute || data.isInitialRoute ||
                (this.localInSwim[data.id] ?? IN_SWIM_STATUSES.includes(data.swimStatus)),
        }));

        // local variable isFullList to use before possible state update flows through
        let isFullList = this.state.fullList;
        if ((this.state.fullList === null) &&
            localData.some(data => (! (data.isFiledRoute || data.isInitialRoute) &&
              (data.localInSwim || (data.eligibilityStatus === 'CANDIDATE'))))) {
            isFullList = false;
            this.setState((curState) => ({
                ...curState,
                fullList: false,
            }));
        }

        if ((isFullList === false) && !this.state.editMode)
        {
            localData = localData.filter(data => {
                return data.localInSwim || data.inSwim ||
                    (data.eligibilityStatus === 'CANDIDATE');
            });
        }
        this.flightMenuTableRef.current.reloadTableData(localData);
    }

    /**
     * If this react component has been created, then set up the context menu.
     */
    componentDidMount()
    {
        if (this.flightMenuTableRef.current) {
            this.defineContextMenu();
            
            /**
             * Adjust the columns width when the flight menu is mounted
             * as the flight menu may cause the flight table (parent table) width to stretch.
             */
            $("#" + this.props.tableGroupId).DataTable().columns.adjust();
        }
    }

    /**
     * Defines the right-click context menu for flight menu.
     */
    defineContextMenu()
    {
        // callback functions will be in row context, so reassign class fields
        let user = this.props.user;
        let addRoutes = this.props.addRouteInformation;
        let gufi = this.props.gufi;
        let postUpdate = this.postUpdate;
        let postSingleOptionUpdate = this.postSingleOptionUpdate;
        let getFlightInfo = this.getFlightInfo;
        let readOnly = this.isReadOnly;
        let closeWindow = this.props.onClose;
        const unactable = readOnly() || getFlightInfo().international;

        let itemList;
        if (readOnly())
        {
            itemList = {
                filler: {
                    name: "Route options unavailable",
                    disabled: true,
                },
            };
        }
        else if (FO_ROLES.includes(user.role))
        {
            itemList = {
                "submit": {
                    name: "Request Reroute",
                    callback: function(unusedKey, unusedOptions) {
                        console.log("Request Reroute " + $(this).attr("id") +
                            " for " + gufi);
                        postUpdate(tosClient.postOperatorUpdate, user.role,
                            user.roleUser, getFlightInfo().tosId,
                            $(this).attr("id"), FoAction.REQUEST_TOS_ROUTE.name, gufi,
                            addRoutes);
                        postSingleOptionUpdate(user.role, user.roleUser,
                            getFlightInfo().tosId, $(this).attr("id"), gufi);
                        closeWindow(gufi);
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return unactable ||
                               (!($(this).hasClass("route-option") &&
                                   actionUtils.enableRequest(
                                       getFlightInfo().eligibilityStatus,
                                       getFlightInfo().coordinationStatus,
                                       $(this).data("coordinationStatus"))));
                    },
                },
                "undoSubmit": {
                    name: "Undo Request Reroute",
                    callback: function(unusedKey, unusedOptions) {
                        console.log("Undo Request Reroute " + $(this).attr("id") +
                            " for " + gufi);
                        postUpdate(tosClient.postOperatorUpdate, user.role,
                            user.roleUser, getFlightInfo().tosId,
                            $(this).attr("id"), FoAction.CANCEL_REQUEST_TOS_ROUTE.name, gufi,
                            addRoutes);
                        closeWindow(gufi);
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return unactable ||
                               (!($(this).hasClass("route-option") &&
                                   actionUtils.enableUnrequest(
                                       getFlightInfo().coordinationStatus,
                                       $(this).data("coordinationStatus"))));
                    },
                },
                "divider": "-----",
                "approve": {
                    name: "Approve Opportunity",
                    callback: function(unusedKey, unusedOptions) {
                        console.log("Approve Opportunity " + $(this).attr("id") +
                            " for " + gufi);
                        postUpdate(tosClient.postOperatorUpdate, user.role,
                            user.roleUser, getFlightInfo().tosId,
                            $(this).attr("id"), FoAction.APPROVE_TOS_ROUTE.name, gufi,
                            addRoutes);
                        closeWindow(gufi);
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return readOnly() || getFlightInfo().international ||
                               (!($(this).hasClass("route-option") &&
                                   actionUtils.enableFoApprove(
                                       getFlightInfo().coordinationStatus,
                                       $(this).data("coordinationStatus"))));
                    },
                },
                "undoApprove": {
                    name: "Undo Approve Opportunity",
                    callback: function(unusedKey, unusedOptions) {
                        console.log("Undo Approve Opportunity " + $(this).attr("id") +
                            " for " + gufi);
                        postUpdate(tosClient.postOperatorUpdate, user.role,
                            user.roleUser, getFlightInfo().tosId,
                            $(this).attr("id"), FoAction.UNAPPROVE_TOS_ROUTE.name, gufi,
                            addRoutes);
                        closeWindow(gufi);
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return readOnly() || getFlightInfo().international ||
                               (!($(this).hasClass("route-option") &&
                                   actionUtils.enableFoUnapprove(
                                       getFlightInfo().coordinationStatus,
                                       $(this).data("coordinationStatus"))));
                    },
                },
                "divider2": "-----",
                "unable": {
                    name: "Unable",
                    callback: function(unusedKey, unusedOptions) {
                        console.log("Unable Opportunity " + $(this).attr("id") +
                            " for " + gufi);
                        postUpdate(tosClient.postOperatorUpdate, user.role,
                            user.roleUser, getFlightInfo().tosId,
                            $(this).attr("id"), FoAction.UNABLE_TOS_ROUTE.name, gufi,
                            addRoutes);
                        closeWindow(gufi);
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return readOnly() || getFlightInfo().international ||
                               (!($(this).hasClass("route-option") &&
                                   actionUtils.enableFoUnable(
                                       getFlightInfo().coordinationStatus,
                                       $(this).data("coordinationStatus"))));
                    },
                },
                "undoUnable": {
                    name: "Undo Unable",
                    callback: function(unusedKey, unusedOptions) {
                        console.log("Undo Unable Opportunity " + $(this).attr("id") +
                            " for " + gufi);
                        postUpdate(tosClient.postOperatorUpdate, user.role,
                            user.roleUser, getFlightInfo().tosId,
                            $(this).attr("id"), FoAction.UNUNABLE_TOS_ROUTE.name, gufi,
                            addRoutes);
                        closeWindow(gufi);
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return readOnly() || getFlightInfo().international ||
                               (!($(this).hasClass("route-option") &&
                                   actionUtils.enableFoUnunable(
                                       getFlightInfo().coordinationStatus,
                                       $(this).data("coordinationStatus"))));
                    },
                },
            };
        }
        else if (ATC_APPROVER_ROLES.includes(user.role))
        {
            itemList = {
                "approve": {
                    name: "Approve",
                    callback: function(unusedKey, unusedOptions) {
                        console.log("Approved " + $(this).attr("id") +
                            " for " + gufi);
                        postUpdate(tosClient.postAtcUpdate, user.role,
                            user.roleUser, getFlightInfo().tosId,
                            $(this).attr("id"), AtcAction.APPROVE_TOS_ROUTE.name, gufi,
                            addRoutes);
                        closeWindow(gufi);
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return unactable ||
                               (!($(this).hasClass("route-option") &&
                                   actionUtils.hasAtcAuthority(user.role,
                                       getFlightInfo().origin) &&
                                   actionUtils.enableAtcApprove(
                                       getFlightInfo().coordinationStatus,
                                       (this).data("coordinationStatus"))));
                    },
                },
                "undoApprove": {
                    name: "Undo Approve",
                    callback: function(unusedKey, unusedOptions) {
                        console.log("Undo Approve " + $(this).attr("id") +
                            " for " + gufi);
                        postUpdate(tosClient.postAtcUpdate, user.role,
                            user.roleUser, getFlightInfo().tosId,
                            $(this).attr("id"), AtcAction.UNAPPROVE_TOS_ROUTE.name, gufi,
                            addRoutes);
                        closeWindow(gufi);
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return unactable ||
                               (!($(this).hasClass("route-option") &&
                                   actionUtils.hasAtcAuthority(user.role,
                                       getFlightInfo().origin) &&
                                   actionUtils.enableAtcUnapprove(
                                       getFlightInfo().coordinationStatus,
                                       $(this).data("coordinationStatus"))));
                    },
                },
                "divider": "-----",
                "unable": {
                    name: "Unable",
                    callback: function(unusedKey, unusedOptions) {
                        console.log("Unable " + $(this).attr("id") +
                            " for " + gufi);
                        postUpdate(tosClient.postAtcUpdate, user.role,
                            user.roleUser, getFlightInfo().tosId,
                            $(this).attr("id"), AtcAction.UNABLE_TOS_ROUTE.name, gufi,
                            addRoutes);
                        closeWindow(gufi);
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return unactable ||
                               (!($(this).hasClass("route-option") &&
                                   actionUtils.hasAtcAuthority(user.role,
                                       getFlightInfo().origin) &&
                                   actionUtils.enableAtcUnable(
                                       getFlightInfo().coordinationStatus,
                                       (this).data("coordinationStatus"))));
                    },
                },
                "undoUnable": {
                    name: "Undo Unable",
                    callback: function(unusedKey, unusedOptions) {
                        console.log("Undo Unable " + $(this).attr("id") +
                            " for " + gufi);
                        postUpdate(tosClient.postAtcUpdate, user.role,
                            user.roleUser, getFlightInfo().tosId,
                            $(this).attr("id"), AtcAction.UNUNABLE_TOS_ROUTE.name, gufi,
                            addRoutes);
                        closeWindow(gufi);
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return unactable ||
                               (!($(this).hasClass("route-option") &&
                                   actionUtils.hasAtcAuthority(user.role,
                                       getFlightInfo().origin) &&
                                   actionUtils.enableAtcUnunable(
                                       getFlightInfo().coordinationStatus,
                                       (this).data("coordinationStatus"))));
                    },
                },
                "divider2": "-----",
                "propose": {
                    name: "Propose",
                    callback: function(unusedKey, unusedOptions) {
                        console.log("Propose " + $(this).attr("id") +
                            " for " + gufi);
                        postUpdate(tosClient.postAtcUpdate, user.role,
                            user.roleUser, getFlightInfo().tosId,
                            $(this).attr("id"), AtcAction.PROPOSE_TOS_ROUTE.name, gufi,
                            addRoutes);
                        closeWindow(gufi);
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return readOnly() || getFlightInfo().international ||
                               (!($(this).hasClass("route-option") &&
                                   actionUtils.hasAtcAuthority(user.role,
                                       getFlightInfo().origin) &&
                                   actionUtils.enablePropose(
                                       getFlightInfo().eligibilityStatus,
                                       getFlightInfo().coordinationStatus,
                                       (this).data("coordinationStatus"))));
                    },
                },
                "undoPropose": {
                    name: "Undo Propose",
                    callback: function(unusedKey, unusedOptions) {
                        console.log("Undo Propose " + $(this).attr("id") +
                            " for " + gufi);
                        postUpdate(tosClient.postAtcUpdate, user.role,
                            user.roleUser, getFlightInfo().tosId,
                            $(this).attr("id"), AtcAction.CANCEL_PROPOSE_TOS_ROUTE.name, gufi,
                            addRoutes);
                        closeWindow(gufi);
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return readOnly() || getFlightInfo().international ||
                               (!($(this).hasClass("route-option") &&
                                   actionUtils.hasAtcAuthority(user.role,
                                       getFlightInfo().origin) &&
                                   actionUtils.enableUnpropose(
                                       getFlightInfo().coordinationStatus,
                                       $(this).data("coordinationStatus"))));
                    },
                },
            };
        }

        if (itemList)
        {
            let flightMenuTableId = "#" + FLIGHT_MENU_ID + "_" + this.tableIndex;
            let contextMenu = {
                selector: "tr",
                trigger: "right",
                build: function() {
                  return {
                    items: itemList,
                    autoHide: true,
                  }
                }
            }

            // Set the context menu only if the flight menu is currently visible in the table
            if ($(flightMenuTableId).length > 0) {
                $(flightMenuTableId).contextMenu(contextMenu);
            }

            // Handle the context menu update
            if (this.props.handleContextMenuUpdate) {
                this.props.handleContextMenuUpdate(gufi, contextMenu);
            }
        }
    }

    /**
     * Listener to toggle inclusion of route in SWIM selection.
     */
    swimInclusionListener(event)
    {
        event.preventDefault();
        const routeId = event.target.dataset.routeId;
        const dataTable = this.flightMenuTableRef.current.dataTable;
        const rowData = dataTable.row("#" + routeId).data();
        rowData.localInSwim = !rowData.localInSwim;
        this.localInSwim[routeId] = rowData.localInSwim;
        dataTable.row("#" + routeId).data(rowData).draw();
    }

    /**
     * Gets if the current settings mean this is display only. For access in
     * context menu functions.
     */
    isReadOnly()
    {
        return this.props.readOnly;
    }

    /**
     * Posts the update to the server, logs the response, and refreshes the
     * flight menu data.
     *
     * @param {function} postFunction function used to post to the server
     * @param {string} role           user role
     * @param {string} roleUser       specific user within the role
     * @param {string} tosId          TOS id for update
     * @param {string} cfrId          CFR id for update
     * @param {string} action         action for update
     * @param {string} gufi           gufi to request data refresh
     * @param {function} addRoutes    function used to request data refresh
     */
    postUpdate(postFunction, role, roleUser, tosId, cfrId, action, gufi,
        addRoutes)
    {
        postFunction(role, roleUser, tosId, cfrId, action)
        .then((unusedResponse) => {
            // logging this response seems to be necessary to get the refresh
            // to wait long enough, I don't know why
            console.log("Successfully retrieved flight routes for", gufi);
            let carrier = userConfig[roleUser].carrierForRequests;
            addRoutes(role, carrier, gufi);
            return true;
        })
        .catch((errMsg) =>
        {
            console.log("Error!", errMsg);
            return false;
        });
    }

    /**
     * Posts the update to the server, logs the response, and refreshes the
     * flight menu data.
     *
     * @param {string} role           user role
     * @param {string} roleUser       specific user within the role
     * @param {string} tosId          TOS id for update
     * @param {string} gufi           gufi to request data refresh
     */
    postSwimUpdate(role, roleUser, tosId, gufi)
    {
        const actionArray = Object.entries(this.localInSwim).map(([routeId, included]) => ({
            routeId,
            action: included ? FoAction.SAVE_SWIM.name : FoAction.CANCEL_SWIM.name,
        }));
        tosClient.postOperatorArrayUpdate(role, roleUser, tosId, actionArray)
        .then((unusedResponse) => {
            // logging this response seems to be necessary to get the refresh
            // to wait long enough, I don't know why
            console.log("Successfully retrieved flight routes for", gufi);
            let carrier = userConfig[roleUser].carrierForRequests;
            this.props.addRouteInformation(role, carrier, gufi);
            this.localInSwim = {}
            return true;
        })
        .catch((errMsg) =>
        {
            console.log("Error!", errMsg);
            this.localInSwim = {}
            return false;
        });
    }

    /**
     * Updates localInSwim to just the chosen option, posts the update to the
     * server, logs the response, and refreshes the flight menu data.
     *
     * @param {string} role           user role
     * @param {string} roleUser       specific user within the role
     * @param {string} tosId          TOS id for update
     * @param {string} optionId       TOS options id to select
     * @param {string} gufi           gufi to request data refresh
     * @param {array}  flightRoutes   all the flight route option info
     */
    postSingleOptionUpdate(role, roleUser, tosId, optionId, gufi)
    {
        this.props.flightRoutes.forEach((option) => {
            if (option.id === optionId)
            {
                if (!IN_SWIM_STATUSES.includes(option.swimStatus))
                {
                    this.localInSwim[optionId] = true;
                }
            }
            else if (IN_SWIM_STATUSES.includes(option.swimStatus))
            {
                this.localInSwim[option.id] = false;
            }
        });
        this.postSwimUpdate(role, roleUser, tosId, gufi);
    }

    /**
     * If this component did update, then check if the table data was changed.
     * If so, then update the table.
     *
     * @param {object} prevProps The props object before the update
     */
    componentDidUpdate(prevProps)
    {
        // update sort if columns changed
        if (this.props.ordering !== prevProps.ordering)
        {
            this.flightMenuTableRef.current.dataTable.order(
                copySort(this.props.ordering));
        }

        // update data if changed
        if (this.props.flightRoutes)
        {
            this.updateTableData();

            /**
             * This is not needed anymore as scrollX prop of the data table is set to false.
             * But if adjusting the parent columns is required when scrollX is enabled,
             * you should save and restore the scroll Y position of the table managers
             * after adjusting the parent columns because this adjustment cause
             * the column elements to scroll into view.
             * 
             * const scrollTop = $(".main-frame").scrollTop();
             * $("#" + this.props.tableGroupId).DataTable().columns.adjust();
             * $(".main-frame").scrollTop(scrollTop)
             */
        }
    }

    /**
     * Deals with the Columns button click. Redirect to the column
     * selection page.
     *
     * @param {React.changeEvent} unusedEvent  The button click event
     */
    processColumnsClick = (unusedEvent) =>
    {
        // Go to column selection page
        this.props.colSelection(FLIGHT_MENU_ID);
    };

    /**
     * Toggles whether to show all routes or only included and candidate routes.
     * 
     * @param {React.changeEvent} event  The button click event
     */
    toggleFullList = (event) =>
    {
        event.stopPropagation();
        this.setState(curState => ({
            ...curState,
            fullList: (curState.fullList === null) ? false : !curState.fullList,
        }));
        this.updateTableData();
    }

    /**
     * Enter edit mode.
     *
     * @param {React.changeEvent} event  The button click event
     */
    enterEditMode = (event) =>
    {
        event.stopPropagation();
        this.setState({
            ...this.state,
            editMode: true,
        });

        this.updateTableData();
        $("#" + FLIGHT_MENU_ID + "_" + this.tableIndex + " tbody").on("click",
            "td.in-tos input", this.swimInclusionListener);
    }

    /**
     * Clear the locally updated included routes and reverts to values from
     * the server.
     * 
     * @param {React.changeEvent} event  The button click event
     */
    cancelEditMode = (event) =>
    {
        event.stopPropagation();
        this.setState({
            ...this.state,
            editMode: false,
        });

        this.localInSwim = {};
        $("#" + FLIGHT_MENU_ID + "_" + this.tableIndex + " tbody").off("click",
          "td.in-tos input", this.swimInclusionListener);
        this.updateTableData();
    }

    /**
     * Saves the locally included routes and sends the update back to the
     * server for processing.
     * 
     * @param {React.changeEvent} event  The button click event
     */
    saveIncluded = (event) =>
    {
        event.stopPropagation();
        this.setState({
          ...this.state,
          editMode: false,
          fullList: false,
        });
        this.postSwimUpdate(this.props.user.role, this.props.user.roleUser,
          this.props.flightLevelInfo.tosId, this.props.gufi);

        $("#" + FLIGHT_MENU_ID + "_" + this.tableIndex + " tbody").off("click",
            "td.in-tos input", this.swimInclusionListener);
        this.updateTableData();
    }

    /**
     * Deals with the Close button click. Removes this gufi from the flight
     * menu list in redux.
     *
     * @param {React.changeEvent} unusedEvent  The button click event
     */
    processCloseClick = (unusedEvent) =>
    {
        this.props.onClose(this.props.gufi);
    };

    /**
     * Set up the table wrapper for rendering.
     *
     * @return {JSX.element} The container/wrapper for the formatted table
     */
    render()
    {
        return (
            <flight-menu-group class={this.state.editMode ? "editing" : ""}>
                <spaced-line>
                    <spaced-line--left>
                        <button onClick={this.processColumnsClick}
                            title="Select and adjust order of columns">
                            <label>Columns</label>
                        </button>
                    </spaced-line--left>

                    <spaced-line--center>
                        <b>Route Options Menu - {this.props.tableName}</b>
                    </spaced-line--center>

                    <spaced-line--right>
                        <button onClick={this.toggleFullList}
                            title={(this.state.fullList === false) ?
                              "Show full list" :
                              "Show limited list"}>
                            <label>
                                {(this.state.fullList === false) ?
                                    "Expand" :
                                    "Minimize"}
                            </label>
                        </button>
                        { FO_ROLES.includes(this.props.user.role) ?
                          <>
                            { this.state.editMode ?
                                <button onClick={this.cancelEditMode}
                                    title="Cancel edit mode">
                                    <label>Cancel</label>
                                </button> :
                                <button onClick={this.enterEditMode}
                                    title="Enter edit mode" disabled={this.isReadOnly()}>
                                    <label>Edit</label>
                                </button>
                            }
                            <button onClick={this.saveIncluded}
                                title="Save included routes"
                                disabled={!this.state.editMode}>
                                <label>Save</label>
                            </button>
                          </> :
                          null
                        }
                        <button onClick={this.processCloseClick}
                            title="Close Route Options Menu"
                            className="close-button">
                            <label>X</label>
                        </button>
                    </spaced-line--right>
                </spaced-line>
                {
                    // Note: ref in DatatablesComponent initializes
                    // flightMenuTableRef with access to this
                    // DatatablesComponent instance.
                }
                <DatatablesComponent
                    ref={this.flightMenuTableRef}
                    columns={this.props.columns}
                    tableOptions={tableOptions}
                    tableId={FLIGHT_MENU_ID + "_" + this.tableIndex}
                />
            </flight-menu-group>
        );
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state The current redux state
 *
 * @return {object} The desired redux state properties mapped to props
 */
const mapStateToProps = (state, ownProps) =>
{
    // as mentioned above in the table defaults, force the first column to
    // be whether it's the initial/filed route for forced sort, default to TOP route next
    let allColumns = state.columnsReducer.columnsPerTable[FLIGHT_MENU_ID].slice();
    allColumns.unshift(...FIXED_ROUTE_COLUMNS);

    return {
        flightRoutes: state.dataReducer.flightRoutes.filter(
                function(value)
                {
                    return value.gufi === ownProps.gufi;
                }),
        tableName: ownProps.gufi.split(".")[0],
        columns: allColumns.map(
                column => getColumnByValue(column)),
        ordering: state.columnsReducer.orderPerTable[FLIGHT_MENU_ID],
        user: state.authentication.user,
        flightLevelInfo: state.dataReducer.flightInfoForRoutes[ownProps.gufi],
        readOnly: state.dataReducer.readOnly ||
                state.authentication?.user?.readOnly ||
                state.authentication?.user?.roleUser?.endsWith("RAMP"),
    }
};

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    addRouteInformation,
    removeFlightMenuGufi,
    updateOrdering,
};

export default connect(mapStateToProps, mapDispatchToProps) (FlightMenu);
