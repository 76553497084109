import React, { Component } from 'react'
import PropTypes from 'prop-types';

/**
 * This class is used to create the Apply and Cancel buttons as a component.
 */
class ApplyCancel extends Component
{
    static propTypes = {
        // The function used to process the Apply button click
        onApplyClick: PropTypes.func.isRequired,

        // The function used to process the Cancel button click
        onCancelClick: PropTypes.func.isRequired,
    };

    /**
     * Constructs the ApplyCancel class, which renders the Apply and Cancel 
     * buttons.
     *
     * @param {*}    props
     * @param {func} props.onApplyClick   callback when Apply is clicked
     * @param {func} props.onCancelClick  callback when Cancel is clicked
     */
    constructor(props)
    {
        super(props);

        this.state = {};
    }

    /**
     * Sets up the Apply and Cancel buttons for rendering.
     *
     * @return {JSX.element} The Apply and Cancel buttons
     */
    render()
    {
        return (
          <apply-cancel-group>
            <button onClick={this.props.onApplyClick}>
              <label>Apply</label>
            </button>
            <button onClick={this.props.onCancelClick}>
              <label>Cancel</label>
            </button>
          </apply-cancel-group>
        );
    }
}

export default ApplyCancel;
