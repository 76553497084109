import { LOGOUT } from "tos-web-admin-client";

import { getUserDefaultDataTableColumns,
         getUserDefaultRouteMenuColumns } from "../column_utils/columnDefaults";
import  * as types from "../constants/ActionTypes";
import { FLIGHT_MENU_ID, SHOW_ME_TABLE_ID } from "../constants/TableConstants";
import { copySort } from "../utils/tableUtils";

/**
 * This file contains the Redux reducer for managing the columns for their
 * respective tables. columnsPerTable is an object holding an array of
 * column names for each table property. orderPerTable is an object holding
 * a 2D array of [ column number, "asc" or "desc" ] for each column sorted,
 * for each table property.
 */

/**
 * The default state for columns reducer state object: default columns for
 * search and alert table, no columns for flight
 * tables, default columns for flight menus, and no sorting.
 */
const userColumns = getUserDefaultDataTableColumns();
const DEFAULT_COLUMNS_REDUCER_STATE = {
    columnsPerTable: {
        [SHOW_ME_TABLE_ID]: [ ...userColumns ],
    },
    orderPerTable: {}
};
const userRouteColumns = getUserDefaultRouteMenuColumns();
DEFAULT_COLUMNS_REDUCER_STATE.columnsPerTable[FLIGHT_MENU_ID] =
   [ ...userRouteColumns ];

/**
 * The columns reducer used to update the table columns.
 *
 * @param {object} state   The current Redux state, with initial value set to
 *                         the default tables array.
 * @param {object} action  The Redux action object containing the action type
 *                         and the data necessary for the specified action.
 */
export default function columnsReducer(state = DEFAULT_COLUMNS_REDUCER_STATE,
                                       action = {}) {
    // Redux changes need to be immutable. So for every change three things
    // need to happen.
    // 1. Shallow copy of containing objects.
    // 2. Apply desired modification to copy.
    // 3. Bundle the changes in with the rest of the unmodified state and
    //    return it. The return should be a copy of the current state, but with
    //    the desired modification.
    switch (action.type)
    {
        case types.ADD_TABLE:
        {
            // Copy columns arrays in state
            let newColumnsPerTable = { ...state.columnsPerTable };

            // Add a new default columns array
            let userColumns = getUserDefaultDataTableColumns();
            newColumnsPerTable[action.payload.tableId] = [ ...userColumns ];

            // Return current state with modified columns arrays
            return {
                ...state,
                columnsPerTable: newColumnsPerTable
            };
        }

        case types.REMOVE_TABLE:
        {
            let newColumnsPerTable = { ...state.columnsPerTable };
            delete newColumnsPerTable[action.payload.tableId];

            let newOrderPerTable = { ...state.orderPerTable };
            delete newOrderPerTable[action.payload.tableId];

            return {
                ...state,
                columnsPerTable: newColumnsPerTable,
                orderPerTable: newOrderPerTable,
            };
        }

        case types.UPDATE_COLUMNS:
        {
            let newColumnsPerTable = { ...state.columnsPerTable };

            // Modify the columns array for the necessary table
            newColumnsPerTable[action.payload.tableId] = action.payload.data;

            return {
                ...state,
                columnsPerTable: newColumnsPerTable,
            };
        }

        case types.UPDATE_ORDER:
        {
            let newOrderPerTable = { ...state.orderPerTable };

            let newOrder = copySort(action.payload.data);

            // Modify the columns array for the necessary table
            newOrderPerTable[action.payload.tableId] = newOrder;

            return {
                ...state,
                orderPerTable: newOrderPerTable,
            };
        }

        case types.RESET_SETTINGS:
        {
            // make sure to get current user columns
            const userColumns = getUserDefaultDataTableColumns();
            return {
                columnsPerTable: {
                    [SHOW_ME_TABLE_ID]: [ ...userColumns ],
                },
                orderPerTable: {}
            };
        }

        case LOGOUT:
        {
            return {
                ...state,
                ...DEFAULT_COLUMNS_REDUCER_STATE,
            };
        }

        // By default return an unmodified state
        default:
            return state;
    }
}
