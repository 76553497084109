import React, { PureComponent } from 'react';
import Button from "react-bootstrap/Button";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addTable } from "../../actions";
import { DEPARTURE_TABLE_ID } from "../../constants/TableConstants";

/**
 * Provides a button to add a new TOS departure table. Only displays when a
 * user is logged in.
 */
class AddTableButton
    extends PureComponent
{
    static propTypes = {
        // dispatch function to add a new table, from Redux
        addTable: PropTypes.func.isRequired,

        // list of table ids currently in use
        tableGroups: PropTypes.arrayOf(PropTypes.string).isRequired,

        // indicates if a user is logged in and the button should display;
        // from Redux
        loggedIn: PropTypes.bool.isRequired,
    }

    /**
     * Constructs the AddTableButton component.
     *
     * @param {*} props    none passed in from parent
     */
    constructor(props)
    {
        super(props);

        this.nextTableId = 0;
    }

    /**
     * Adds a new table
     *
     * @param {React.changeEvent} unusedChangeEvent   the change event object
     */
    processAdd = unusedChangeEvent => {
        while (this.props.tableGroups.includes(DEPARTURE_TABLE_ID +
                   "_" + this.nextTableId))
        {
            this.nextTableId++;
        }
        let newTableId = DEPARTURE_TABLE_ID + "_" + this.nextTableId;
        this.props.addTable(newTableId);
    };

    /**
     * Displays the button only when a user is logged in.
     */
    render()
    {
        if (this.props.loggedIn)
        {
            // The "d-grid" is boostrap shorthand for "display:grid" and
            // is what causes the button to span the width of the window
            return (
                <div className="d-grid add-table-button">
                    <Button variant="light" onClick={this.processAdd} size="lg">
                        Add new table
                    </Button>
                </div>
            )
        }
        else
        {
            return null;
        }
    }
}

/**
 * Maps the Redux state to local props for access.
 *
 * @param {*} state full Redux state
 */
const mapStateToProps = (state) =>
{
    return {
        tableGroups: state.tablesReducer.tableGroups,
        loggedIn: state.authentication.loggedIn,
    }
};

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    addTable,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTableButton);
