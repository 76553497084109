import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

import rootReducer from "../reducers";

const loggerMiddleware = createLogger({
        collapsed: true,
    });

// Enable redux dev tool in the development environment for streamlined debugging.
const composeEnhancers = (process.env.REACT_APP_DIP_PLATFORM_ENVIRONMENT == 'dev' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

/**
 * Creates the Redux store for the tos table application, using thunk and
 * logging middleware.
 */
export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware))
);
