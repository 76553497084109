import { LOGOUT } from 'tos-web-admin-client';

import  * as types from '../constants/ActionTypes';
import { produce } from 'immer';

/**
 * This file contains the Redux reducer for tables. tableGroups is an array of
 * table ids. filterPerTable is an object containing a searchBuilder definition
 * for each table that is filtered. lengthPerTable is an object containing
 * the number of rows per page for each table.
 */

/**
 * The default state for tables reducer state object: everything empty
 */
const DEFAULT_TABLES_REDUCER_STATE = {
    tableGroups: [],
    filterPerTable: {},
    lengthPerTable: {},
    updatedTables: new Set(),
}

/**
 * The table reducer used to update the selected table.
 *
 * @param {object} state   The current Redux state, with initial value set to
 *                         the default.
 * @param {object} action  The Redux action object containing the action type
 *                         and the data necessary for the specified action.
 */
const tablesReducer = produce((state = DEFAULT_TABLES_REDUCER_STATE,
                                      action) =>
{
    // Redux changes need to be immutable. So for every change three things
    // need to happen.
    // 1. Shallow copy of containing objects.
    // 2. Apply desired modification to copy.
    // 3. Bundle the changes in with the rest of the unmodified state and
    //    return it. The return should be a copy of the current state, but with
    //    the desired modification.
    switch (action.type)
    {
        case types.START_UPDATE: {
            return {
                ...state,
                updatedTables: new Set(),
            };
        }
        case types.TABLE_UPDATED: {
            const newUpdatedTables = new Set(state.updatedTables);
            newUpdatedTables.add(action.payload.tableId);
            return {
                ...state,
                updatedTables: newUpdatedTables,
            };
        }
        case types.ADD_TABLE: {
            let addedGroupList = [ ...state.tableGroups,
                    action.payload.tableId ];
            return {
                ...state,
                tableGroups: addedGroupList,
            };
        }
        case types.MOVE_TABLE_DOWN: {
            let moveDownGroupList = [ ...state.tableGroups ];

            let moveDownIdx = moveDownGroupList.findIndex((tableId) =>
                tableId === action.payload.tableId);
            if ((moveDownIdx >= 0) &&
                (moveDownIdx < moveDownGroupList.length - 1)) {

                // Destructuring allows swapping values without
                // using temporary variables
                [ moveDownGroupList[moveDownIdx],
                  moveDownGroupList[moveDownIdx + 1] ] =
                    [ moveDownGroupList[moveDownIdx + 1],
                      moveDownGroupList[moveDownIdx] ];
            }

            return {
                ...state,
                tableGroups: moveDownGroupList,
            };
        }
        case types.MOVE_TABLE_UP: {
            let moveUpGroupList = [ ...state.tableGroups ];

            let moveUpIdx = moveUpGroupList.findIndex((tableId) =>
                tableId === action.payload.tableId);
            if (moveUpIdx > 0) {

                // Destructuring allows swapping values without
                // using temporary variables
                [ moveUpGroupList[moveUpIdx - 1],
                  moveUpGroupList[moveUpIdx] ] =
                    [ moveUpGroupList[moveUpIdx],
                      moveUpGroupList[moveUpIdx - 1] ];
            }

            return {
                ...state,
                tableGroups: moveUpGroupList,
            };
        }
        case types.REMOVE_TABLE: {
            let stayingGroupList = state.tableGroups.filter((groupId) => {
                return groupId !== action.payload.tableId;
            });

            let stayingFilterPerTable = { ...state.filterPerTable };
            delete stayingFilterPerTable[action.payload.tableId];

            let stayingLengthPerTable = { ...state.lengthPerTable };
            delete stayingLengthPerTable[action.payload.tableId];

            return {
                ...state,
                tableGroups: stayingGroupList,
                filterPerTable: stayingFilterPerTable,
                lengthPerTable: stayingLengthPerTable,
            };
        }
        case types.UPDATE_FILTER: {
            state.filterPerTable[action.payload.tableId] = action.payload.data;
            break;
        }
        case types.UPDATE_TABLE_LENGTH: {
            let updatedLengthPerTable = { ...state.lengthPerTable };
            updatedLengthPerTable[action.payload.tableId] = action.payload.data;

            return {
                ...state,
                lengthPerTable: updatedLengthPerTable,
            };
        }
        case types.RESET_SETTINGS:
        {
            return {
                ...state,
                ...DEFAULT_TABLES_REDUCER_STATE,
                isResettingSettings: true,
            };
        }
        case types.RESET_SETTINGS_COMPLETE:
        {
            return {
                ...state,
                isResettingSettings: false,
            };
        }
        case LOGOUT:
        {
            return {
                ...state,
                ...DEFAULT_TABLES_REDUCER_STATE,
            };
        }

        default:
            return state;
    }
});

export default tablesReducer;