import { DateTime } from "luxon";

import { LOGOUT } from "tos-web-admin-client";

import * as types from "../constants/ActionTypes";
import { TIME_CURRENT } from "../constants/Constants";

/**
 * This file contains the Redux reducer for storing and distributing the table
 * data.
 */

/**
 * The default TOS status object, TOS is inactive and no airports have
 * approvers set.  approvalTypes will be an object with property name of
 * airport and value of approver.
 */
const DEFAULT_TOS_STATUS = {
    active: false,
    approvalTypes: {},
};

/**
 * The default state for data reducer
 */
const DEFAULT_DATA_REDUCER_STATE = {
    tosStatus: {...DEFAULT_TOS_STATUS},
    departures: [],
    flightRoutes: [],
    flightInfoForRoutes: {},
    timeMode: TIME_CURRENT,
    dataTimestamp: DateTime.utc(),
    readOnly: false,
    userMessage: "",
    runTimes: [],
    intervalUpdateNeeded: false,
};


/**
 * The table data reducer used to update and store the table data.
 *
 * @param {object} state   The current Redux state, with initial value set to
 *                         the default tables array.
 * @param {object} action  The Redux action object containing the action type
 *                         and the data necessary for the specified action.
 */
export default function dataReducer(
    state = DEFAULT_DATA_REDUCER_STATE, action = {}) {

    // Redux changes need to be immutable. So for every change three things
    // need to happen.
    // 1. Shallow copy of containing objects.
    // 2. Apply desired modification to copy.
    // 3. Bundle the changes in with the rest of the unmodified state and
    //    return it. The return should be a copy of the current state, but with
    //    the desired modification.
    switch (action.type)
    {
        case types.UPDATE_TOS_STATUS:
        {
            // The payload data's approvalTypes is an array of
            // objects containing {airport, active, approver}.
            // Convert that to an object with property name of airport and
            // value of approver, for easier lookup
            let newApprovalTypes = {};
            let newActiveStatus = false;
            if (action.payload?.data?.approvalTypes?.length > 0)
            {
                action.payload.data.approvalTypes.forEach(airportApprover => {
                    newApprovalTypes[airportApprover.airport] =
                        airportApprover.approvalType;
                    // If any airport is active, then TOS status is
                    // active for all
                    if (airportApprover.active)
                    {
                        newActiveStatus = true;
                    }
                });
            }
            // catches legacy case with old TOS mode format
            if (action.payload?.data.active !== undefined)
            {
                newActiveStatus = action.payload.data.active;
            }
            const newReadOnly = state.timeMode !== TIME_CURRENT ||
                !newActiveStatus;
            return {
                ...state,
                readOnly: newReadOnly,
                tosStatus: {
                    active: newActiveStatus,
                    approvalTypes: newApprovalTypes,
                }
            };
        }

        case types.UPDATE_DEPARTURES:
        {
            let flightInfo = {...state.flightInfoForRoutes};
            let newDepartures = [];
            // Shallow copy of object array where objects contain primitives
            if (action.payload?.data?.length > 0)
            {
                newDepartures = action.payload.data.map(obj => ({...obj}));

                // Update data we need for flight menu tables. A
                // flightInfoForRoutes record would be initiated by the
                // ADD_FLIGHT_ROUTES or ADD_FLIGHT_MENU_GUFI action
                let depWithFlightMenu = action.payload.data.filter(
                    (rec) => flightInfo[rec.gufi] !== undefined);
                depWithFlightMenu.forEach((rec) =>
                    flightInfo[rec.gufi] = extractFlightInfo(rec));
                }

            return {
                ...state,
                departures: newDepartures,
                flightInfoForRoutes: flightInfo,
                userMessage: "",
            }
        }

        case types.UPDATE_FLIGHT_ROUTES:
        {
            // Shallow copy of object array where objects contain primitives
            let newFlightRoutes = [];
            if (action.payload?.data?.length > 0)
            {
                newFlightRoutes = action.payload.data.map(obj => ({...obj}));
            }

            return {
                ...state,
                flightRoutes: action.payload?.fullUpdate ? newFlightRoutes : [...state.flightRoutes, ...newFlightRoutes],
            };
        }

        case types.ADD_FLIGHT_ROUTES:
        {
            // Shallow copy of object array where objects contain primitives
            let newFlightRoutes =
                action.payload.routeData.map(obj => ({...obj}));
            let oldFlightRoutes = state.flightRoutes.filter(
                (route) => route.gufi !== action.payload.gufi);
            let fullFlightRoutes = [...oldFlightRoutes, ...newFlightRoutes];

            let newFlightInfoForRoutes = {...state.flightInfoForRoutes};
            newFlightInfoForRoutes[action.payload.gufi] =
                extractFlightInfo(action.payload.flightData);

            return {
                ...state,
                flightRoutes: fullFlightRoutes,
                flightInfoForRoutes: newFlightInfoForRoutes,
                userMessage: "",
            };
        }

        case types.ADD_FLIGHT_MENU_GUFI:
        {
            let newFlightInfoForRoutes = {...state.flightInfoForRoutes};
            newFlightInfoForRoutes[action.payload.gufi] = {};

            return {
                ...state,
                flightInfoForRoutes: newFlightInfoForRoutes,
            };
        }

        case types.REMOVE_FLIGHT_MENU_GUFI:
        {
            let newFlightInfoForRoutes = {...state.flightInfoForRoutes};
            delete newFlightInfoForRoutes[action.payload.gufi];

            return {
                ...state,
                flightInfoForRoutes: newFlightInfoForRoutes,
            };
        }

        case types.UPDATE_TIME_MODE:
        {
            const newReadOnly = action.payload.timeMode !== TIME_CURRENT ||
                !state.tosStatus.active;
            return {
                ...state,
                timeMode: action.payload.timeMode,
                readOnly: newReadOnly,
            };
        }

        case types.UPDATE_DATA_TIMESTAMP:
        {
            return {
                ...state,
                dataTimestamp: action.payload.dataTimestamp,
            };
        }

        case types.ADD_MESSAGE:
        {
            return {
                ...state,
                userMessage: action.payload.message,
            };
        }

        case types.UPDATE_RUN_TIMES:
        {
            return {
                ...state,
                runTimes: action.payload.runTimes,
            };
        }

        case types.UPDATE_REFRESH_INTERVAL:
        {
            return {
                ...state,
                intervalUpdateNeeded: action.payload.intervalUpdateNeeded,
            };
        }

        case LOGOUT:
        {
            return {
                ...state,
                ...DEFAULT_DATA_REDUCER_STATE,
            };
        }

        // By default return an unmodified state
        default:
            return state;
    }
}

/**
 * Extracts the information we need for the flight menu table from the departure
 * table data record.
 *
 * @param {object} rec  Departure record with flight level information
 *
 * @return {object} just the info we need to access flight menu information
 */
function extractFlightInfo(rec)
{
    return {
        tosId: rec.tosId,
        acid: rec.acid,
        coordinationStatus: rec.coordinationStatus,
        eligibilityStatus: rec.eligibilityStatus,
        flightStatus: rec.flightStatus,
        origin: rec.origin,
        international: rec.international,
        hasFiled: rec.hasFiled,
    };
}
