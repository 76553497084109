/**
 * This file contains constants related to the various tables.
 */

// Flag to identify flight menu
export const FLIGHT_MENU_ID = "routes";

// Flag to identify departure table
export const DEPARTURE_TABLE_ID = "departure";

// Name for flight filter button and table
export const ALERT_FILTER_BUTTON = "alertFilterButton";
export const ALERT_FILTER_TABLE_ID = "AlertTable";

// Name for candidate filter button and table
export const CANDIDATE_FILTER_BUTTON = "candidateFilterButton";
export const CANDIDATE_FILTER_TABLE_ID = "CandidateAlertTable";

// Name for the show-me "temporary" table
export const SHOW_ME_TABLE_ID = "showMe";
export const SHOW_ME_TABLE_TITLE = "Search and Alert";

// Constant for the Time To Expiration field's unset value.
// This has to match what is assigned in the TOS Web Table Service
// when the Tower is the approver ( currently "N/A")
export const TIME_TO_EXPIRATION_UNSET = "N/A";

// SearchBuilder type for render method
export const SEARCH_TYPE = "filter2";
