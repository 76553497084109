/**
 * This file contains constants used by the navigation bar
 */
export const ALERT_TYPES_SETTINGS = "Alert Types ...";
export const COLOR_ALERTS_SETTINGS = "Color Alerts...";
export const HELP = "Help";
export const HOTLINE = "Hotline";
export const LOAD_SAVE_SETTINGS = "Load / Save ...";
export const LOGOUT_MENUITEM = "Log Out";
export const TITLE = "Predeparture Rerouting Interface";
export const USER_SETTINGS = "Settings";
