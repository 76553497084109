import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { BLACK_CSS, WHITE_CSS } from "../../../constants/ColorAlertConstants";
import "../../../css/color-alerts.css";


/**
 * This functional component provides a user interface to select the color
 * for an alert from a color picker.
 *
 * @param {object}  alertColor           current color of the alert
 * @param {boolean} alertWhiteText       true if the alert text should be
 *                                       displayed in white
 * @param {number}  alertIndex           index of the alert being edited in the
 *                                       list of alerts
 * @param {func}    setSelectedColor     callback function when the user has
 *                                       selected a color
 * @param {func}    cancelColorChange    callback function when the user wants
 *                                       to discard a color change
 */
const ColorAlertPicker = ({ alertColor={r:255,g:255,b:255},
    alertWhiteText=false, alertIndex, setSelectedColor, cancelColorChange }) =>
{
    // Initialize the color picker with the passed-in value
    const [currentColor, setCurrentColor] = useColor("rgb",
        {r:alertColor.r, g:alertColor.g, b:alertColor.b} );
    const [currentWhiteText, setCurrentWhiteText] = React.useState(
        alertWhiteText);

    /**
     * Handles the "White Text" checkbox selection event.  
     */
    const handleWhiteText = () => {
        setCurrentWhiteText(!currentWhiteText);
    };

    /**
     * Handles the color selection event.  The selected color is passed back
     * to the caller.
     *
     * @param {object} unusedEvent   button selection event; unused
     */
    const handleSetColor = (unusedEvent) => {
        setSelectedColor(currentColor.rgb, currentWhiteText, alertIndex);
    };

    /**
     * Handles the user wanting to cancel a color change.
     *
     * @param {object} unusedEvent   button selection event; unused
     */
    const handleCancelColor = (unusedEvent) => {
        cancelColorChange(alertIndex);
    };

    // Translate the color into the value needed as a css property
    const sampleColor = "rgb(" + currentColor.rgb.r +
       ", " + currentColor.rgb.g + "," + currentColor.rgb.b + ")";
    const textColor = (currentWhiteText ? WHITE_CSS : BLACK_CSS);

    return (
        <>
            <h3 className="color-alert--sample"
                style={{color:textColor, 
                        backgroundColor:sampleColor, 
                        width:450 }}
            >
                &nbsp;&nbsp;Sample Text&nbsp;&nbsp;
            </h3>

            <div className="alert-types--checkbox" >
                <input type="checkbox"
                    checked={currentWhiteText}
                    onChange={handleWhiteText}
                    id="whiteText"
                    name="whiteText"
                />
                <label htmlFor="whiteText">White Text</label>
            </div>

            <ColorPicker width={450} height={300} color={currentColor}
                 onChange={setCurrentColor} hideHSV light/>
            <Modal.Footer>
                <Button variant="primary"
                    onClick={handleSetColor}
                >
                    Apply
                </Button>
                <Button variant="secondary"
                    onClick={handleCancelColor}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </>
    );
};

ColorAlertPicker.propTypes = {
    // Original color of the alert being edited; passed in from parent
    alertColor: PropTypes.object,

    // Original state of whether to use white text in the alert being edited; 
    // passed in from parent
    alertWhiteText: PropTypes.boolean,

    // Index of this alert in the list of alerts; passed in from parent
    alertIndex: PropTypes.number,

    // Function to call whent the user has chosen a color; passed in from parent
    setSelectedColor: PropTypes.func,

    // Function to call whent the user cancels the color change operation;
    // passed in from parent
    cancelColorChange: PropTypes.func,
};

export default ColorAlertPicker;
