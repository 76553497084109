import { COLUMNS } from ".//ColumnField";
import { COLUMN_TYPES } from "./ColumnTypes";
import { CoordinationState,
         EligibilityState,
         FlightStatus,
         ImpactLevel } from "./TosEnum";


/**
 * This file contains constants used for color alerts:
 * - the fields that are allowed to be color coded
 * - the condition ("=", ">=", etc.) that is allowed in the value comparison 
 *   for each field
 * - for enum field types, the defined values
 * - for colors, the css for black and white text
 */


/********************
 * FIELDS
 ********************/

// All fields that can be color-coded; this will be set in
// setColorAlertFieldsForLoggedInUser().
let fieldOptions = [];

// Field look-up map to translate the user-selected field to the full
// field object; that is, key=field.key and value=full field object
// This will be set in setColorAlertFieldsForLoggedInUser().
let coloredFieldMap = {};

/**
 * Retrieves the fields that can be color-coded for the currently
 * logged-in user.
 *
 * @return {array{object}} the field object for the fields that can be 
 *         color coded
 */
export function getFieldOptions()
{
    return [ ...fieldOptions ];
}

/**
 * Retrieves the map of field key to field object for the fields that can
 * be colored coded for the currently logged-in user.
 *
 * @return {object} map of fieldKey to field object 
 */
export function getColoredFieldMap()
{
    return { ...coloredFieldMap };
}

/**
 * Sets the list of fields that can be color-coded based on the currently
 * logged-in user.  This cannot be called until after 
 * ColumnField.updateUserColumns() has been called.
 *
 * @return {object} map of fieldKey to field object 
 */
export function setColorAlertFieldsForLoggedInUser()
{
    // All fields that can be color-coded
    const ALL_COLORED_FIELD_OPTIONS = [
        COLUMNS.TOS_DT_COORDINATION_STATE_COLUMN,
        COLUMNS.TOS_DT_ELIGIBILITY_STATE_COLUMN,
        COLUMNS.FLIGHT_STATUS_COLUMN,
        COLUMNS.IMPACT_LEVEL_COLUMN,
        COLUMNS.EOBT_COLUMN,
        COLUMNS.TOS_DT_ESTIMATED_TAKE_OFF_TIME_COLUMN,
        COLUMNS.TOS_DT_IN_DELAY_COLUMN,
        COLUMNS.TOS_DT_OFF_DELAY_COLUMN,
        COLUMNS.TOS_DT_TIME_TO_EXPIRATION_COLUMN,
        COLUMNS.TOS_DT_TOP_ADDITIONAL_NM_COLUMN,
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_AIRPORT_COLUMN,
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_TRACON_COLUMN,
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_FLEET_COLUMN,
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER1_COLUMN,
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER2_COLUMN,
        COLUMNS.TOS_DT_TOP_IMPACT_LEVEL_COLUMN,
        COLUMNS.TOS_DT_TOP_IN_DELAY_COLUMN,
        COLUMNS.TOS_DT_TOP_IN_DELAY_SAVINGS_COLUMN,
        COLUMNS.TOS_DT_TOP_OFF_DELAY_COLUMN,
        COLUMNS.TOS_DT_TOP_OFF_DELAY_SAVINGS_COLUMN,
        COLUMNS.TOS_DT_TOP_PROBABILITY_DELAY_SAVINGS_RTC_COLUMN,
        COLUMNS.TOS_DT_TOP_PROBABILITY_DELAY_SAVINGS_ZERO_COLUMN,
        COLUMNS.TOS_DT_TOP_RELATIVE_TRAJECTORY_COST_COLUMN
    ];

    // Remove fields not applicable for this user
    fieldOptions = ALL_COLORED_FIELD_OPTIONS.filter(field =>
        ((field.disabled === undefined) || !field.disabled));

    // Set up the field look-up map 
    coloredFieldMap = {};
    fieldOptions.forEach((field) => coloredFieldMap[field.key] = field);
}


/********************
 * CONDITIONS
 ********************/

// Allowed conditions
export const CONDITION_EQUALS = "=";
export const CONDITION_NOT_EQUALS = "!=";
export const CONDITION_GREATER_THAN = ">";
export const CONDITION_GREATER_THAN_EQUALS = ">=";
export const CONDITION_LESS_THAN = "<";
export const CONDITION_LESS_THAN_EQUALS = "<=";
export const CONDITION_BETWEEN = "Between";
export const CONDITION_ONE_OF = "One Of";

// Create a map of what kinds of conditions are allowed for which field types
// key=field data type, value=allowed conditions
export const coloredConditionMap = {};
coloredConditionMap[COLUMN_TYPES.COORD_STATE] =
    [CONDITION_EQUALS, CONDITION_NOT_EQUALS, CONDITION_ONE_OF];
coloredConditionMap[COLUMN_TYPES.ELIGIBILITY_STATE] =
    [CONDITION_EQUALS, CONDITION_NOT_EQUALS, CONDITION_ONE_OF];
coloredConditionMap[COLUMN_TYPES.FLIGHT_STATUS] =
    [CONDITION_EQUALS, CONDITION_NOT_EQUALS, CONDITION_ONE_OF];
coloredConditionMap[COLUMN_TYPES.IMPACT_LEVEL] =
    [CONDITION_EQUALS, CONDITION_NOT_EQUALS, CONDITION_ONE_OF];
coloredConditionMap[COLUMN_TYPES.HHMM] =
    [CONDITION_EQUALS, CONDITION_NOT_EQUALS,
     CONDITION_GREATER_THAN, CONDITION_GREATER_THAN_EQUALS,
     CONDITION_LESS_THAN, CONDITION_LESS_THAN_EQUALS,
     CONDITION_BETWEEN];
coloredConditionMap[COLUMN_TYPES.NUM] =
    [CONDITION_EQUALS, CONDITION_NOT_EQUALS,
     CONDITION_GREATER_THAN, CONDITION_GREATER_THAN_EQUALS,
     CONDITION_LESS_THAN, CONDITION_LESS_THAN_EQUALS,
     CONDITION_BETWEEN];
coloredConditionMap[COLUMN_TYPES.TIME_TO_EXPIRATION] =
    [CONDITION_EQUALS, CONDITION_NOT_EQUALS,
     CONDITION_GREATER_THAN, CONDITION_GREATER_THAN_EQUALS,
     CONDITION_LESS_THAN, CONDITION_LESS_THAN_EQUALS,
     CONDITION_BETWEEN];


/********************
 * VALUES
 ********************/

// Map of all enums, for easier lookup
// key = enum name aka enum key, e.g. "ATC_APPROVED"
// value = user-friendly name, e.g. "ATC Approved"
export const enumKeyToTitleMap = {};

// List of coordination state options
export const coordinationOptions = [];
for (const state in CoordinationState)
{                              
    coordinationOptions.push({ label: CoordinationState[state].display,
                               value: CoordinationState[state].name, });
    enumKeyToTitleMap[CoordinationState[state].name] =  
        CoordinationState[state].display;
}

// List of eligibility state options
export const eligibilityOptions = [];
for (const state in EligibilityState)
{           
    eligibilityOptions.push({ label: EligibilityState[state].display,
                              value: EligibilityState[state].name, });
    enumKeyToTitleMap[EligibilityState[state].name] =  
        EligibilityState[state].display;
}

// List of flight state options
export const flightStatusOptions = [];
for (const state in FlightStatus)
{
    flightStatusOptions.push({ label: FlightStatus[state].display,
                               value: FlightStatus[state].name, });
    enumKeyToTitleMap[FlightStatus[state].name] = FlightStatus[state].display;
}

// List of impact level options
export const impactLevelOptions = [];
for (const level in ImpactLevel)
{
    impactLevelOptions.push({ label: ImpactLevel[level].display,
                              value: ImpactLevel[level].name, });
    enumKeyToTitleMap[ImpactLevel[level].name] = ImpactLevel[level].display;
}


/********************
 * COLORS
 ********************/

export const BLACK_CSS = "rgb(0, 0, 0)";
export const WHITE_CSS = "rgb(255, 255, 255)";
