import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import { updateTosStatus } from "../../actions";
import { ATC_APPROVER_ROLES } from "../../constants/Constants";
import userConfig from "../../config/user_config.json";
import { tosClient } from "../../utils/tosClient";
import ConfirmDialog from "../shared/ConfirmDialog";
import "../../css/navbar.css";

/**
 * Provides a component to display and update whether TOS is active, and if
 * the approver is at the tower or center level. Only the possible approvers
 * are able to update the status.
 */
class TosModeDisplay
    extends Component
{
    static propTypes = {
        // Indicates if a user is logged in and the field should display;
        // from redux
        loggedIn: PropTypes.bool.isRequired,

        // Logged in user information; from redux
        user: PropTypes.object,

        // Current TOS Mode information; from redux
        tosStatusActive: PropTypes.bool,

        // Object containing approver type for each airport; from redux
        tosApprovers: PropTypes.object,

        // Action creator to update the TOS status; from redux
        updateTosStatus: PropTypes.func,
    }

    /**
     * Constructs the TosModeDisplay class.
     *
     * @param {*} props    none passed from parent
     */
    constructor(props)
    {
        super(props);
        this.approverClickHandler = this.approverClickHandler.bind(this);
        this.modeClickHandler = this.modeClickHandler.bind(this);
        this.sendTosApprover = this.sendTosApprover.bind(this);
        this.sendTosMode = this.sendTosMode.bind(this);

        this.state = {
            confirmingApproverUpdate: false,
            confirmingModeUpdate: false,
        }
    }

    /**
     * Handles the user click on an approver button to toggle the TOS
     * approver for an airport.
     *
     * @param {object} changeEvent  React event, used to blur the button
     */
    approverClickHandler(changeEvent)
    {
        const airport =
            changeEvent.target.attributes.getNamedItem('airport').nodeValue;
        const approver =
            changeEvent.target.attributes.getNamedItem('approver').nodeValue;

        const tosApprovers = { ...this.props.tosApprovers };
        if (tosApprovers[airport] !== approver)
        {
            this.setState({ confirmingApproverUpdate: true });
            this.approvalChangeAirport = airport;
            this.approvalChangeApprover = approver;
        }

        changeEvent.currentTarget.blur();
    }

    /**
     * Handles the user click on the mode button to toggle the TOS mode.
     *
     * @param {object} changeEvent  React event, used to blur the button
     */
    modeClickHandler(changeEvent)
    {
        this.setState({ confirmingModeUpdate: true });
        this.newMode = !this.props.tosStatusActive;

        changeEvent.currentTarget.blur();
    }

    /**
     * Callback function used by the ConfirmationDialog to set whether the user
     * wants to confirm the change to the approver.  If confirmed,
     * the update will be sent to the server.
     *
     * @param {bool} confirmed   true if the user wants to change the approver
     */
    approverConfirmationCallback = (confirmed) =>
    {
        this.setState({ confirmingApproverUpdate: false });

        if (confirmed)
        {
            this.sendTosApprover();
        }

        this.approvalChangeAirport = "";
        this.approvalChangeApprover = "";
    }

    /**
     * Callback function used by the ConfirmationDialog to set whether the user
     * wants to confirm the change to the active/inactive mode.  If confirmed,
     * the update will be sent to the server.
     *
     * @param {bool} confirmed   true if the user wants to change the mode
     */
    modeConfirmationCallback = (confirmed) =>
    {
        this.setState({ confirmingModeUpdate: false });
        if (confirmed)
        {
            this.sendTosMode();
        }
    }

    /**
     * Sends the TOS Mode with the updated TOS active/inactive status for all
     * configured airports.
     */
    sendTosMode()
    {
        console.log("Updating TOS active/inactive Mode to " + this.newMode);

        const tosApprovers = this.props.tosApprovers;
        const newStatus = { approvalTypes: [] };

        for (const loc in tosApprovers)
        {
            newStatus.approvalTypes.push({
                airport: loc,
                approvalType: tosApprovers[loc],
                active: this.newMode,
            });
        }

        tosClient.postTosStatus(this.props.user.role, this.props.user.roleUser,
            newStatus);
        this.props.updateTosStatus(newStatus);
    }

    /**
     * Sends the updated TOS approver for the airport selected on the display.
     */
    sendTosApprover()
    {
        const airport = this.approvalChangeAirport;
        const approver = this.approvalChangeApprover;
        console.log("Updating approver for " + airport + " to " + approver);

        // Note tosApprovers is an object of property=airport and value=approver
        // while newStatus's approvalTypes is an array of objects of
        // {airport, approver, active}
        const tosApprovers = { ...this.props.tosApprovers };
        const newStatus = { approvalTypes: [] };

        if (tosApprovers[airport] === "TOWER")
        {
            tosApprovers[airport] = "CENTER";
        }
        else
        {
            tosApprovers[airport] = "TOWER";
        }

        // Note only the one airport is sent
        newStatus.approvalTypes.push({
            airport: airport,
            approvalType: tosApprovers[airport],
            active: this.props.tosStatusActive,
        });
        tosClient.postTosStatus(this.props.user.role, this.props.user.roleUser,
            newStatus);

        // Need full new status for redux
        for (const loc in tosApprovers)
        {
            if (loc !== airport)
            {
                newStatus.approvalTypes.push({
                    airport: loc,
                    approvalType: tosApprovers[loc],
                    active: this.props.tosStatusActive,
                });
            }
        }

        this.props.updateTosStatus(newStatus);
    }

    /**
     * Renders the TOS button to display active or not.
     */
    renderStatus()
    {
        let activeClass = this.props.tosStatusActive ? "tos-mode-active" :
            "tos-mode-inactive";
        let isApprover = ATC_APPROVER_ROLES.includes(this.props.user.role) &&
            !this.props.user.readOnly;
        return (
            <Button id="tosMode" className={"tos-mode-flag " + activeClass}
                disabled={!isApprover} onClick={this.modeClickHandler}
            >
                TOS
            </Button>
        );
    }

    /**
     * Renders the approver buttons to indicate center or tower as approver
     * for each airport of interest.
     */
    renderApprovers()
    {
        const isApprover = ATC_APPROVER_ROLES.includes(this.props.user.role) && 
            !this.props.user.readOnly;
        const config = userConfig[this.props.user.roleUser];
        if (config && config.tosAirports && (config.tosAirports.length > 0))
        {
            return (
                <>
                    {   // Render each airport button.
                        config.tosAirports.map((loc) => {
                            const approver = this.props.tosApprovers[loc];
                            const centerApproval = approver &&
                                (approver === "CENTER") &&
                                this.props.tosStatusActive;
                            const centerClass = "tos-approver " +
                                (centerApproval ? "tos-approver-true" : "");
                            const towerApproval = approver &&
                                (approver === "TOWER") &&
                                this.props.tosStatusActive;
                            const towerClass = "tos-approver " +
                                (towerApproval ? "tos-approver-true" : "");

                            return (
                                <ButtonGroup vertical key={loc} size="sm"
                                    className="tos-approver"
                                >
                                    <Button key={"center" + loc}
                                        disabled={!isApprover || !this.props.tosStatusActive}
                                        onClick={this.approverClickHandler}
                                        airport={loc}
                                        approver={"CENTER"}
                                        className={centerClass}
                                    >
                                        {config.center}
                                    </Button>
                                    <Button key={"tower" + loc}
                                        disabled={!isApprover || !this.props.tosStatusActive}
                                        onClick={this.approverClickHandler}
                                        airport={loc}
                                        approver={"TOWER"}
                                        className={towerClass}
                                    >
                                        {loc}
                                    </Button>
                                </ButtonGroup>
                            );
                        })
                    }
                </>
            );
        }
        else
        {
            return null;
        }
    }

    /**
     * Displays the mode status and the currently assigned approver.
     */
    render()
    {
        if (this.props.loggedIn)
        {
            return (
                <>
                    { this.renderStatus() }
                    { this.renderApprovers() }
                    <ConfirmDialog isShown={this.state.confirmingModeUpdate}
                        onSelection={this.modeConfirmationCallback}
                        title="Confirm TOS Status Change"
                    >
                        <h5>
                            Are you sure you want to make TOS mode&nbsp;
                            {this.newMode ? "ACTIVE" : "INACTIVE"}?
                        </h5>
                    </ConfirmDialog>
                    <ConfirmDialog isShown={this.state.confirmingApproverUpdate}
                        onSelection={this.approverConfirmationCallback}
                        title="Confirm TOS Approving Facility Change"
                    >
                        <h5>
                            Are you sure you want to change the <br/>approving
                            facility for {this.approvalChangeAirport}?
                        </h5>
                    </ConfirmDialog>
                </>
            )
        }
        else
        {
            return null;
        }
    }
}

/**
 * Maps the Redux state to local props for access.
 *
 * @param {*} state full Redux state
 */
function mapStateToProps(state)
{
    const { loggedIn, user } = state.authentication;

    return {
        loggedIn,
        user,
        tosStatusActive: state.dataReducer.tosStatus.active,
        tosApprovers: state.dataReducer.tosStatus.approvalTypes,
    };
}

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    updateTosStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(TosModeDisplay);
