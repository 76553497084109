import React from "react";
import PropTypes from "prop-types";

import { getColoredFieldMap,
         getFieldOptions } from "../../../constants/ColorAlertConstants";


/**
 * This functional component provides a user interface to select a field
 * to be color-coded.
 *
 * @param {string} selectedField      the "key" property of a field (e.g. 
 *                                    "inDelay") to select by default; may be 
 *                                    undefined
 * @param {string} setSelectedField   callback function to notify when a field
 *                                    has been selected
 * @param {number} index              index of the alert being edited in the
 *                                    list of alerts
 */
const ColorAlertField = ({ selectedField, setSelectedField, index }) =>
{
    const optionToSelect = selectedField ?? "Field"

    /**
     * Handles the field selection event.  The selected field is passed back
     * to the caller.
     *
     * @param {object} event   selection event; event.target.value contains
     *                         the field's "key" property
     */
    const handleField = (event) => {

        // Return the full field, not just the field key
        const coloredFieldMap = getColoredFieldMap();
        setSelectedField(coloredFieldMap[event.target.value], index);
    };

    const fieldOptions = getFieldOptions();

    // Return the list of fields in a drop-down list
    return (
        <div>
            <select name="selectField" id="selectField"
                defaultValue={optionToSelect}
                onChange={handleField}
            >
                <option value="Field" key="Field" disabled hidden>
                    Field
                </option>
                {fieldOptions.map(field => (
                    <option value={field.key} key={field.key}>
                        {field.title}
                    </option>
                ))}
            </select>
        </div>
    );
};

ColorAlertField.propTypes = {
    // The currently selected field; passed from parent
    selectedField: PropTypes.string,

    // Callback when a field is selected; passed in from parent
    setSelectedField: PropTypes.func.isRequired,

    // Index of this alert in the list of alerts; passed in from parent
    index: PropTypes.number.isRequired,
};

export default ColorAlertField;
