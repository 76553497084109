import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

import { TIME_CURRENT,
         TIME_FIXED } from "../../constants/Constants";
import { updateDataTimestamp,
         updateRunTimes,
         updateTimeMode,
         updateRefreshInterval } from "../../actions";
import { tosClient } from "../../utils/tosClient";
import "../../css/dataTime.css";
import userConfig from "../../config/user_config.json";

const NEWER = "newer";
const NEWER_STRING = "More Recent";

/**
 * Provides a component to display and select the time associated with the
 * data displayed in the tables. This can be current time which auto updates, or
 * a selected date and time in the past, along with options to display the
 * previous or next model update time.
 */
class DataTimeDisplay
    extends Component
{
    static propTypes = {
        // Flag for whether the update was sucessful; passed in by parent
        wasUpdateSuccessful: PropTypes.bool.isRequired,

        // The update error text, may be blank; passed in by parent
        errorText: PropTypes.string,

        // Indicates if a user is logged in and the field should display;
        // from redux
        loggedIn: PropTypes.bool.isRequired,

        // Logged in user's role; from redux
        userRoleUser: PropTypes.string,

        // Currently selected time mode; from redux
        timeMode: PropTypes.string.isRequired,

        // Current data timestamp, Luxon DateTime; from redux
        dataTimestamp: PropTypes.object.isRequired,

        // Recent TOS run times for history selection; from redux
        runTimes: PropTypes.arrayOf(PropTypes.string),

        // Action creator to update the time options for historical display;
        // from redux
        updateRunTimes: PropTypes.func,

        // Action creator to update the display time; from redux
        updateDataTimestamp: PropTypes.func,

        // Action creator to change the time mode; from redux
        updateTimeMode: PropTypes.func,

        // Action creator to change refresh interval in table; from redux
        updateRefreshInterval: PropTypes.func,
    }

    /**
     * Constructs the DataTimeDisplay class.
     *
     * @param {*}      props
     * @param {bool}   props.wasUpdateSuccessful  true if the last update
     *                                            was successful
     * @param {string} props.errorText            if the last update was not
     *                                            successful, the error message
     *                                            to display
     */
    constructor(props)
    {
        super(props);
        this.onDateChange = this.onDateChange.bind(this);
    }

    /**
     * Updates the dataTime and timeMode when a new time is selected.
     *
     * @param {object} dateEvent  React event with selected time in target.value
     */
    onDateChange(dateEvent)
    {
        // If we're managing to pick a time in Current mode, change the mode
        if (this.props.timeMode === TIME_CURRENT)
        {
            this.props.updateTimeMode(TIME_FIXED);
        }

        if (dateEvent.target.value === NEWER)
        {
            tosClient.getTosRuns()
            .then((times) => {
                this.props.updateRunTimes(times.runTimes);
                return true;
            })
            .catch(() => {
                this.props.updateRunTimes([]);
                return false;
            });
        }
        else // get the time stamp user selected from list
        {
            this.props.updateDataTimestamp(
                DateTime.fromISO(dateEvent.target.value, { zone: "utc" }));
                
            // alert table that historic data needs refreshed
            this.props.updateRefreshInterval(true);
        }
    }

    /**
     * Handles the user click on the radio buttons to update the time mode.
     *
     * @param {object} changeEvent  React event with selected mode
     */
    modeHandler(changeEvent)
    {
        const target = changeEvent.target;
        const value =  target.value;
        if (value === TIME_FIXED)
        {
            tosClient.getTosRuns()
            .then((times) => {
                this.props.updateRunTimes(times.runTimes);
                return true;
            })
            .catch(() => {
                this.props.updateRunTimes([]);
                return false;
            });
        }
        this.props.updateTimeMode(value);
        
        // alert table that refresh interval needs updated to match time mode
        this.props.updateRefreshInterval(true);
    }

    /**
     * Creates the time display. This will be either a read only input field or
     * a selection with all the recent run times and an option to refresh the
     * list, based on current time mode.
     */
    createSelection()
    {
        if (this.props.timeMode === TIME_CURRENT)
        {
            return (
                <input value={this.formatTime(this.props.dataTimestamp)}
                    readOnly className="time-display" />
            );
        }
        else
        {
            // Default selection will be the most recent time in list, or
            // previously selected time.
            var fullTime = this.props.dataTimestamp.toISO(
                { suppressMilliseconds: true });
            var curValue = this.props.runTimes[0];
            if (this.props.runTimes.includes(fullTime))
            {
                curValue = fullTime;
            }

            return (
                <select onChange={this.onDateChange}
                    className="time-display" value={curValue}
                >
                    <option key={NEWER} value={NEWER}>
                        {NEWER_STRING}
                    </option>
                    {this.props.runTimes.map((choice) => {
                        return (
                            <option key={choice} value={choice}>
                                {this.formatTime(choice)}
                            </option>
                        )})
                    }
                </select>
            )
        }
    }

    /**
     * Formats time value into HH:mm:ss string.
     *
     * @param { string | DateTime } time to format, either ISO string or
     *                              Luxon DateTime object.
     *
     * @return {string} HH:mm:ss format of time
     */
    formatTime(timeVal)
    {
        var updateTime = "";

        if (typeof timeVal === "string")
        {
            updateTime = timeVal.substr(11, 8);
        }
        else if (timeVal instanceof DateTime)
        {
            updateTime = timeVal.toFormat("HH:mm:ss");
        }

        return updateTime;
    }

    /**
     * Displays the time when a user is logged in. If the user is allowed to
     * display historical data, time control options are also displayed.
     */
    render()
    {
        if (this.props.loggedIn)
        {
            // Look for an error first
            if ((this.props.wasUpdateSuccessful !== undefined) &&
                !this.props.wasUpdateSuccessful)
            {
                // If this.props.errorText is empty or undefined then we will
                // give a generic 'Connection Error' error message
                var errorText = ("" === this.props.errorText ||
                    undefined === this.props.errorText) ?
                    "Connection Error" : this.props.errorText;

                return (
                    <div className="error-message">
                        {errorText}
                    </div>
                );
            }
            // User can display historical data
            else if (userConfig[this.props.userRoleUser] &&
                userConfig[this.props.userRoleUser].allowHistory)
            {
                return (
                    <div className="data-time-display">
                      <span className="time-mode-option">
                        <input type="radio"
                               id="currentTime"
                               value={TIME_CURRENT}
                               checked={this.props.timeMode === TIME_CURRENT}
                               onChange={(e) => this.modeHandler(e)}
                               title="Select to use current time" />
                        <label htmlFor="currentTime"
                               title="Select to use current time">current</label>
                      </span>
                      <span className="time-mode-option">
                        <input type="radio"
                               id="fixedTime"
                               value={TIME_FIXED}
                               checked={this.props.timeMode !== TIME_CURRENT}
                               onChange={(e) => this.modeHandler(e)}
                               title="Select to use a fixed time" />
                        <label htmlFor="fixedTime"
                               title="Select to use a fixed time">fixed</label>
                      </span>
                      {this.createSelection()}
                    </div>
                );
            }
            // Normal update time label
            else
            {
                return (
                    <div className="updated-display">
                        Updated: {this.formatTime(this.props.dataTimestamp)}
                    </div>
                );
            }
        }
        else
        {
            return null;
        }
    }
}

/**
 * Maps the Redux state to local props for access.
 *
 * @param {*} state full Redux state
 */
function mapStateToProps(state)
{
    let userRoleUser = "";
    const { loggedIn, user } = state.authentication;
    if (loggedIn)
    {
        userRoleUser = user.roleUser;
    }

    return {
        loggedIn,
        userRoleUser,
        dataTimestamp: state.dataReducer.dataTimestamp,
        timeMode: state.dataReducer.timeMode,
        runTimes: state.dataReducer.runTimes,
    };
}

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    updateDataTimestamp,
    updateRunTimes,
    updateTimeMode,
    updateRefreshInterval,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataTimeDisplay);
