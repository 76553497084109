import React, { Component } from "react"
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom"
import { connect } from "react-redux";

import { updateColumns, updateOrdering } from "../../actions";
import { ALL_DATA_TABLE_COLUMNS,
         ALL_FLIGHT_MENU_COLUMNS,
         FIXED_ROUTE_COLUMNS } from "../../column_utils/columnDefaults"
import { FLIGHT_MENU_ID } from "../../constants/TableConstants";
import PageHeader from "../../PageHeader";
import ApplyCancel from "../shared/ApplyCancel"

import "../../css/react-dual-listbox.css";
import DualListBox from "react-dual-listbox";

// Used to specify the contents of the various buttons in the dual list box.
const icons = {
        moveLeft: "<",
        moveAllLeft: [
            "<",
            "<",
        ],
        moveRight: ">",
        moveAllRight: [
            ">",
            ">",
        ],
        moveTop: "Move to Top",
        moveUp: "Move Up",
        moveDown: "Move Down",
        moveBottom: "Move to Bottom",
    };

// Used to specify custom headers in the dual list box.
const lang = {
    availableHeader: "Available Columns",
    selectedHeader: "Columns Displayed in the Table",
};

/**
 * This class is used to facilitate the selection of columns shown in the TOS
 * table. It makes use of a dual list box.
 */
class ColumnSelection extends Component
{
    static propTypes = {
        // location state from react-router, contains tableId
        location: PropTypes.object.isRequired,

        // The names of columns currently selected for the table of interest;
        // from redux
        selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,

        // The table's sort order; from redux
        currentSort: PropTypes.array,

        // Action creator to update the table's columns; from redux
        updateColumns: PropTypes.func.isRequired,

        // Action creator to update the table's sort order; from redux
        updateOrdering: PropTypes.func.isRequired,

        // Navigation; provided by the system
        history: PropTypes.object.isRequired,
    };

    /**
     * Constructs the ColumnSelection class, which enables the selection on
     * columns to be shown for a table.
     *
     * @param {*} props   none passed from parent
     */
    constructor(props)
    {
        super(props);
        let tableId = this.props.location.tableId;

        this.state = {
             // The currently selected columns. This is passed to the
             // DualListBox which then automatically filters the selected
             // options from the list of all provided options
             // (ALL_COLUMN_OPTIONS). This feature can be toggled.
            selected: this.props.selectedColumns,
        };
        let fullOptionList = ALL_DATA_TABLE_COLUMNS;
        // colAdjustment to deal with flight menu having hidden first columns
        this.colAdjustment = 0;
        if (tableId === FLIGHT_MENU_ID)
        {
            fullOptionList = ALL_FLIGHT_MENU_COLUMNS;
            this.colAdjustment = FIXED_ROUTE_COLUMNS.length;
        }

        // copy over all options that are not disabled
        this.allOptions = [];
        fullOptionList.forEach((section) => {
            if (section.options)
            {
                this.allOptions.push({
                    ...section,
                    options: section.options.filter((option) => {
                        return (option.disabled === undefined) || !option.disabled;
                    }),
                });
            }
        });

        // grab the current sorting before we change columns
        this.currentSortNumbers = this.props.currentSort;
        if (this.currentSortNumbers)
        {
            this.currentSort = [];
            this.currentSortNumbers.forEach((sortEntry) => {
                let newEntry =
                    [ this.props.selectedColumns[sortEntry[0] - this.colAdjustment],
                    sortEntry[1] ];
                this.currentSort.push(newEntry);
            });
        }
    }

    /**
     * Whenever we load this page, make sure to scroll to the top so that we
     * can see things properly.
     */
    componentDidMount()
    {
        window.scrollTo(0,0)
    }

    /**
     * Passes to the dual list box. Used as callback function when a column
     * change has been made.
     *
     * @param {string[]} selected  A string array of the column titles selected.
     */
    onSelectionChange = (selected) =>
    {
        this.setState({ selected });
    };

    /**
     * On Apply click, store the modified columns array and return to the table.
     *
     * @param {React.changeEvent} unusedEvent  The event object associated with
     *                                         the Apply button click
     */
    processApplyClick = (unusedEvent) =>
    {
        if (this.currentSort)
        {
            let newSort = [];
            this.currentSort.forEach((sortElement) => {
                let newIndex = this.state.selected.indexOf(sortElement[0]);
                if (newIndex > -1)
                {
                    newSort.push(
                       [ newIndex + this.colAdjustment, sortElement[1] ]);
                }
            });
            this.props.updateOrdering(this.props.location.tableId, newSort);
        }
        this.props.updateColumns(this.props.location.tableId,
            this.state.selected);

        // Return to the main page
        this.props.history.push({
            pathname: "/",
        });
    }

    /**
     * On Cancel click, no changes are made, and return to the table.
     *
     * @param {React.changeEvent} unusedEvent  The event object associated with
     *                                         the Cancel button click
     */
    processCancelClick = (unusedEvent) =>
    {
        if (this.currentSortNumbers)
        {
            this.props.updateOrdering(this.props.location.tableId,
                this.currentSortNumbers);
        }
        this.props.history.goBack();
    }

    /**
     * Sets up the column selection ui for rendering. This includes the list
     * boxes and associated controls.
     *
     * @return {JSX.element} The column selection page
     */
    render()
    {
        return (
            <>
                <PageHeader titleOnly={true} />

                {/* Note: DualListBox uses the selected and filters them */}
                {/* from the options. This can be toggled. */}
                <div className="col-selection">
                    <DualListBox
                        options={this.allOptions}
                        selected={this.state.selected}
                        onChange={this.onSelectionChange}
                        icons={icons}
                        preserveSelectOrder
                        showOrderButtons
                        showHeaderLabels
                        lang={lang}
                    />

                    <ApplyCancel
                        onApplyClick={this.processApplyClick}
                        onCancelClick={this.processCancelClick}
                    />
                </div>
            </>
        );
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state     The current redux state
 * @param {object} ownProps  Props passed in to the page
 *
 * @return {object} The desired redux state properties mapped to props
 */
const mapStateToProps = (state, ownProps) =>
{
    return {
        selectedColumns:
            state.columnsReducer.columnsPerTable[ownProps.location.tableId],
        currentSort:
            state.columnsReducer.orderPerTable[ownProps.location.tableId],
    }
}

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    updateColumns,
    updateOrdering,
}

// need withRouter on the outside of 'connect' to get location property set
// before mapStateToProps
export default withRouter(connect(mapStateToProps, mapDispatchToProps) (ColumnSelection));
