import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import $ from "jquery";

import { moveTableDown,
         moveTableUp,
         removeTable } from "../../actions";
import Table from "./Table";


/**
 * This class is used to create and manage a single table for departure
 * flights and includes the ability to move the table up and down in the
 * table list on the main display.
 */
class FlightTable extends PureComponent
{
    static propTypes = {
        // The table id for this table; passed in from parent
        tableId: PropTypes.string.isRequired,

        // Action creator to move the table down in the set of tables; 
        // from redux
        moveTableDown: PropTypes.func.isRequired,

        // Action creator to move the table up in the set of tables; 
        // from redux
        moveTableUp: PropTypes.func.isRequired,

        // Action creator to remove the table; from redux
        removeTable: PropTypes.func.isRequired,

        // Navigation; provided by the system
        history: PropTypes.object.isRequired,
    };

    /**
     * Constructs the Table class, which is used to generate a data table with
     * the given options in props.
     *
     * @param {*}      props
     * @param {string} props.tableId   the identifier for this table
     */
    constructor(props)
    {
        super(props);

        // Set the DOM layout, [B]utton, [f]ind, p[r]ocessing, [t]able,
        // [l]ength, and [p]agination.
        this.tableDom = '<"' + this.props.tableId + '"Bf<"tabName">rtlp>';
        this.createButtonsLeft = this.createButtonsLeft.bind(this);
        this.callRemoveTable = this.callRemoveTable.bind(this);
    }

    /**
     * Creates the buttons associated with this table aligned on the top left.
     */
    createButtonsLeft()
    {
        let tableId = this.props.tableId;
        let history = this.props.history;
        let moveTableDown = this.props.moveTableDown;
        let moveTableUp = this.props.moveTableUp;

        return {
            buttons: [
                {
                    text: "<i class='fa fa-arrow-up' > </i>",
                    action: function(unusedEvent, unusedDt, node, 
                                unusedConfig) {
                        moveTableUp(tableId);
                        node.blur();
                    },
                    titleAttr: "Move table up",
                },
                {
                    text: "<i class='fa fa-arrow-down' > </i>",
                    action: function(unusedEvent, unusedDt, node, 
                                unusedConfig) {
                        moveTableDown(tableId);
                        node.blur();
                    },
                    titleAttr: "Move table down",
                },
                {
                    text: "Columns",
                    action: function(unusedEvent, unusedDt, unusedNode, 
                                unusedConfig) {
                        // Go to column selection page
                        history.push({ pathname: "/columnSelection",
                                       tableId: tableId });
                    },
                    titleAttr: "Select and adjust order of columns",
                },
                {
                    extend: "searchBuilder",
                    titleAttr: "Filter flights",
                    // mostly copied from searchbuilder, but tweaked to
                    // redraw the window after loading stored filter
                    action: function (e, dt, node, config) {  
                            this.popover(config._searchBuilder.getNode(), {
                                align: 'container',
                                span: 'container'
                            });
                            
                            var topGroup = config._searchBuilder.s.topGroup;
                            // Need to redraw the contents to calculate the correct positions for the elements
                            if (config._searchBuilder.s.topGroup !== undefined) {
                                config._searchBuilder.s.topGroup.dom.container.trigger('dtsb-redrawContents');
                            }
                            if (topGroup.s.criteria.length === 0) {
                                $('.' + $.fn.dataTable.Group.classes.add.replace(/ /g, '.')).click();
                            }
                        }
                },
            ],
        };
    }

    callRemoveTable(tableId)
    {
        this.props.removeTable(tableId);
    }

    /**
     * Set up the table wrapper for rendering.
     *
     * @return {JSX.element} The container/wrapper for the formatted table
     */
    render()
    {
        // Note: ref in DatatablesComponent initializes dataTableRef with access
        // to this DatatablesComponent instance.
        return (
            <Table tableId={this.props.tableId} 
                confirmDelete={true}
                leftButtons={this.createButtonsLeft} 
                tableDom={this.tableDom}
                callRemoveTable={this.callRemoveTable} 
            />
        );
    }
}

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    moveTableDown,
    moveTableUp,
    removeTable,
};

export default connect(null, mapDispatchToProps) (withRouter(FlightTable));
