/**
 * This file holds various enumerated types for the tos tables.
 */
export const CoordinationState = {
    OPERATOR_EXCLUDED: {
        name: "OPERATOR_EXCLUDED",
        display: "FO Excluded",
        sortOrder: 0,
    },
    ATC_EXCLUDED: {
        name: "ATC_EXCLUDED",
        display: "ATC Excluded",
        sortOrder: 2,
    },
    ATC_UNABLE: {
        name: "ATC_UNABLE",
        display: "ATC Unable",
        sortOrder: 4,
    },
    OPERATOR_UNABLE: {
        name: "OPERATOR_UNABLE",
        display: "Operator Unable",
        sortOrder: 6,
    },
    DEFAULT: {
        name: "DEFAULT",
        display: "Default",
        sortOrder: 8,
    },
    SAVED: {
        name: "SAVED",
        display: "Saved",
        sortOrder: 10,
    },
    REROUTE_REQUESTED: {
        name: "REROUTE_REQUESTED",
        display: "Reroute Requested",
        sortOrder: 12,
    },
    REROUTE_PROPOSED: {
        name: "REROUTE_PROPOSED",
        display: "Reroute Proposed",
        sortOrder: 14,
    },
    ATC_APPROVED: {
        name: "ATC_APPROVED",
        display: "ATC Approved",
        sortOrder: 16,
    },
    OPERATOR_APPROVED: {
        name: "OPERATOR_APPROVED",
        display: "Operator Approved",
        sortOrder: 18,
    },
    REROUTE_FILED: {
        name: "REROUTE_FILED",
        display: "Reroute Filed",
        sortOrder: 20,
    },
    PENDING_ACTION: {
        name: "PENDING_ACTION",
        display: "Pending",
        sortOrder: 22,
    },
    PENDING_EXCLUDE: {
        name: "PENDING_EXCLUDE",
        display: "Pending Exclude",
        sortOrder: 24,
    },
};

export const EligibilityState = {
    POTENTIAL: {
        name: "POTENTIAL",
        display: "Potential",
        sortOrder: 3,
    },
    CANDIDATE: {
        name: "CANDIDATE",
        display: "Candidate",
        sortOrder: 4,
    },
    EXCLUDED: {
        name: "EXCLUDED",
        display: "Excluded",
        sortOrder: 1,
    },
    EXPIRED: {
        name: "EXPIRED",
        display: "Expired",
        sortOrder: 5,
    },
    PENDING_EXCLUDE: {
        name: "PENDING_EXCLUDE",
        display: "Pending Exclude",
        sortOrder: 9,
    },
    NOT_APPLICABLE: {
        name: "NOT_APPLICABLE",
        display: "Not Applicable",
        sortOrder: 10,
    },
};

export const FlightStatus = {
    SCHEDULED: {
        name: "SCHEDULED",
        display: "Scheduled",
        sortOrder: 1,
    },
    PUSHBACK: {
        name: "PUSHBACK",
        display: "Pushback",
        sortOrder: 2,
    },
    TAXI_OUT: {
        name: "TAXI_OUT",
        display: "Taxiing AMA",
        sortOrder: 3,
    },
};

export const TosSwimStatus = {
    NOT_INCLUDED: {
        name: "NOT_INCLUDED",
        display: "Not Included",
        sortOrder: 1,
    },
    SAVED: {
        name: "SAVED",
        display: "Saved",
        sortOrder: 2,
    },
    PENDING_SAVE: {
        name: "PENDING_SAVE",
        display: "Pending Save",
        sortOrder: 3,
    },
    PENDING_CANCEL: {
        name: "PENDING_CANCEL",
        display: "Pending Cancel",
        sortOrder: 4,
    },
    IN_SWIM: {
        name: "IN_SWIM",
        display: "In SWIM",
        sortOrder: 5,
    },
    FORMERLY_INCLUDED: {
        name: "FORMERLY_INCLUDED",
        display: "Formerly Included",
        sortOrder: 6,
    }
};

export const FoAction = {
    SAVE_SWIM: {
        name: "SAVE_SWIM_TOS_ROUTE",
    },
    CANCEL_SWIM: {
        name: "CANCEL_SWIM_TOS_ROUTE",
    },
    REQUEST_TOS_ROUTE: {
        name: "REQUEST_TOS_ROUTE",
    },
    CANCEL_REQUEST_TOS_ROUTE: {
        name: "CANCEL_REQUEST_TOS_ROUTE",
    },
    APPROVE_TOS_ROUTE: {
        name: "APPROVE_TOS_ROUTE",
    },
    UNAPPROVE_TOS_ROUTE: {
        name: "UNAPPROVE_TOS_ROUTE",
    },
    UNABLE_TOS_ROUTE: {
        name: "UNABLE_TOS_ROUTE",
    },
    UNUNABLE_TOS_ROUTE: {
        name: "UNUNABLE_TOS_ROUTE",
    }
};

export const AtcAction = {
    APPROVE_TOS_ROUTE: {
        name: "APPROVE_TOS_ROUTE",
    },
    UNAPPROVE_TOS_ROUTE: {
        name: "UNAPPROVE_TOS_ROUTE",
    },
    UNABLE_TOS_ROUTE: {
        name: "UNABLE_TOS_ROUTE",
    },
    UNUNABLE_TOS_ROUTE: {
        name: "UNUNABLE_TOS_ROUTE",
    },
    PROPOSE_TOS_ROUTE: {
        name: "PROPOSE_TOS_ROUTE",
    },
    CANCEL_PROPOSE_TOS_ROUTE: {
        name: "CANCEL_PROPOSE_TOS_ROUTE",
    },
}

export const ImpactType = {
    NONE: {
      name: "NONE",
      display: "NONE",
      sortOrder: 2,
    },
    ASH: {
      name: "ASH",
      display: "ASH",
      sortOrder: 4,
    },
    CONV: {
      name: "CONV",
      display: "CONV",
      sortOrder: 6,
    },
    ICE: {
      name: "ICE",
      display: "ICE",
      sortOrder: 8,
    },
    IFR: {
      name: "IFR",
      display: "IFR",
      sortOrder: 10,
    },
    MTN_OBSCN: {
      name: "MTN_OBSCN",
      display: "MTN OBSCN",
      sortOrder: 12,
    },
    PCPN: {
      name: "PCPN",
      display: "PCPN",
      sortOrder: 14,
    },
    TS: {
      name: "TS",
      display: "TS",
      sortOrder: 16,
    },
    TURB: {
      name: "TURB",
      display: "TURB",
      sortOrder: 18,
    },
    UNK: {
      name: "UNK",
      display: "UNK",
      sortOrder: 20,
    }
}

export const ImpactLevel = {
    NONE: {
        name: "NONE",
        display: "NONE",
        sortOrder: 1,
    },
    LT_MOD: {
        name: "LT_MOD",
        display: "LT-MOD",
        sortOrder: 2,
    },
    MOD: {
        name: "MOD",
        display: "MOD",
        sortOrder: 3,
    },
    MOD_SEV: {
        name: "MOD_SEV",
        display: "MOD-SEV",
        sortOrder: 4,
    },
    SEVERE: {
        name: "SEVERE",
        display: "SEVERE",
        sortOrder: 5,
    }
}
export const TosRouteSource = {
    CDR: {
        name: "CDR",
        display: "CDR",
        sortOrder: 4,
    },
    ADSB: {
        name: "ADSB",
        display: "ADSB",
        sortOrder: 3,
    },
    ESCAPE: {
        name: "ESCAPE",
        display: "ESCAPE",
        sortOrder: 2,
    },
    SWIM: {
        name: "SWIM",
        display: "SWIM",
        sortOrder: 1,
    },
};
