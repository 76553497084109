import $ from "jquery";
import { DateTime, FixedOffsetZone } from "luxon";

import { CoordinationState,
         EligibilityState,
         FlightStatus,
         ImpactLevel,
         ImpactType,
         TosSwimStatus,
         TosRouteSource } from "../constants/TosEnum";
import { COLUMN_TYPES } from "../constants/ColumnTypes";
import { makeArrayConditionIncludes,
         makeArrayConditionNotIncludes,
         makeConditionBase,
         makeConditionSingleCheck,
         makeConditionSingleCheckNot,
         makeEnumConditionEquals,
         makeEnumConditionNotEquals,
         makeEnumConditionOneOf } from "./columnFilterUtils";

/**
 * Adds required custom conditions for searchBuilder columns.
 */
export function addConditions()
{
    if (!$.fn.dataTable.ext.searchBuilder.conditions[
            COLUMN_TYPES.COORD_STATE])
    {
        addEnumType(CoordinationState, COLUMN_TYPES.COORD_STATE);
    }

    if (!$.fn.dataTable.ext.searchBuilder.conditions[
            COLUMN_TYPES.ELIGIBILITY_STATE])
    {
        addEnumType(EligibilityState, COLUMN_TYPES.ELIGIBILITY_STATE);
    }

    if (!$.fn.dataTable.ext.searchBuilder.conditions[
            COLUMN_TYPES.FLIGHT_STATUS])
    {
        addEnumType(FlightStatus, COLUMN_TYPES.FLIGHT_STATUS);
    }

    if (!$.fn.dataTable.ext.searchBuilder.conditions[
            COLUMN_TYPES.SWIM_STATE])
    {
        addEnumType(TosSwimStatus, COLUMN_TYPES.SWIM_STATE);
    }

    if (!$.fn.dataTable.ext.searchBuilder.conditions[
            COLUMN_TYPES.IMPACT])
    {
        addEnumType(ImpactType, COLUMN_TYPES.IMPACT);
    }

    if (!$.fn.dataTable.ext.searchBuilder.conditions[
            COLUMN_TYPES.IMPACT_LEVEL])
    {
        addEnumType(ImpactLevel, COLUMN_TYPES.IMPACT_LEVEL);
    }

    if (!$.fn.dataTable.ext.searchBuilder.conditions[
        COLUMN_TYPES.ROUTE_LIST])
    {
        addRouteTypeList();
    }

    if (!$.fn.dataTable.ext.searchBuilder.conditions[
            COLUMN_TYPES.BOOLEAN])
    {
        addBoolean();
    }

    if (!$.fn.dataTable.ext.searchBuilder.conditions[
            COLUMN_TYPES.HHMM])
    {
        addHhmm();
    }

    if (!$.fn.dataTable.ext.searchBuilder.conditions[
            COLUMN_TYPES.TIME_TO_EXPIRATION])
    {
        addTimeToExpiration();
    }
}

/**
 * Adds condition elements for Enumerated Types.
 */
function addEnumType(enumType, columnType)
{
    let values = [];
    let texts = [];
    for (const aVal in enumType)
    {
        values.push(aVal);
        texts.push(enumType[aVal].display);
    }

    $.fn.dataTable.ext.searchBuilder.conditions[columnType] =
    {
        "=": makeEnumConditionEquals(values, texts),
        "!=": makeEnumConditionNotEquals(values, texts),
        "one-of:": makeEnumConditionOneOf(values, texts),
    };
}

/**
 * Adds condition elements for the Route Types.
 */
function addRouteTypeList()
{
    let values = [];
    let texts = [];
    for (const source in TosRouteSource)
    {
        values.push(source);
        texts.push(TosRouteSource[source].display);
    }

    $.fn.dataTable.ext.searchBuilder.conditions[
        COLUMN_TYPES.ROUTE_LIST] =
    {
        "includes:": makeArrayConditionIncludes(values, texts),
        "notIncludes": makeArrayConditionNotIncludes(values, texts),
    };
}

/**
 * Adds condition elements for fields with a boolean type.
 */
function addBoolean()
{
    $.fn.dataTable.ext.searchBuilder.conditions[COLUMN_TYPES.BOOLEAN] =
    {
        "isTrue": makeConditionSingleCheck("isTrue", "true"),
        "isFalse": makeConditionSingleCheck("isFalse", "false"),
    };
}

/**
 * Adds condition elements for fields with the HHmm time type.
 */
function addHhmm()
{
    $.fn.dataTable.ext.searchBuilder.conditions[COLUMN_TYPES.HHMM] =
    {
        "< Minutes": {
            ...makeConditionBase(1, "relative", "minutes"),
            conditionName: "Less than X minutes from now",
            search: function(value, condition) {
                if (!value)
                {
                    return false;
                }

                const timeThen = DateTime.utc().plus({minutes: condition[0]});
                return value < timeThen;
            },
        },
        "> Minutes": {
            ...makeConditionBase(1, "relative", "minutes"),
            conditionName: "Greater than X minutes from now",
            search: function(value, condition){
                if (!value)
                {
                    return false;
                }

                const timeThen = DateTime.utc().plus({minutes: condition[0]});
                return value > timeThen;
            },
        },
        "between Minutes": {
            ...makeConditionBase(2, "relative", "minutes"),
            conditionName: "Between X and Y minutes from now",
            search: function(value, condition){
                if (!value)
                {
                    return false;
                }

                condition.sort(function(a, b) {
                    return a - b;
                });
                const timeFrom = DateTime.utc().plus({minutes: condition[0]});
                const timeTo = DateTime.utc().plus({minutes: condition[1]});
                return (timeFrom <= value) && (value <= timeTo);
            },
        },
        "< Zulu": {
            ...makeConditionBase(1, "zulu", "HHmm"),
            conditionName: "Before HHmm Zulu",
            search: function(value, condition){
                if (!value)
                {
                    return false;
                }

                let timeThen = DateTime.fromFormat(condition[0], "Hmm",
                    { zone: FixedOffsetZone.utcInstance });
                if (timeThen < DateTime.utc().plus({ hour: -12 }))
                {
                    timeThen = timeThen.plus({ days: 1 });
                }
                else if (timeThen > DateTime.utc().plus({ hour: 12 }))
                {
                    timeThen = timeThen.plus({ days: -1 });
                }
                return value < timeThen;
            },
        },
        "> Zulu": {
            ...makeConditionBase(1, "zulu", "HHmm"),
            conditionName: "After HHmm Zulu",
            search: function(value, condition){
                if (!value)
                {
                    return false;
                }

                let timeThen = DateTime.fromFormat(condition[0], "Hmm",
                    { zone: FixedOffsetZone.utcInstance });
                if (timeThen < DateTime.utc().plus({ hour: -12 }))
                {
                    timeThen = timeThen.plus({ days: 1 });
                }
                else if (timeThen > DateTime.utc().plus({ hour: 12 }))
                {
                    timeThen = timeThen.plus({ days: -1 });
                }
                return value > timeThen;
            },
        },
        "between Zulu": {
            ...makeConditionBase(2, "zulu", "HHmm"),
            conditionName: "Between HHmm and HHmm Zulu",
            search: function(value, condition){
                if (!value)
                {
                    return false;
                }

                // unfortunately, the condition values are the HHmm string,
                // so we have to convert to the proper "now-based" time
                // for each comparison
                let timeFrom = DateTime.fromFormat(condition[0], "Hmm",
                    { zone: FixedOffsetZone.utcInstance });
                let timeTo = DateTime.fromFormat(condition[1], "Hmm",
                    { zone: FixedOffsetZone.utcInstance });
                let now = DateTime.utc();

                // make sure the start time is within 12 hours of "now"
                if (timeFrom < now.plus({ hour: -12 }))
                {
                    timeFrom = timeFrom.plus({ days: 1 });
                }
                else if (timeFrom > now.plus({ hour: 12 }))
                {
                    timeFrom = timeFrom.plus({ days: -1 });
                }

                // make sure the end time is always after the start
                if (timeTo < timeFrom)
                {
                    timeTo = timeTo.plus({ days: 1 });
                }

                return (timeFrom <= value) && (value <= timeTo);
            },
        },
        "notSet": makeConditionSingleCheck("Not Set", ""),
    };
}

/**
 * Adds condition elements for the Time to Expiration field.
 *
 * ** IMPORTANT NOTE **
 * ColumnFormats converts "N/A" to Number.NEGATIVE_INFINITY for
 * sorting and filtering purposes.
 */
function addTimeToExpiration()
{
    $.fn.dataTable.ext.searchBuilder.conditions[
        COLUMN_TYPES.TIME_TO_EXPIRATION] =
    {
        "<": {
            ...makeConditionBase(1, "numeric", "minutes"),
            conditionName: "Less than",
            search: function(value, condition) {
                if (!value || (value === Number.NEGATIVE_INFINITY))
                {
                    return false;
                }

                return value < condition[0];
            },
        },
        "<=": {
            ...makeConditionBase(1, "numeric", "minutes"),
            conditionName: "Less than or equal to",
            search: function(value, condition) {
                if (!value || (value === Number.NEGATIVE_INFINITY))
                {
                    return false;
                }

                return value <= condition[0];
            },
        },
        ">": {
            ...makeConditionBase(1, "numeric", "minutes"),
            conditionName: "Greater than",
            search: function(value, condition){
                if (!value || (value === Number.NEGATIVE_INFINITY))
                {
                    return false;
                }

                return value > condition[0];
            },
        },
        ">=": {
            ...makeConditionBase(1, "numeric", "minutes"),
            conditionName: "Greater than or equl to",
            search: function(value, condition){
                if (!value || (value === Number.NEGATIVE_INFINITY))
                {
                    return false;
                }

                return value >= condition[0];
            },
        },
        "Between": {
            ...makeConditionBase(2, "numeric", "minutes"),
            conditionName: "Between",
            search: function(value, condition){
                if (!value || (value === Number.NEGATIVE_INFINITY))
                {
                    return false;
                }

                condition.sort(function(a, b) {
                    return a - b;
                });

                return (condition[0] <= value) && (value <= condition[1]);
            },
        },
        "isSet": makeConditionSingleCheckNot("Is Set", Number.NEGATIVE_INFINITY),
        "notSet": makeConditionSingleCheck("Is Not Set",
                      Number.NEGATIVE_INFINITY),
    };
}

/**
 * Adds the configuration-dependent conditions based on the given configuration.
 *
 * @param {object}   configuration                   airport configuration
 *                                                   information
 * @param {string[]} configuration.departureFixList  departure fixes
 * @param {string[]} configuration.departureGateList departure gates
 * @param {string[]} configuration.runwayList        runway names
 */
export function addConfigurationConditions(configuration)
{
    if (configuration.departureFixList && configuration.departureFixList.length)
    {
        let values = configuration.departureFixList;
        let texts = configuration.departureFixList;

        $.fn.dataTable.ext.searchBuilder.conditions[
            COLUMN_TYPES.DEPARTURE_FIX] =
            {
                "=": makeEnumConditionEquals(values, texts),
                "!=": makeEnumConditionNotEquals(values, texts),
                "one-of:": makeEnumConditionOneOf(values, texts),
            };
    }
    else
    {
        $.fn.dataTable.ext.searchBuilder.conditions[
            COLUMN_TYPES.DEPARTURE_FIX] =
            {
                "reload": { conditionName:
                    "Error loading departure fixes, please refresh page",
                },
            };
    }

    if (configuration.departureGateList &&
        configuration.departureGateList.length)
    {
        let values = configuration.departureGateList;
        let texts = configuration.departureGateList;

        $.fn.dataTable.ext.searchBuilder.conditions[
            COLUMN_TYPES.DEPARTURE_GATE] =
        {
            "=": makeEnumConditionEquals(values, texts),
            "!=": makeEnumConditionNotEquals(values, texts),
            "one-of:": makeEnumConditionOneOf(values, texts),
        };
    }
    else
    {
        $.fn.dataTable.ext.searchBuilder.conditions[
            COLUMN_TYPES.DEPARTURE_GATE] =
        {
            "reload": { conditionName:
                "Error loading departure gates, please refresh page",
            },
        };
    }

    if (configuration.runwayList && configuration.runwayList.length)
    {
        // runways can be a different list depending on the user, so always
        // update
        let values = configuration.runwayList;
        let texts = configuration.runwayList;

        $.fn.dataTable.ext.searchBuilder.conditions[COLUMN_TYPES.RUNWAY] =
        {
            "=": makeEnumConditionEquals(values, texts),
            "!=": makeEnumConditionNotEquals(values, texts),
            "one-of:": makeEnumConditionOneOf(values, texts),
        }
    }
    else
    {
        $.fn.dataTable.ext.searchBuilder.conditions[COLUMN_TYPES.RUNWAY] =
        {
            "reload": { conditionName:
                "Error loading runway names, please refresh page",
            },
        };
    }

    // Refresh existing filters
    let tableApis = $.fn.dataTable.tables({ visible: false, api: false });
    tableApis.forEach(function(table) {
        let tableApi = $(table).DataTable();
        tableApi.searchBuilder.rebuild(tableApi.searchBuilder.getDetails());
    });
}

/**
 * Makes custom adjustments to the default conditions for searchBuilder columns
 * for all dataTables.
 */
export function modifyBaseConditions()
{
    $.fn.dataTable.SearchBuilder.defaults.conditions.string["="].init =
        $.fn.dataTable.Criteria.initInput;
    $.fn.dataTable.SearchBuilder.defaults.conditions.string["="].inputValue =
        makeInputValueUpperCase();
    $.fn.dataTable.SearchBuilder.defaults.conditions.string["="].isInputValid =
        $.fn.dataTable.Criteria.isInputValidInput;

    $.fn.dataTable.SearchBuilder.defaults.conditions.string["!="].init =
        $.fn.dataTable.Criteria.initInput;
    $.fn.dataTable.SearchBuilder.defaults.conditions.string["!="].inputValue =
        makeInputValueUpperCase();
    $.fn.dataTable.SearchBuilder.defaults.conditions.string["!="].isInputValid =
        $.fn.dataTable.Criteria.isInputValidInput;

    $.fn.dataTable.SearchBuilder.defaults.conditions.string["starts"].inputValue =
        makeInputValueUpperCase();

    $.fn.dataTable.SearchBuilder.defaults.conditions.string["contains"].inputValue =
        makeInputValueUpperCase();

    $.fn.dataTable.SearchBuilder.defaults.conditions.string["ends"].inputValue =
        makeInputValueUpperCase();

    $.fn.dataTable.SearchBuilder.defaults.conditions.num["="].search =
        function (value, comparison) {
            return numEqualCheckEmpty(value, comparison);
        };
    $.fn.dataTable.SearchBuilder.defaults.conditions.num["!="].search =
        function (value, comparison) {
            return !numEqualCheckEmpty(value, comparison);
        };
    $.fn.dataTable.SearchBuilder.defaults.conditions.num["<"].search =
        function (value, comparison) {
            if (value === "")
            {
                return false;
            }
            else
            {
                return +value < +comparison[0];
            }
        };
    $.fn.dataTable.SearchBuilder.defaults.conditions.num["<="].search =
        function (value, comparison) {
            if (value === "")
            {
                return false;
            }
            else
            {
                return +value <= +comparison[0];
            }
        };
    $.fn.dataTable.SearchBuilder.defaults.conditions.num[">"].search =
        function (value, comparison) {
            if (value === "")
            {
                return false;
            }
            else
            {
                return +value > +comparison[0];
            }
        };
    $.fn.dataTable.SearchBuilder.defaults.conditions.num[">="].search =
        function (value, comparison) {
            if (value === "")
            {
                return false;
            }
            else
            {
                return +value >= +comparison[0];
            }
        };
    $.fn.dataTable.SearchBuilder.defaults.conditions.num["between"].search =
        function (value, comparison) {
            if (value === "")
            {
                return false;
            }
            else
            {
                if (+comparison[0] < +comparison[1]) {
                    return +comparison[0] <= +value && +value <= +comparison[1];
                }
                else {
                    return +comparison[1] <= +value && +value <= +comparison[0];
                }
            }
        };
    $.fn.dataTable.SearchBuilder.defaults.conditions.num["!between"].search =
        function (value, comparison) {
            if (value === "")
            {
                return true;
            }
            else
            {
                if (+comparison[0] < +comparison[1])
                {
                    return !(+comparison[0] <= +value &&
                             +value <= +comparison[1]);
                }
                else
                {
                    return !(+comparison[1] <= +value &&
                             +value <= +comparison[0]);
                }
            }
        };
}

/**
 * Checks numbers for equality, with "" not equivalent to 0.
 *
 * @param {number}   value       value from table
 * @param {number[]} comparison  numbers from condition, only check first entry
 */
function numEqualCheckEmpty(value, comparison)
{
    // Check empty strings first, so they don't match 0
    if (value === "")
    {
        return comparison[0] === "";
    }
    else if (comparison[0] === "")
    {
        return false;
    }
    else
    {
        return +value === +comparison[0];
    }
}

/*
 * Makes an inputValue condition function that retrieves values from 'input'
 * and converts them all to upper case.
 */
function makeInputValueUpperCase()
{
    let upperValueInput = function (el) {
        let values = [];

        // Go through the input elements and push each value to the return array
        for (let idx = 0, el4 = el; idx < el4.length; idx++)
        {
            let element = el4[idx];
            if ($(element).is("input"))
            {
                values.push($(element).val().toUpperCase());
            }
            return values;
        }
    };

    return upperValueInput;
}
