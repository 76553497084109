import PropTypes from "prop-types";

//Defines the look of the context menu
import "jquery-contextmenu/dist/jquery.contextMenu.min.css";
import "font-awesome/css/font-awesome.min.css";
import { ATC_APPROVER_ROLES,
    FO_ROLES,
    IN_SWIM_STATUSES } from "../../constants/Constants";
import { actionUtils } from "../../utils/actionUtils";
import { AtcAction, FoAction } from "../../constants/TosEnum";
import { tosClient } from "../../utils/tosClient";
import userConfig from "../../config/user_config.json";
import * as columnFormats from "../../column_utils/columnFormats";

/**
 * This class is used to create the context menu options for a flight table.
 */
class FlightTableContext
{
    static propTypes = {
        // The currently logged in user
        user: PropTypes.object.isRequired,

        // Flag to indicate if the user can submit route options
        readOnly: PropTypes.bool.isRequired,
    };

    /**
     * Constructs the Flight Table Context class, which is used to generate a context menu
     * for the flight table.
     *
     * @param {*}      props
     * @param {string} props.tableId          the table id for this table
     */
    constructor(user, isReadOnly)
    {
        this.user = user;
        this.isReadOnly = isReadOnly;
    }

    /**
     * Creates the right-click context menu for the departure table.
     * Flight Operators have a menu to exclude a flight. FO and ATC could
     * have actions based on flight coordination status.
     */
    createContextMenuBuilder()
    {
        let user = this.user;
        let readOnly = this.isReadOnly;
        let operator = FO_ROLES.includes(user.role);
        let controller = ATC_APPROVER_ROLES.includes(user.role);
        let getOperatorOptions = this.getOperatorOptions.bind(this);
        let getAtcOptions = this.getAtcOptions.bind(this);

        let buildFunc = function($tr, unusedEvent) {
            let fltTosId = $tr.data("tosId");
            let fltGufi = $tr.data("gufi");
            let fltOrigin = $tr.data("origin");
            let fltEligStatus = $tr.data("eligibilityStatus");
            let fltCoordStatus = $tr.data("coordinationStatus");
            let international = $tr.data("international");

            let itemList = {};
            // No context menu in read-only mode
            if (readOnly)
            {
                itemList.filler = {
                    name: "Route options unavailable",
                    disabled: true,
                };
            }
            // No context menu if no flight.  This removes redundant
            // error checking below
            else if (!fltTosId)
            {
                itemList.filler = {
                    name: "Missing flight data",
                    disabled: true,
                };
            }
            // Flight operator options
            else if (operator)
            {
                // Route options
                itemList.operatorAction = {
                    name: "Route Action",
                    icon: "loading",
                    items: getOperatorOptions(user, fltGufi, fltTosId,
                        fltEligStatus, fltCoordStatus, international),
                };

/* Flight Operator exclusion is not really working out, but leaving code
   commented out in place in case it comes back */
                // Exclude
/*                itemList.sep1 = "------";
                itemList.exclude = {
                    name: "Exclude Flight",
                    callback: function(unusedKey, unusedOptions) {
                        tosClient.postOperatorUpdate(user.role, user.roleUser,
                            fltTosId, "", "EXCLUDE_TOS_FLIGHT");
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return international || !actionUtils.enableOperatorExcluded(
                                   fltEligStatus, fltCoordStatus);
                    },
                };

                // Undo Exclude
                itemList.unexclude = {
                    name: "Undo Exclude Flight",
                    callback: function(unusedKey, unusedOptions) {
                        tosClient.postOperatorUpdate(user.role, user.roleUser,
                            fltTosId, "", "UNEXCLUDE_TOS_FLIGHT");
                    },
                    disabled: function(unusedKey, unusedOptions) {
                        return international || !actionUtils.enableOperatorUnexcluded(
                                   fltEligStatus, fltCoordStatus);
                    },
                };
*/
            }
            // Controller with approval authority
            else if (controller &&
                     actionUtils.hasAtcAuthority(user.role, fltOrigin))
            {
                itemList.atcAction = {
                    name: "Route Action",
                    icon: "loading",
                    items: getAtcOptions(user, fltGufi, fltTosId,
                               fltEligStatus, fltCoordStatus, international),
                };
            }
            // Controller without approval authority
            else if (controller)
            {
                itemList.atcAction = {
                    name: "No ATC authority for Origin=" + fltOrigin,
                    disabled: true,
                };
            }
            // Should never get here
            else
            {
                itemList.filler = {
                    name: "No actionable route options",
                    disabled: true,
                };
            }

            return {
                items: itemList,
                autoHide: true,
            };
        };

        return buildFunc;
    }

    /**
     * Submits the requested option as the only TOS option to SWIM.
     *
     * @param {object} user               current logged-in user
     * @param {string} tosId              TOS identifier
     * @param {string} optionId           name of chosen option
     * @param {array}  routeOptionList    list of options in this TOS
     */
    submitOption(user, tosId, optionId, routeOptionList)
    {
        const actionArray = [];
        routeOptionList.forEach(option => {
            if (option.id === optionId)
            {
                if (!IN_SWIM_STATUSES.includes(option.swimStatus))
                {
                    actionArray.push({
                        routeId: option.id,
                        action: FoAction.SAVE_SWIM.name,
                    });
                }
            }
            else
            {
                if (IN_SWIM_STATUSES.includes(option.swimStatus))
                    {
                        actionArray.push({
                            routeId: option.id,
                            action: FoAction.CANCEL_SWIM.name,
                        });
                    }
                }
        });
        tosClient.postOperatorArrayUpdate(user.role, user.roleUser, tosId, actionArray);
    }

    /**
     * Gets the flight operator's options for the right click menu.
     *
     * @param {object} user               current logged-in user
     * @param {string} gufi               flight identifier
     * @param {string} tosId              TOS identifier
     * @param {string} flightEligStatus   flight-level eligibility status
     * @param {string} flightCoordStatus  flight-level coordination status
     * @param {boolean} international     if flight is international
     *
     * @return {object} menu of route actions, each with its own submenu of
     *                  route options
     */
    async getOperatorOptions(user, gufi, tosId, flightEligStatus,
       flightCoordStatus, international)
    {
        let optionList = {};
        let postSwimOption = this.submitOption;

        let flightParams = {
            role: user.role,
            carrier: userConfig[user.roleUser].carrierForRequests,
            gufiList: [ gufi ],
        };
        let routesList = await tosClient.getFlightRoutes(flightParams);
        if (routesList)
        {
            let requestList = {};
            let undoRequestList = {};
            let approveList = {};
            let unapproveList = {};
            let unableList = {};
            let ununableList = {};
            
            // Pull out just the route options (i.e. exclude the filed route)
            let routeOptionsList = routesList.filter(
                (record) => (!(record.isFiledRoute || record.isInitialRoute)));

                console.log("Got route options", routesList, routeOptionsList);
            // Now create the sub-menu options for each route
            let hasRequest = false;
            let hasUndoRequest = false;
            let hasApprove = false;
            let hasUnapprove = false;
            let hasUnable = false;
            let hasUnunable = false;
            if (!international) {
                routeOptionsList.forEach((record) => {
                    const id = record.id;
                    const routeDataStr = this.formatOptionLine(record);
                    if (actionUtils.enableFoApprove(flightCoordStatus, record.coordinationStatus))
                    {
                        hasApprove = true;
                        approveList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Approve to Reroute", tosId, id);
                                tosClient.postOperatorUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    FoAction.APPROVE_TOS_ROUTE.name, gufi);
                            },
                        };
                    }
                    else if (actionUtils.enableFoUnapprove(flightCoordStatus, record.coordinationStatus))
                    {
                        hasUnapprove = true;
                        unapproveList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Cancel Approve to Reroute", tosId, id);
                                tosClient.postOperatorUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    FoAction.UNAPPROVE_TOS_ROUTE.name, gufi);
                            },
                        };
                    }

                    if (actionUtils.enableFoUnable(flightCoordStatus, record.coordinationStatus))
                    {
                        hasUnable = true;
                        unableList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Unable to Reroute", tosId, id);
                                tosClient.postOperatorUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    FoAction.UNABLE_TOS_ROUTE.name, gufi);
                            },
                        };
                    }
                    else if (actionUtils.enableFoUnunable(flightCoordStatus, record.coordinationStatus))
                    {
                        hasUnunable = true;
                        ununableList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Cancel Unable to Reroute", tosId, id);
                                tosClient.postOperatorUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    FoAction.UNUNABLE_TOS_ROUTE.name, gufi);
                            },
                        }
                    }

                    if (actionUtils.enableRequest(flightEligStatus,
                          flightCoordStatus, record.coordinationStatus))
                    {
                        hasRequest = true;
                        requestList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Request Reroute " + tosId + " " + id);
                                tosClient.postOperatorUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    FoAction.REQUEST_TOS_ROUTE.name, gufi);
                                postSwimOption(user, tosId, id, routeOptionsList);
                            },
                        };
                    }
                    else if (actionUtils.enableUnrequest(flightCoordStatus,
                          record.coordinationStatus))
                    {
                        hasUndoRequest = true;
                        undoRequestList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Undo Request Reroute " + tosId + " " + id);
                                tosClient.postOperatorUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    FoAction.CANCEL_REQUEST_TOS_ROUTE.name, gufi)
                            },
                        };
                    }
                });
            }

            optionList = {
                submit: {
                    name: "Request Reroute",
                    disabled: !hasRequest,
                    items: requestList,
                },
                unsubmit: {
                    name: "Undo Request Reroute",
                    disabled: !hasUndoRequest,
                    items: undoRequestList,
                },
                filler: "----",
                approve: {
                    name: "Approve Opportunity",
                    disabled: !hasApprove,
                    items: approveList,
                },
                unapprove: {
                    name: "Undo Approve Opportunity",
                    disabled: !hasUnapprove,
                    items: unapproveList,
                },
                filler2: "----",
                unable: {
                    name: "Unable",
                    disabled: !hasUnable,
                    items: unableList,
                },
                ununable: {
                    name: "Undo Unable",
                    disabled: !hasUnunable,
                    items: ununableList,
                },
            };
        }

        return optionList;
    }

    /**
     * Gets the controller's options for the right click menu.  This assumes
     * that it was already verified that ATC has authority for route actions.
     *
     * @param {object} user               current logged-in user
     * @param {string} gufi               flight identifier
     * @param {string} tosId              TOS identifier
     * @param {string} flightEligStatus   flight-level eligibility status
     * @param {string} flightCoordStatus  flight-level coordination status
     * @param {boolean} international     if flight is international
     *
     * @return {object} menu of route actions, each with its own submenu of
     *                  route options
     */
    async getAtcOptions(user, gufi, tosId, flightEligStatus, flightCoordStatus, international)
    {
        let optionList = {};

        let flightParams = {
            role: user.role,
            carrier: userConfig[user.roleUser].carrierForRequests,
            gufiList: [ gufi ],
        };

        let routesList = await tosClient.getFlightRoutes(flightParams);
        if (routesList)
        {
            let approveList = {};
            let undoApproveList = {};
            let unableList = {};
            let undoUnableList = {};
            let proposeList = {};
            let undoProposeList = {};

            // Pull out just the route options (i.e. exclude the filed route)
            let routeOptionsList = routesList.filter((record) => !(record.isFiledRoute || record.isInitialRoute));

            // These flags will be used to set the enabled/disabled status
            let hasApprove = false;
            let hasUndoApprove = false;
            let hasUnable = false;
            let hasUndoUnable = false;
            let hasPropose = false;
            let hasUndoPropose = false;

            // Now create the sub-menu options for each route
            if (!international)
            {
                routeOptionsList.forEach((record) => {
                    let id = record.id;
                    let routeDataStr = this.formatOptionLine(record);

                    if (actionUtils.enableAtcApprove(flightCoordStatus, record.coordinationStatus))
                    {
                        hasApprove = true;
                        approveList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Approve " + tosId + " " + id);
                                tosClient.postAtcUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    AtcAction.APPROVE_TOS_ROUTE.name, gufi)
                            },
                        };
                    }
                    else if (actionUtils.enableAtcUnapprove(flightCoordStatus,
                        record.coordinationStatus))
                    {
                        hasUndoApprove = true;
                        undoApproveList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Undo Approve " + tosId + " " + id);
                                tosClient.postAtcUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    AtcAction.UNAPPROVE_TOS_ROUTE.name, gufi)
                            },
                        };
                    }

                    if (actionUtils.enableAtcUnable(flightCoordStatus, record.coordinationStatus))
                    {
                        hasUnable = true;
                        unableList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Unable " + tosId + " " + id);
                                tosClient.postAtcUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    AtcAction.UNABLE_TOS_ROUTE.name, gufi)
                            },
                        };
                    }
                    else if (actionUtils.enableAtcUnunable(flightCoordStatus,
                        record.coordinationStatus))
                    {
                        hasUndoUnable = true;
                        undoUnableList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Undo Unable " + tosId + " " + id);
                                tosClient.postAtcUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    AtcAction.UNUNABLE_TOS_ROUTE.name, gufi)
                            },
                        };
                    }

                    if (actionUtils.enablePropose(flightEligStatus, flightCoordStatus,
                        record.coordinationStatus))
                    {
                        hasPropose = true;
                        proposeList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Proposing", tosId, id);
                                tosClient.postAtcUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    AtcAction.PROPOSE_TOS_ROUTE.name, gufi)
                            },
                        };
                    }
                    else if (actionUtils.enableUnpropose(flightCoordStatus,
                        record.coordinationStatus))
                    {
                        hasUndoPropose = true;
                        undoProposeList[id] = {
                            name: routeDataStr,
                            className: "routeOption",
                            callback: function(unusedKey, unusedOptions) {
                                console.log("Cancel Proposal", tosId, id);
                                tosClient.postAtcUpdate(user.role,
                                    user.roleUser, tosId, id,
                                    AtcAction.CANCEL_PROPOSE_TOS_ROUTE.name, gufi)
                        },
                    };
                }
            });
            }
            optionList = {
                approve: {
                    name: "Approve",
                    disabled: !hasApprove,
                    items: approveList,
                },
                unapprove: {
                    name: "Undo Approve",
                    disabled: !hasUndoApprove,
                    items: undoApproveList,
                },
                filler: "----",
                unable: {
                    name: "Unable",
                    disabled: !hasUnable,
                    items: unableList,
                },
                ununable: {
                    name: "Undo Unable",
                    disabled: !hasUndoUnable,
                    items: undoUnableList,
                },
                filler2: "----",
                suggest: {
                    name: "Propose",
                    disabled: !hasPropose,
                    items: proposeList,
                },
                unsuggest: {
                    name: "Undo Propose",
                    disabled: !hasUndoPropose,
                    items: undoProposeList,
                },
            };
        }

        return optionList;
    }

    /**
     * Parses out the route option record to create right click menu option
     * line.
     *
     * @param {object} record  route option data
     *
     * @return formatted line
     */
    formatOptionLine(record)
    {
        let routeDataStr = "";

        if ( !(record.isFiledRoute || record.isInitialRoute) )
        {
            routeDataStr = record.id + " " +
                record.source + " " +
                columnFormats.formatEligibilityState(
                    record.eligibilityStatus, "display") +
                " ETOT=" +
                columnFormats.formatDateTimeHHMM(
                    record.estimatedTakeOffTime, "display") +
                " Delay Savings=" +
                columnFormats.formatNumberWithSign(
                    record.offDelaySavings, "display");
        }

        return routeDataStr;
    }
}

export default FlightTableContext;

