import { combineReducers } from 'redux';

import { authReducer, settingsReducer } from 'tos-web-admin-client';

import columnsReducer from './columnsReducer';
import dataReducer from './dataReducer';
import modalReducer from './modalReducer';
import tablesReducer from './tablesReducer';
import flightMenuReducer from './flightMenuReducer';
import showMeReducer from './showMeReducer';

/**
 * This file contains the root reducer for Redux. It combines all the reducers
 * into one, which is then exported. In general, reducers specify how the
 * application's state changes in response to actions sent to the store. The
 * actions only describe what happened, whereas the reducer describes
 * (implements) how the application's state actually changes.
 *
 * The code below is shorthand for calling each of the specified reducer
 * functions and passing the given action. This means that an action can be
 * processed by multiple different reducers.
 */
const RootReducer = combineReducers({
  columnsReducer,
  dataReducer,
  modalReducer,
  tablesReducer,
  flightMenuReducer,
  showMeReducer,
  authentication: authReducer,
  settings: settingsReducer
})

export default RootReducer
