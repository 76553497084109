import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router,
         Route,
         Switch } from "react-router-dom";
import PropTypes from "prop-types";

import { LoginPage,
         LogoutModal,
         PrivateRoute,
         SettingsModal } from "tos-web-admin-client";
import "tos-web-admin-client/dist/tosAdmin.css";

import Help from "./Help";
import PageHeader from "./PageHeader";
import TablesManager from "./TablesManager";
import { addShowMeClientFlights, updateDataTimestamp } from "./actions";
import ColumnSelection from "./components/options/ColumnSelection";
import ErrorBoundary from "./components/shared/ErrorBoundary";
import AlertTypesModal from "./components/modals/AlertTypesModal";
import ColorAlertsModal from "./components/modals/ColorAlertsModal";
import ErrorModal from "./components/modals/ErrorModal";
import HotlineModal from "./components/modals/HotlineModal";
import ScratchModal from "./components/modals/ScratchModal";
import { APP_NAME, SHOW_ME_QUERY, COGNITO_AUTH_ENABLED } from "./constants/Constants";
import { packSettings, unpackSettings } from "./utils/settingsUtils";

/**
 * The main top level class. Sets up the necessary framework and components
 * on which the rest of the program rests.
 */
class App extends Component
{
    static propTypes = {

        // Action creator to add flights to the Show Me table which were
        // specified from the Client; from redux
        addShowMeClientFlights: PropTypes.func.isRequired,

        // Flag if the user is logged in; from redux
        loggedIn: PropTypes.bool,

        // Action creator to update the timestamp of the data; from redux
        updateDataTimestamp: PropTypes.func,
    };

    /**
     * Constructs the main App class.
     *
     * @param {*} props
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            wasUpdateSuccessful: true,
            errorText: "",
        };
        this.props.updateDataTimestamp(new Date());
    }

    /**
     * Called when the App is created.  This will check the URL to see if
     * the TWT is being opened at the request of the Client to show specific
     * flights in the Show Me table.
     */
    componentDidMount()
    {
        this.checkForShowMeFlights();
    }

    /**
     * Called when the App is updated.  This will check the URL to see if
     * the TWT is being opened at the request of the Client to show specific
     * flights in the Show Me table.
     *
     * @param {object} unusedPrevProps   the props object before the update
     */
    componentDidUpdate(unusedPrevProps)
    {
        // This has to be checked not just on mount but also on update because
        // of the timing of when the departure flight list comes in; if
        // Show Me flights are set before there are departure flights,
        // then the Show Me filter will remove the Client Show Me flights
        // thinking that they have taken off because they aren't in the
        // main departure flight list.
        this.checkForShowMeFlights();
    }

    /**
     * Checks to see if SHOW_ME flights were included in the URL and thus the
     * Show Me table should be displayed.
     */
    checkForShowMeFlights()
    {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has(SHOW_ME_QUERY))
        {
            const queryGufis = urlParams.get(SHOW_ME_QUERY);
            const showGufis = queryGufis.split(",");
            if (showGufis.length > 0)
            {
                 this.props.addShowMeClientFlights(showGufis);
            }
        }
    }

    /**
     * Used to update the time of the last update.
     *
     * @param {boolean} updateStatus  If update was successful.
     * @param {string}  text          Error related text.
     */
    onUpdate = (updateStatus, text) =>
    {
        this.setState({
            wasUpdateSuccessful: updateStatus,
            errorText: text,
        });
    }

    /**
     * Set up the nav bar for rendering, and link the data and help pages.
     *
     * @return {JSX.element} The main parent div containing all other pages
     */
    render()
    {
        let loginPage = (
            <LoginPage cognitoLogin={COGNITO_AUTH_ENABLED === 'true'}>
                <PageHeader titleOnly={true} />
            </LoginPage>
        );

        return (
          <div>
            <Router>

                {/* Enables switching between pages via the nav bar. */}
                <Switch>
                <PrivateRoute exact path="/">
                    <>
                    <PageHeader
                        wasUpdateSuccessful={this.state.wasUpdateSuccessful}
                        errorText={this.state.errorText}
                    />
                    <below-navbar>
                        <ErrorBoundary>
                            <ErrorModal />
                            <ColorAlertsModal />
                            <ScratchModal />
                            <HotlineModal />
                            <LogoutModal />
                            <SettingsModal appName={APP_NAME}
                                packSettings={packSettings}
                                unpackSettings={unpackSettings}
                            />
                        </ErrorBoundary>
                        <TablesManager onUpdate={this.onUpdate} />
                        { /* Place AlertTypesModal down here so any 
                            alert table will already exist */ }
                        <AlertTypesModal />
                    </below-navbar>
                    </>
                </PrivateRoute>

                <PrivateRoute loggedIn path="/help" >
                    <Help />
                </PrivateRoute>

                <PrivateRoute loggedIn path="/columnSelection" >
                    <ColumnSelection />
                </PrivateRoute>

                <Route path="/login">
                    {loginPage}
                </Route>
                </Switch>
            </Router>
          </div>
        )
    }
}

/**
 * Maps the redux state to local props for access. In this case, we don't really
 * need this prop, but we want to refresh the page when it changes.
 *
 * @param {Object} state  full redux state
 */
function mapStateToProps(state)
{
    const { loggedIn } = state.authentication;

    return {
        loggedIn
    };
}

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    addShowMeClientFlights,
    updateDataTimestamp,
};

export default connect(mapStateToProps, mapDispatchToProps) (App);
