import { ALERT_FILTER_TABLE_ID,
         CANDIDATE_FILTER_TABLE_ID,
         FLIGHT_MENU_ID,
         SHOW_ME_TABLE_ID } from "../constants/TableConstants";
import { addTable,
         resetSettings,
         resetSettingsComplete,
         updateAlertTypes,
         updateColorAlerts,
         updateColumns,
         updateFilter,
         updateOrdering,
         updateTableLength } from "../actions";
import { DEFAULT_ALERT_TYPES,
         DEFAULT_COLOR_ALERTS } from "../reducers/modalReducer";
import { DEFAULT_ROUTE_SORT, FIXED_ROUTE_COLUMNS } from "../column_utils/columnDefaults";

/**
 * This file contains the functions for pulling required settings from Redux
 * state into a single object for saving on the server, and for splitting a
 * single object from the server back out to where the rest of the app expects
 * the values.
 */

/**
 * The function to pull all the various pieces together and pack them into
 * a single object.
 *
 * @param {object} fullState   The current full Redux state
 */
export function packSettings(fullState)
{
    let tableSettings = {};
    fullState.tablesReducer.tableGroups.forEach((tableId) => {
        tableSettings[tableId] = {
            order: tableSortNumbersToNames(fullState.columnsReducer.columnsPerTable[tableId],
                fullState.columnsReducer.orderPerTable[tableId], 0),
            filter: fullState.tablesReducer.filterPerTable[tableId],
            length: fullState.tablesReducer.lengthPerTable[tableId],
        };
    });
    const routeSort = fullState.columnsReducer.orderPerTable[FLIGHT_MENU_ID];
    if (routeSort)
    {
        tableSettings[FLIGHT_MENU_ID] = {
            order: tableSortNumbersToNames(fullState.columnsReducer.columnsPerTable[FLIGHT_MENU_ID],
                routeSort, FIXED_ROUTE_COLUMNS.length),
        };
    }
    const alertFilter = fullState.tablesReducer.
        filterPerTable[ALERT_FILTER_TABLE_ID];
    if (alertFilter)
    {
        tableSettings[ALERT_FILTER_TABLE_ID] = {
            filter: alertFilter,
        };
    }
    const candidateAlertFilter = fullState.tablesReducer.
        filterPerTable[CANDIDATE_FILTER_TABLE_ID];
    if (candidateAlertFilter)
    {
        tableSettings[CANDIDATE_FILTER_TABLE_ID] = {
            filter: candidateAlertFilter,
        };
    }
    const showMeSort = fullState.columnsReducer.orderPerTable[SHOW_ME_TABLE_ID];
    if (showMeSort)
    {
        tableSettings[SHOW_ME_TABLE_ID] = {
            order: tableSortNumbersToNames(fullState.columnsReducer.columnsPerTable[SHOW_ME_TABLE_ID],
                showMeSort, 0),
        };
    }

    return {
        tableColumns: fullState.columnsReducer.columnsPerTable,
        tableGroupList: fullState.tablesReducer.tableGroups,
        tableSettings: tableSettings,
        alertTypes: fullState.modalReducer.alertTypes,
        colorAlerts: fullState.modalReducer.colorAlerts,
    };
}

/**
 * The thunk used to extract information from the saved settings object and
 * update Redux with the information.
 *
 * @param {object} fullSettings  Settings loaded from server
 */
export function unpackSettings(fullSettings)
{
    return (dispatch) => {
        dispatch(resetSettings());
        if (fullSettings.alertTypes)
        {
            dispatch(updateAlertTypes(
                Object.assign({}, DEFAULT_ALERT_TYPES, fullSettings.alertTypes)));
        }
        else
        {
            dispatch(updateAlertTypes(DEFAULT_ALERT_TYPES));
        }
        if (fullSettings.colorAlerts)
        {
            dispatch(updateColorAlerts(fullSettings.colorAlerts));
        }
        else
        {
            dispatch(updateColorAlerts(DEFAULT_COLOR_ALERTS));
        }
        dispatch(updateColumns(FLIGHT_MENU_ID,
            fullSettings.tableColumns[FLIGHT_MENU_ID]));
        if (fullSettings.tableSettings[FLIGHT_MENU_ID])
        {
            const sortByNum = typeof(fullSettings.tableSettings[FLIGHT_MENU_ID].order?.[0]?.[0]) === 'number' ?
                fullSettings.tableSettings[FLIGHT_MENU_ID].order :
                tableSortNamesToNumbers(fullSettings.tableColumns[FLIGHT_MENU_ID],
                  fullSettings.tableSettings[FLIGHT_MENU_ID].order, FIXED_ROUTE_COLUMNS.length);
            dispatch(updateOrdering(FLIGHT_MENU_ID, sortByNum));
        }
        else
        {
            const sortByNum = tableSortNamesToNumbers(fullSettings.tableColumns[FLIGHT_MENU_ID], DEFAULT_ROUTE_SORT,
                FIXED_ROUTE_COLUMNS.length);
            dispatch(updateOrdering(FLIGHT_MENU_ID, sortByNum));
        }

        if (fullSettings.tableColumns[SHOW_ME_TABLE_ID])
        {
            dispatch(updateColumns(SHOW_ME_TABLE_ID,
                fullSettings.tableColumns[SHOW_ME_TABLE_ID]));
        }
        if (fullSettings.tableSettings[SHOW_ME_TABLE_ID])
        {
          const sortByNum = typeof(fullSettings.tableSettings[SHOW_ME_TABLE_ID].order?.[0]?.[0]) === 'number' ?
              fullSettings.tableSettings[SHOW_ME_TABLE_ID].order :
              tableSortNamesToNumbers(fullSettings.tableColumns[SHOW_ME_TABLE_ID],
                  fullSettings.tableSettings[SHOW_ME_TABLE_ID].order, 0);
          dispatch(updateOrdering(SHOW_ME_TABLE_ID, sortByNum));
        }

        fullSettings.tableGroupList.forEach((tableId) => {
            dispatch(addTable(tableId));
            dispatch(updateColumns(tableId,
                fullSettings.tableColumns[tableId]));
            if (fullSettings.tableSettings &&
                fullSettings.tableSettings[tableId])
            {
                let tableSettings = fullSettings.tableSettings[tableId];
                const sortByNum = typeof(tableSettings.order?.[0]?.[0]) === 'number' ?
                    tableSettings.order :
                    tableSortNamesToNumbers(fullSettings.tableColumns[tableId],
                        tableSettings.order, 0);
                dispatch(updateOrdering(tableId, sortByNum));
                dispatch(updateFilter(tableId, tableSettings.filter));
                dispatch(updateTableLength(tableId, tableSettings.length));
            }
        });

        if (fullSettings.tableSettings[ALERT_FILTER_TABLE_ID])
        {
            dispatch(updateFilter(ALERT_FILTER_TABLE_ID,
                fullSettings.tableSettings[ALERT_FILTER_TABLE_ID].filter));
        }
        else
        {
            dispatch(updateFilter(ALERT_FILTER_TABLE_ID, null));
        }

        if (fullSettings.tableSettings[CANDIDATE_FILTER_TABLE_ID])
        {
            dispatch(updateFilter(CANDIDATE_FILTER_TABLE_ID,
                fullSettings.tableSettings[CANDIDATE_FILTER_TABLE_ID].filter));
        }
        else
        {
            dispatch(updateFilter(CANDIDATE_FILTER_TABLE_ID, null));
        }

        dispatch(resetSettingsComplete());
    };
}

export function tableSortNamesToNumbers(columnNames, currentSort, adjustment)
{
    const numberSort = currentSort.map(([namei, diri]) => {
        const newIndex = columnNames.indexOf(namei);
        return [ newIndex + adjustment, diri ];
    });
    return numberSort;
}

export function tableSortNumbersToNames(columnNames, currentSort, adjustment)
{
    const nameSort = currentSort.map(([numi, diri]) => {
        return [ columnNames[numi - adjustment], diri ];
    }).filter(sortRec => sortRec[0] !== null);
    return nameSort;
}
