import $ from "jquery";
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { closeScratchModal } from "../../actions";
import { tosClient } from "../../utils/tosClient";

/**
 * Serves as a modal dialog for displaying and updating a scratch pad entry.
 */
class ScratchModal extends Component
{
    static propTypes = {
        // Flag to indicate if the scratch pad is editable
        readOnly: PropTypes.bool.isRequired,

        // Flag to indicate if the modal should be shown
        isOpen: PropTypes.bool.isRequired,

        // Gufi of flight being updated
        gufi: PropTypes.string,

        // Callsign of flight being updated
        acid: PropTypes.string,

        // Historical scratch pad entries, if any
        scratchEntries: PropTypes.arrayOf(PropTypes.object),

        // The currenly logged in user information; from redux
        user: PropTypes.object.isRequired,

        // Action creator to close the scratch dialog; from redux
        closeScratchModal: PropTypes.func,
    };

    /**
     * Constructs the scratchpad editor modal.
     *
     * @param {*} props
     */
    constructor(props)
    {
        super(props);

        this.state = {
            value: "",
            processing: false,
        };
    }


    /**
     * Processes the value entered in the input field, updating state with the
     * new scratch pad value.
     *
     * @param {React.changeEvent} changeEvent event containing new scratch pad
     *                                        entry text
     */
    processInput = (changeEvent) => {
        let cleanInput =
            changeEvent.target.value.replace(/"/,"'");
        this.setState({ value: cleanInput });
    }

    /**
     * Passes the updated value back to server when the save button is clicked.
     */
    processApply = (changeEvent) => {
        this.setState({ processing: true });
        const newValue = this.state.value.trim();
        if (newValue === "")
        {
            this.processCancel(changeEvent);
        }
        else
        {
            // default to OPERATOR to cover ATC hitting enter in input
            const rcpt = changeEvent?.target?.dataset?.rcpt || 'OPERATOR';
            console.log("Update scratchpad for " + this.props.gufi +
                " to " + newValue + " going to " + rcpt);
            tosClient.postFlightUpdate(this.props.user.role,
                this.props.user.roleUser, this.props.gufi,
                { scratchPad: newValue, recipient: rcpt })
            .then(() => {
                this.setState({ value: "", error: "" });
                // update the tables with the new value before next data refresh
                $("input[name='" + this.props.gufi + "']").each(function () {
                    $(this).val(newValue);
                });
                this.props.closeScratchModal();
                this.setState({ processing: false });
                return true;
            })
            .catch(e => {
                this.setState({ error: e });
                console.log("Could not update scratchpad: " + e);
                this.setState({ processing: false });
                return false;
            })
        }
    }

    /**
     * Processes the cancel or close buttons being pressed by clearing the
     * input field and closing the modal.
     *
     * @param {object} unusedEvent    the button click event
     */
    processCancel = (unusedEvent) => {
        this.setState({ value: "", error: "" });
        this.props.closeScratchModal();
    }

    /**
     * Renders the table of scratch pad entries to show the conversation.
     */
    renderHistory()
    {
        if (this.props.scratchEntries &&
            Array.isArray(this.props.scratchEntries) &&
            this.props.scratchEntries.length)
        {
            return (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Source</th>
                    <th>Time</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {
                      this.props.scratchEntries.map((scratchEntry) => {
                          let entryTime = "";
                          if (scratchEntry.time)
                          {
                              // quick and dirty pull the time from the datetime
                              entryTime = scratchEntry.time.substr(11, 19);
                          }
                          return (
                            <tr key={entryTime}>
                              <td>{scratchEntry.user}</td>
                              <td>{entryTime}</td>
                              <td>{scratchEntry.scratchPad}</td>
                            </tr>
                          );
                      })
                  }
                </tbody>
              </Table>
            );
        }
    }

    /**
     * Displays the Modal with our options and content.
     *
     * @return {JSX.element} A single modal dialog for rendering
     */
    render()
    {
        if (this.props.isOpen)
        {
            return (
                <Modal id="scratch-modal" show={true}
                   onHide={this.processCancel} animation={false} scrollable
                >
                    <Modal.Header>
                        <Modal.Title>
                            Scratch Pad - {this.props.acid}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="text-danger">{this.state.error}</p>
                      {this.renderHistory()}
                      {this.props.readOnly ? "" :
                          <input type="text" className="form-control" autoFocus
                              onChange={this.processInput}
                              onKeyDown={e => (e.key === "Enter" &&
                                                (this.props.user.role !== 'OPERATOR') &&
                                                this.processApply())}
                              value={this.state.value || ""}
                              disabled={this.state.processing}
                          />
                      }
                    </Modal.Body>
                    {this.props.user.role === 'OPERATOR' ?
                        <Modal.Footer className="fo-scratch">
                            {this.props.readOnly ? "" :
                                <Button variant="success"
                                    onClick={this.processApply}
                                    disabled={this.state.processing}
                                    data-rcpt="ATC_CENTER"
                                >
                                    Send to Center
                                </Button>
                            }
                            {this.props.readOnly ? "" :
                                <Button variant="primary"
                                    onClick={this.processApply}
                                    disabled={this.state.processing}
                                    data-rcpt="ATC_TOWER"
                                >
                                    Send to Tower
                                </Button>
                            }
                            <Button variant="secondary"
                                onClick={this.processCancel}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                        :
                        <Modal.Footer>
                            {this.props.readOnly ? "" :
                                <Button variant="primary"
                                    onClick={this.processApply}
                                    disabled={this.state.processing}
                                    data-rcpt="OPERATOR"
                                >
                                    Apply
                                </Button>
                            }
                            <Button variant="secondary"
                                onClick={this.processCancel}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    }

                </Modal>
            );
        }
        else
        {
            return null;
        }
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state The current redux state
 *
 * @return {object} The desired redux state properties mapped to props
 */
const mapStateToProps = (state) =>
{
    return {
        readOnly: state.dataReducer.readOnly || state.authentication.user.readOnly,
        isOpen: state.modalReducer.scratchModalOpen,
        gufi: state.modalReducer.scratchModalGufi,
        acid: state.modalReducer.scratchModalAcid,
        scratchEntries: state.modalReducer.scratchModalEntries,
        user: state.authentication.user,
    };
};

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    closeScratchModal,
};

export default connect(mapStateToProps, mapDispatchToProps) (ScratchModal);
