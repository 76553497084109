

export const airportUtils = {
    iataToIcaoSimple
}


/**
 * A method to convert iata to icao airport format.
 * This just does the simple conversion by adding a "K" to the front.
 * This works for most US airports, but not all. And it won't work for any international airports.
 * 
 * @param {string} iataName The airport name in iata format
 */
export function iataToIcaoSimple( iataName ) {
    return "K" + iataName;
}