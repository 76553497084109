import React, { PureComponent } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "../../css/color-alerts.css";
import { closeColorAlertsModal, updateColorAlerts } from "../../actions";
import { getCssColor } from "../../column_utils/columnColors";
import { enumKeyToTitleMap,
         getColoredFieldMap,
         CONDITION_EQUALS,
         CONDITION_NOT_EQUALS,
         CONDITION_GREATER_THAN,
         CONDITION_GREATER_THAN_EQUALS,
         CONDITION_LESS_THAN,
         CONDITION_LESS_THAN_EQUALS,
         CONDITION_BETWEEN,
         CONDITION_ONE_OF } from "../../constants/ColorAlertConstants";
import { COLUMN_TYPES } from "../../constants/ColumnTypes";
import ColorAlertCondition from "./color_alert/ColorAlertCondition";
import ColorAlertField from "./color_alert/ColorAlertField";
import ColorAlertPicker from "./color_alert/ColorAlertPicker";
import ColorAlertValue from "./color_alert/ColorAlertValue";

const WHITE = {r:255, g:255, b:255};

/**
 * This component provides a modal for setting color alerts.  For each alert,
 * the user selects a field, a condition (e.g. <, >=, etc), a value, and the
 * color to display that field when its value meets the condition.  The user is
 * allowed to add more alerts or to delete existing alerts.  The dialog
 * also functions as a sub-modal to:
 * - provide a color chooser, 
 * - confirm removing an alert, and 
 * - report errors in alert specifications.
 */
class ColorAlertsModal extends PureComponent
{
    static propTypes = {
        // Flag to indicate if the modal should be shown; from Redux
        isOpen: PropTypes.bool.isRequired,

        // Color alerts; from Redux
        colorAlerts: PropTypes.arrayOf(PropTypes.object).isRequired,

        // Action creator to close the alert dialog; from Redux
        closeColorAlertsModal: PropTypes.func.isRequired,

        // Action creator to update the alert flags; from Redux
        updateColorAlerts: PropTypes.func.isRequired,
    };

    /**
     * Constructs the color alert modal.
     *
     * @param {*} props  none passed from parent
     */
    constructor(props)
    {
        super(props);

        // Keep a working copy of the alerts when the user is editing them.
        // The isCurrentStateInitialized flag indicates whether the working
        // copy has been initialized when the modal is opened.  The other
        // state values are used for the sub-modal functionality: the
        // color chooser for an alert, confirmation for removing an alert,
        // and error reporting of alert settings.
        this.state = {
            isCurrentStateInitialized: false,
            currentAlerts: [],

            isEditingFieldColor: false,
            editingFieldColor: WHITE,
            editingFieldColorIndex: undefined,
            editingFieldColorName: undefined,
            editingFieldColorWhiteText: false,

            isRemovingAlert: false,
            removingAlertIndex: undefined,
            removingAlertName: undefined,
            removingAlertCondition: undefined,
            removingAlertValue: undefined,
            removingAlertValue2: undefined,
            removingAlertValueOneOf: undefined,

            hasError: false,
            errorMsgs: undefined,
        };
    }

    /**
     * Grabs the current color alerts from the props and copies them in the
     * local state in order to maintain a local copy during the current
     * editing session.  We do this here instead of in componentDidUpdate
     * because the state needs to be set before the render instead of after.
     *
     * @param {object} props    the current properties
     * @param {object} state    the current state
     *
     * @return the initialized state or null
     */
    static getDerivedStateFromProps(props, state)
    {
        if (props.isOpen && !state.isCurrentStateInitialized)
        {
            const currentAlerts = [];
            const coloredFieldMap = getColoredFieldMap();

            props.colorAlerts.forEach(alert => {
                const thisAlert = {};
                const foundField = coloredFieldMap[alert.field];
                if (foundField)
                {
                    thisAlert.field = foundField;
                    thisAlert.condition = alert.condition;
                    thisAlert.value = alert.value;
                    thisAlert.value2 = alert.value2;
                    if ((alert.valueOneOf) && (alert.valueOneOf.length > 0))
                    {
                         thisAlert.valueOneOf = [ ...alert.valueOneOf ];
                    }
                    else
                    {
                        thisAlert.valueOneOf = undefined;
                    }
                    if (alert.rgb)
                    {
                        thisAlert.rgb = { ...alert.rgb };
                    }
                    else
                    {
                        thisAlert.rgb = { ...WHITE };
                    }
                    if (alert.whiteText)
                    {
                        thisAlert.whiteText = alert.whiteText;
                    }
                    else
                    {
                        thisAlert.whiteText = false;
                    }
                    currentAlerts.push(thisAlert);
                }
                else
                {
                    console.error("Could not find field", alert.field);
                }
            });

            return {isCurrentStateInitialized: true,  currentAlerts,
                hasError: false, errorMsgs: undefined };
        }
        return null;
    }

    /**
     * Adds a new field to the alerts when the user clicks the "Add Field"
     * button.
     *
     * @param {object} unusedEvent   button click event
     */
    addField = (unusedEvent) => {
        // Define a new alert
        const thisAlert = {};
        thisAlert.field = undefined
        thisAlert.condition = undefined;
        thisAlert.value = undefined;
        thisAlert.value2 = undefined;
        thisAlert.valueOneOf = undefined;
        thisAlert.rgb = { ...WHITE };
        thisAlert.whiteText = false;

        // Add the alert to the list
        const currentAlerts = [ ...this.state.currentAlerts ];
        currentAlerts.push(thisAlert);
        this.setState({ currentAlerts });
    }

    /**
     * Processes the Ok button being pressed in the error sub-modal.  This will
     * clear the error flags, which causes the error "sub-modal" to close and 
     * returns to the main color alert table.
     *
     * @param {object} unusedEvent   close button or background click, not used
     */
    acknowledgeError = (unusedEvent) => {
        this.setState({ hasError: false, errorMsgs: undefined });
    };

    /**
     * Processes the Apply button being pressed.  The alerts will be scanned
     * for any errors.  If there are errors, an error modal will be displayed;
     * if no errors, then the new settings will be dispatched and the modal
     * will be closed.
     *
     * @param {object} unusedEvent   button click event
     */
    processApply = (unusedEvent) => {
        const newColorAlerts = [ ...this.state.currentAlerts ];

        // Before we dispatch the alerts, we will error check them.
        // Error Checking Phase 1: check all alerts to make sure all
        // elements are set.  Any errors will be added to errorMsgs for display
        // when all error checking is complete.
        //
        // We will also collect which fields are used in which alerts in 
        // fieldIndexMap for use in Error Checking Phase 2.
        // key=field key and 
        // value=array containing indexes of alerts using that field.  
        const fieldIndexMap = {};
        const errorMsgs = [];
        this.checkAllElementsSet(newColorAlerts, fieldIndexMap, errorMsgs);

        // Error Checking Phase 2: check the alerts to make sure there are
        // no inconsistent or overlapping data values.  Only do this if there
        // were no errors in Phase 1.
        if (errorMsgs.length === 0)
        {
            // This coloredFieldMap is a map with 
            // key=fieldKey and value=full field object
            // This map is used to know a field's data type and for
            // creating user-friendly error messages
            const coloredFieldMap = getColoredFieldMap();

            for (const fieldKey in fieldIndexMap)
            {
                // We only need to run the cross-check if a field is used in
                // more than one alert.
                const alertIndicesUsingThisField = fieldIndexMap[fieldKey];
                if (alertIndicesUsingThisField.length > 1)
                {
                    const fieldType = coloredFieldMap[fieldKey].type;

                    // Field value is an enum type
                    if ((fieldType === COLUMN_TYPES.COORD_STATE) ||
                        (fieldType === COLUMN_TYPES.ELIGIBILITY_STATE) ||
                        (fieldType === COLUMN_TYPES.FLIGHT_STATUS))
                    {
                        this.checkEnumValues(newColorAlerts, fieldKey,
                            alertIndicesUsingThisField, errorMsgs,
                            coloredFieldMap);
                    }
                    // Field value is a numeric type
                    else
                    {
                        this.checkNumericValues(newColorAlerts, fieldKey,
                            alertIndicesUsingThisField, errorMsgs,
                            coloredFieldMap);
                    }
                }
            }
        }

        // If there were any errors, set the state to report them
        if (errorMsgs.length > 0)
        {
            this.setState({ hasError: true, errorMsgs });
        }
        // No errors; dispatch the new parameters
        else
        {
            // Our working copy of the alert has the full field object.
            // Replace that with just the field key
            newColorAlerts.forEach(alert => alert.field = alert.field.key);

            // Pass along the new values and close the modal
            this.props.updateColorAlerts(newColorAlerts);
            this.props.closeColorAlertsModal();
            this.setState({ isCurrentStateInitialized: false,
                currentAlerts: [], hasError: false, errorMsgs: undefined });
        }
    };

    /**
     * Checks that all elements in each alert are set.  This is Phase 1 of
     * the error checking when the Apply button is pressed.  As a side effect,
     * this will also gather all the alerts that each field is used in, which
     * will be used later in the Phase 2 error checking.
     *
     * @param {array}  newColorAlerts   array of all alerts
     * @param {object} fieldIndexMap    which fields are used in which alerts.
     *                                  key=field key, value=array of indices
     *                                  of alerts that use the field (output)
     * @param {array}  errorMsgs        collection of error messages to
     *                                  report to the user (output)
     */
    checkAllElementsSet = (newColorAlerts, fieldIndexMap, errorMsgs) =>
    {
        newColorAlerts.forEach((alert, index) => {
            let lookingGood = true;
            if (!alert.field)
            {
                lookingGood = false;
                errorMsgs.push(
                    "There is an empty alert; please set it or delete it.");
            }
            else
            {
                const fieldName = alert.field.title;
                if (!alert.condition)
                {
                    lookingGood = false;
                    errorMsgs.push(
                        "Please set a condition and value for '" +
                        fieldName + "'.");
                }
                else if (alert.condition === CONDITION_BETWEEN)
                {
                    if (!alert.value || !alert.value2)
                    {
                        lookingGood = false;
                        errorMsgs.push("Please set a value for '" +
                            fieldName + " " + CONDITION_BETWEEN + "'.");
                    }
                    else if (isNaN(+alert.value) || isNaN(+alert.value2))
                    {
                        lookingGood = false;
                        errorMsgs.push("Both values '" + alert.value +
                            "' and '" + alert.value2 + "' for '" + fieldName + 
                            " " + CONDITION_BETWEEN +
                            "' must be numeric.");
                    }
                    // The multiplication by 1 converts the string to number
                    else if ((alert.value * 1) >= (alert.value2 * 1))
                    {
                        lookingGood = false;
                        errorMsgs.push("The second value '" + alert.value2 +
                            "' for '" + fieldName + " " + CONDITION_BETWEEN +
                            "' must be less than the first value of '" +
                            alert.value + "'.");
                    }
                }
                else if (alert.condition === CONDITION_ONE_OF)
                {
                    if (!alert.valueOneOf)
                    {
                        lookingGood = false;
                        errorMsgs.push("Please set a value for '" +
                            fieldName + " " + CONDITION_ONE_OF + "'.");
                    }
                    else if (alert.valueOneOf.length === 1)
                    {
                        lookingGood = false;
                        errorMsgs.push("'" + fieldName + " " +
                            CONDITION_ONE_OF + "' only has a single value '" +
                            enumKeyToTitleMap[alert.valueOneOf[0]] +
                            "'; this condition should have multiple values.");
                    }
                }
                else
                {
                    if (!alert.value)
                    {
                        lookingGood = false;
                        errorMsgs.push("Please set a value for '" +
                            fieldName + "'.");
                    }
                    else if (((alert.field.type === COLUMN_TYPES.NUM) ||
                                 (alert.field.type === COLUMN_TYPES.HHMM) ||
                                 (alert.field.type === 
                                     COLUMN_TYPES.TIME_TO_EXPIRATION)) &&
                               isNaN(+alert.value))
                    {
                        errorMsgs.push("'" + fieldName + " " +
                            CONDITION_ONE_OF + "' value '" + alert.value +
                            "' must be numeric.");
                    }
                }
                if (!alert.rgb ||
                   ((alert.rgb.r === WHITE.r) &&
                        (alert.rgb.g === WHITE.g) &&
                        (alert.rgb.b === WHITE.b)))
                {
                    lookingGood = false;
                    errorMsgs.push("Please set a color for " +
                        this.createAlertDescription(fieldName,
                        alert.condition, alert.value, alert.value2,
                        alert.valueOneOf) + ".");
                }
            }
            if (lookingGood)
            {
                // If there are no basic errors, store the index of this
                // alert in the list of all alerts that use this field
                if (!fieldIndexMap[alert.field.key])
                {
                    fieldIndexMap[alert.field.key] = [];
                }
                fieldIndexMap[alert.field.key].push(index);
            }
        });
    };

    /**
     * Checks the values of an enum field type to make sure there are no
     * redundancies or inconsistencies across alerts.  This is called as part
     * of the Phase 2 error checking when the Apply button is pressed.
     *
     * @param {array}  newColorAlerts             array of all alerts
     * @param {string} fieldKey                   key of the field being
     *                                            checked, e.g. 'flightStatus'
     * @param {array}  alertIndicesUsingThisField indices of all alerts that
     *                                            reference the 'fieldKey' field
     * @param {array}  errorMsgs                  collection of error messages
     *                                            to report to the user (output)
     * @param {object} coloredFieldMap            map of field key to field 
     *                                            object
     */
    checkEnumValues = (newColorAlerts, fieldKey,
        alertIndicesUsingThisField, errorMsgs, coloredFieldMap) =>
    {
        // Enum error check Phase 2 Part 1:  Compare each alert with all the
        // alerts that have been checked so far, looking for redundancies
        const usedValues = {};
        alertIndicesUsingThisField.forEach(index => {

            const thisAlert = newColorAlerts[index];

            // Check a single value, i.e. from an "=" or "!=" condition
            if (thisAlert.value)
            {
                this.checkEnumValue(usedValues, fieldKey, thisAlert.condition,
                    thisAlert.value, errorMsgs, coloredFieldMap);
            }
            // Check each element of a "One Of"
            else if (thisAlert.valueOneOf)
            {
                thisAlert.valueOneOf.forEach(val => {
                    this.checkEnumValue(usedValues, fieldKey,
                        thisAlert.condition, val, errorMsgs, coloredFieldMap);
                });
            }
        });

        // Enum error check Phase 2 Part 2:  Check for inconsistencies if any
        // condition was "!=".   In the variables below,
        //  - "excluded" means "!="
        //  - "included" means "=" or "One Of"
        // Error check Part 2 prep:  find all values that have been excluded.
        let excludedValues = [];
        for (const value in usedValues)
        {
            const valueConditions = usedValues[value];
            if (valueConditions.isNotEquals)
            {
                 excludedValues.push(valueConditions.value);
            }
        }

        // Enum error check Part 2.a:  Two different values cannot be excluded
        // e.g. "Flight Status != PUSHBACK" and "Flight Status != SCHEDULED".
        if (excludedValues.length > 1)
        {
            // Convert the values from the key format to the user-friendly
            // format.  (Yes this will be bad grammar if there are 3 or more
            // excluded values, but hopefully the user isn't stupid enough
            // to do that...)
            let valueString = "";
            excludedValues.forEach(val =>
                valueString = valueString + "'" + enumKeyToTitleMap[val] +
                    "' and ");
            valueString = valueString.substring(0, valueString.length - 5);

            // Now create the full error message
            const errMsg = "'" + coloredFieldMap[fieldKey].title +
                " " + CONDITION_NOT_EQUALS + "' is duplicated with values " +
               valueString + ".";
            errorMsgs.push(errMsg);
        }

        // Enum error check Part 2.b:  Make sure there are no inconsistencies
        // in the conditions across alerts with different values; for example:
        // "Flight Status != PUSHBACK" and "Flight Status = SCHEDULED".
        // Note that having the same value is not an error; for example:
        // "Flight Status != SCHEDULED" and "Flight Status = SCHEDULED" is fine.
        if (excludedValues.length > 0)
        {
            for (const value in usedValues)
            {
                const valueConditions = usedValues[value];

                if (valueConditions.isEquals || valueConditions.isOneOf)
                {
                    if (!excludedValues.includes(valueConditions.value))
                    {
                        const errMsg = "'" + coloredFieldMap[fieldKey].title +
                            " " + CONDITION_NOT_EQUALS +
                            " " + enumKeyToTitleMap[excludedValues[0]] +
                            "' conflicts with '" +
                            (valueConditions.isEquals ? CONDITION_EQUALS :
                                CONDITION_ONE_OF) + " " +
                                enumKeyToTitleMap[valueConditions.value] + "'.";
                        errorMsgs.push(errMsg);
                    }
                }
            }
        }
    }

    /**
     * Checks an enum value against previous alerts that used this value,
     * looking for redundancies or inconsistencies.  This is called in
     * Phase 2 Part 1 of the enum error checking when the Apply button is 
     * pressed.
     *
     * @param {object} usedValues       collection of values used so far;
     *                                  property is the field's value,
     *                                  value is a set of flags for which
     *                                  condition was used
     * @param {string} thisFieldKey     field key of the alert being checked,
     *                                  used for error messages
     * @param {string} thisCondition    condition of the alert being checked
     * @param {string} thisValue        value of the alert being checked;
     *                                  if thisCondition is "One Of", thisValue
     *                                  will be only one of those values
     * @param {array}  errorMsgs        collection of error messages
     *                                  to report to the user
     * @param {object} coloredFieldMap  map of field key to field object
     */
    checkEnumValue(usedValues, thisFieldKey, thisCondition, thisValue,
        errorMsgs, coloredFieldMap)
    {
        // Comparisons are easier if we just set boolean flags for what
        // the condition was
        const isEquals = (thisCondition === CONDITION_EQUALS);
        const isNotEquals = (thisCondition === CONDITION_NOT_EQUALS);
        const isOneOf = (thisCondition === CONDITION_ONE_OF);

        // These are flags for whether this value has been used in
        // an alert before and what the conditions were
        let usedConditions = { ...usedValues[thisValue] };

        // This value has been used in an alert before; see if it conflicts
        // with other conditions
        if (usedConditions.value)
        {
            if (isEquals)
            {
                // Check for isEquals with isNotEquals has to be handled
                // later, in the Part 2 error checking
                if (usedConditions.isEquals)
                {
                    const errMsg = this.createEnumFieldSingleError(
                        coloredFieldMap[thisFieldKey].title, 
                        thisCondition, enumKeyToTitleMap[thisValue]);
                    errorMsgs.push(errMsg);
                }
                if (usedConditions.isOneOf)
                {
                    const errMsg = this.createEnumFieldOneOfError(
                        coloredFieldMap[thisFieldKey].title, 
                        thisCondition, enumKeyToTitleMap[thisValue]);
                    errorMsgs.push(errMsg);
                }
                usedConditions.isEquals = true;
            }
            else if (isNotEquals)
            {
                // Check for isNotEquals with isEquals has to be handled
                // later, in the Part 2 error checking
                if (usedConditions.isNotEquals)
                {
                    const errMsg = this.createEnumFieldSingleError(
                        coloredFieldMap[thisFieldKey].title, 
                        thisCondition, enumKeyToTitleMap[thisValue]);
                    errorMsgs.push(errMsg);
                }
                if (usedConditions.isOneOf)
                {
                    const errMsg = this.createEnumFieldOneOfError(
                        coloredFieldMap[thisFieldKey].title, 
                        thisCondition, enumKeyToTitleMap[thisValue]);
                    errorMsgs.push(errMsg);
                }
                usedConditions.isNotEquals = true;
            }
            else if (isOneOf)
            {
                if (usedConditions.isNotEquals)
                {
                    const errMsg = this.createEnumFieldOneOfError(
                        coloredFieldMap[thisFieldKey].title,
                        CONDITION_NOT_EQUALS, enumKeyToTitleMap[thisValue]);
                    errorMsgs.push(errMsg);
                }
                if (usedConditions.isEquals)
                {
                    const errMsg = this.createEnumFieldOneOfError(
                        coloredFieldMap[thisFieldKey].title,
                        CONDITION_EQUALS, enumKeyToTitleMap[thisValue]);
                    errorMsgs.push(errMsg);
                }
                if (usedConditions.isOneOf)
                {
                    const errMsg = this.createEnumFieldOneOfError(
                        coloredFieldMap[thisFieldKey].title,
                        CONDITION_ONE_OF, enumKeyToTitleMap[thisValue]);
                    errorMsgs.push(errMsg);
                }
                usedConditions.isOneOf = true;
            }
        }
        // This value has NOT been used in an alert before; just save it
        else
        {
            usedConditions = { isEquals, isNotEquals, isOneOf,
                value: thisValue};
        }
        usedValues[thisValue] = usedConditions;
    }

    /**
     * Checks the values of a numeric field type to make sure there are no
     * redundancies or inconsistencies.  This is called in the Phase 2 error
     * checking when the Apply button is pressed.
     *
     * @param {array}  newColorAlerts             array of all alerts
     * @param {string} fieldKey                   key of the field being
     *                                            checked, e.g. 'inDelay'
     * @param {array}  alertIndicesUsingThisField indices of all alerts that
     *                                            reference the 'fieldKey' field
     * @param {array}  errorMsgs                  collection of error messages
     *                                            to report to the user (output)
     * @param {object} coloredFieldMap            map of field key to field 
     *                                            object
     */
    checkNumericValues = (newColorAlerts, fieldKey,
        alertIndicesUsingThisField, errorMsgs, coloredFieldMap) =>
    {
        // Error checking prep:
        // For each alert, convert the data values from strings to numbers
        // and set flags for what the condition is to make the comparisons
        // easier.
        const usedValues = [];
        alertIndicesUsingThisField.forEach(index => {

            const thisAlert = newColorAlerts[index];

            // Multiplying a string by 1 is the most efficient way to
            // convert it to a number
            usedValues.push({
                condition: thisAlert.condition,
                value: thisAlert.value * 1,
                value2: thisAlert.value2 * 1,
                isEquals:
                   (thisAlert.condition === CONDITION_EQUALS),
                isNotEquals:
                   (thisAlert.condition === CONDITION_NOT_EQUALS),
                isGreaterThan:
                   (thisAlert.condition === CONDITION_GREATER_THAN),
                isGreaterThanEquals:
                   (thisAlert.condition === CONDITION_GREATER_THAN_EQUALS),
                isLessThan:
                   (thisAlert.condition === CONDITION_LESS_THAN),
                isLessThanEquals:
                   (thisAlert.condition === CONDITION_LESS_THAN_EQUALS),
                isBetween:
                   (thisAlert.condition === CONDITION_BETWEEN),
            });
        });

        // Now go through the used values and make sure the conditions
        // or data values don't contradict each other or overlap
        for (let idx = 0; idx < usedValues.length - 1; idx++)
        {
            let myValues = usedValues[idx];

            // Compare myValues with all the alerts that follow it
            for (let idx2 = idx + 1; idx2 < usedValues.length; idx2++)
            {
                let otherValues = usedValues[idx2];

                // Validate the data values based on the type of condition
                if (myValues.isEquals)
                {
                    if ((otherValues.isEquals &&
                            (myValues.value === otherValues.value)) ||
                        (otherValues.isNotEquals &&
                            (myValues.value !== otherValues.value)) ||
                        (otherValues.isGreaterThan &&
                            (myValues.value > otherValues.value)) ||
                        (otherValues.isGreaterThanEquals &&
                            (myValues.value >= otherValues.value)) ||
                        (otherValues.isLessThan &&
                            (myValues.value < otherValues.value)) ||
                        (otherValues.isLessThanEquals &&
                            (myValues.value <= otherValues.value)) ||
                        (otherValues.isBetween &&
                            (otherValues.value <= myValues.value) &&
                            (myValues.value < otherValues.value2)))
                    {
                        const errMsg = this.createNumericFieldError(
                            coloredFieldMap[fieldKey].title,
                            myValues.condition, myValues.value,
                            myValues.value2, otherValues.condition,
                            otherValues.value, otherValues.value2);
                        errorMsgs.push(errMsg);
                    }
                }
                else if (myValues.isNotEquals)
                {
                    if ((otherValues.isEquals &&
                            (myValues.value !== otherValues.value)) ||
                        otherValues.isNotEquals ||
                        otherValues.isGreaterThan ||
                        otherValues.isGreaterThanEquals ||
                        otherValues.isLessThan ||
                        otherValues.isLessThanEquals ||
                        otherValues.isBetween)
                    {
                        const errMsg = this.createNumericFieldError(
                            coloredFieldMap[fieldKey].title,
                            myValues.condition, myValues.value,
                            myValues.value2, otherValues.condition,
                            otherValues.value, otherValues.value2);
                        errorMsgs.push(errMsg);
                    }
                }
                else if (myValues.isGreaterThan)
                {
                    if ((otherValues.isEquals &&
                            (otherValues.value > myValues.value)) ||
                        (otherValues.isNotEquals) ||
                        (otherValues.isGreaterThan) ||
                        (otherValues.isGreaterThanEquals) ||
                        (otherValues.isLessThan &&
                            (myValues.value < otherValues.value)) ||
                        (otherValues.isLessThanEquals &&
                            (myValues.value < otherValues.value)) ||
                        (otherValues.isBetween &&
                            (myValues.value < otherValues.value2)))
                    {
                        const errMsg = this.createNumericFieldError(
                            coloredFieldMap[fieldKey].title,
                            myValues.condition, myValues.value,
                            myValues.value2, otherValues.condition,
                            otherValues.value, otherValues.value2);
                        errorMsgs.push(errMsg);
                    }
                }
                else if (myValues.isGreaterThanEquals)
                {
                    if ((otherValues.isEquals &&
                            (otherValues.value >= myValues.value)) ||
                        (otherValues.isNotEquals) ||
                        (otherValues.isGreaterThan) ||
                        (otherValues.isGreaterThanEquals) ||
                        (otherValues.isLessThan &&
                            (myValues.value < otherValues.value)) ||
                        (otherValues.isLessThanEquals &&
                            (myValues.value <= otherValues.value)) ||
                        (otherValues.isBetween &&
                            (myValues.value < otherValues.value2)))
                    {
                        const errMsg = this.createNumericFieldError(
                            coloredFieldMap[fieldKey].title,
                            myValues.condition, myValues.value,
                            myValues.value2, otherValues.condition,
                            otherValues.value, otherValues.value2);
                        errorMsgs.push(errMsg);
                    }
                }
                else if (myValues.isLessThan)
                {
                    if ((otherValues.isEquals &&
                            (otherValues.value < myValues.value)) ||
                        (otherValues.isNotEquals) ||
                        (otherValues.isGreaterThan &&
                            (otherValues.value < myValues.value)) ||
                        (otherValues.isGreaterThanEquals &&
                            (otherValues.value < myValues.value)) ||
                        (otherValues.isLessThan) ||
                        (otherValues.isLessThanEquals) ||
                        (otherValues.isBetween &&
                            (myValues.value > otherValues.value)))
                    {
                        const errMsg = this.createNumericFieldError(
                            coloredFieldMap[fieldKey].title,
                            myValues.condition, myValues.value,
                            myValues.value2, otherValues.condition,
                            otherValues.value, otherValues.value2);
                        errorMsgs.push(errMsg);
                    }
                }
                else if (myValues.isLessThanEquals)
                {
                    if ((otherValues.isEquals &&
                            (otherValues.value <= myValues.value)) ||
                        (otherValues.isNotEquals) ||
                        (otherValues.isGreaterThan &&
                            (otherValues.value < myValues.value)) ||
                        (otherValues.isGreaterThanEquals &&
                            (otherValues.value <= myValues.value)) ||
                        (otherValues.isLessThan) ||
                        (otherValues.isLessThanEquals) ||
                        (otherValues.isBetween &&
                            (myValues.value >= otherValues.value)))
                    {
                        const errMsg = this.createNumericFieldError(
                            coloredFieldMap[fieldKey].title,
                            myValues.condition, myValues.value,
                            myValues.value2, otherValues.condition,
                            otherValues.value, otherValues.value2);
                        errorMsgs.push(errMsg);
                    }
                }
                else if (myValues.isBetween)
                {
                    if ((otherValues.isEquals &&
                            ((myValues.value <= otherValues.value) &&
                             (otherValues.value < myValues.value2))) ||
                        (otherValues.isNotEquals) ||
                        ((otherValues.isGreaterThan ||
                             otherValues.isGreaterThanEquals) &&
                            (otherValues.value < myValues.value2)) ||
                        (otherValues.isLessThan &&
                             (otherValues.value > myValues.value)) ||
                        (otherValues.isLessThanEquals &&
                             (otherValues.value >= myValues.value)) ||
                        (otherValues.isBetween &&
                            ((myValues.value < otherValues.value) &&
                             (otherValues.value < myValues.value2)) ||
                            ((myValues.value < otherValues.value2) &&
                             (otherValues.value2 <= myValues.value2))))
                    {
                        const errMsg = this.createNumericFieldError(
                            coloredFieldMap[fieldKey].title,
                            myValues.condition, myValues.value,
                            myValues.value2, otherValues.condition,
                            otherValues.value, otherValues.value2);
                        errorMsgs.push(errMsg);
                    }
                }
            }
        }
    }

    /**
     * Creates a description of an alert as fully as possible based on what
     * condition and values are set.  The description is enclosed in
     * single quotes.  This method assumes that it is not being called unless
     * the fieldName at a minimum has been set.
     *
     * @param {string} fieldName   user-friendly name of the field
     * @param {string} condition   condition such as "="; may be undefined
     * @param {string} value       field value; may be undefined
     * @param {string} value2      second field value if the condition is
     *                             "Between"; may be undefined
     * @param {array}  valueOneOf  field values if the condition is "One Of"
     *                             may be undefined
     *
     * @return {string} the alert description
     */
    createAlertDescription = (fieldName, condition, value, value2,
        valueOneOf) =>
    {
        let description = "'" + fieldName;

        if (condition && (value || value2 || valueOneOf))
        {
            description = description + " " + condition + " ";
            if (value2)
            {
                description = description + value + " <= value < " + value2;
            }
            else if (valueOneOf)
            {
                valueOneOf.forEach(val => {description = description +
                    enumKeyToTitleMap[val] + ","});
                description = description.substring(0, description.length-1);
            }
            else
            {
                if (enumKeyToTitleMap[value])
                {
                    description = description + enumKeyToTitleMap[value];
                }
                else
                {
                    description = description + value;
                }
            }
        }
        description = description + "'";

        return description;
    }

    /**
     * Creates the error message when an enum field with a single value
     * duplicates another alert with that same value.
     *
     * @param {string} fieldName   user-friendly name of the field
     * @param {string} condition   condition, i.e. "=" or "!="
     * @param {string} value       field value such as "Pushback"
     *
     * @return {string} the error message
     */
    createEnumFieldSingleError = (fieldName, condition, value) =>
    {
        const errMsg = "'" + fieldName +
            "' cannot have multiple alerts with '" + condition +
            " " + value + "'.";

        return errMsg;
    }

    /**
     * Creates the error message for an enum field with a "One Of" condition
     * where the value is redundant of another alert with the same value
     * and condition of "=" or "!=".
     *
     * @param {string} fieldName   user-friendly name of the field
     * @param {string} condition   condition, i.e. "=" or "!="
     * @param {string} value       field value such as "Pushback"
     *
     * @return {string} the error message
     */
    createEnumFieldOneOfError = (fieldName, condition, value) =>
    {
        const errMsg = this.createAlertDescription(fieldName, condition, value,
            undefined, undefined) + " is redundant of the alert with '" +
            fieldName + " " + CONDITION_ONE_OF + "'.";

        return errMsg;
    }

    /**
     * Creates the error message when the condition and values of two alerts
     * with a numeric-valued field conflict with each other.
     *
     * @param {string}  fieldName       user-friendly name of the field
     * @param {string}  firstCondition  condition of the first alert
     * @param {string}  firstValue      value of the first alert
     * @param {string}  firstValue2     second field value if
     *                                  firstCondition is "Between"
     * @param {string}  secondCondition condition of the second alert
     * @param {string}  secondValue     value of the second alert
     * @param {string}  secondValue2    second field value if
     *                                  secondCondition is "Between"
     *
     * @return {string} the error message
     */
    createNumericFieldError = (fieldName,
        firstCondition, firstValue, firstValue2,
        secondCondition, secondValue, secondValue2) =>
    {
        let errMsg = "Alert " + this.createAlertDescription(fieldName,
            firstCondition, firstValue, firstValue2, undefined);
        errMsg = errMsg + " conflicts with alert ";
        errMsg = errMsg + this.createAlertDescription(fieldName,
            secondCondition, secondValue, secondValue2, undefined) + ".";

        return errMsg;
    }

    /**
     * Processes the cancel or close buttons being pressed by ignoring any
     * changes made and closing the modal.
     *
     * @param {object} unusedEvent   close button or background click, not used
     */
    processCancel = (unusedEvent) => {
        this.props.closeColorAlertsModal();
        this.setState({ isCurrentStateInitialized: false, currentAlerts: [],
            hasError: false, errorMsgs: undefined });
    }

    /**
     * Handles the user selection of a field by saving the field and
     * clearing all other elements in the alert.
     *
     * @param {object} selectedField  the desired field, e.g. COLUMNS.EOBT
     *                                Note this is the full field object,
     *                                not its name or key
     * @param {number} index          the index of the alert being edited
     */
    handleField = (selectedField, index) => {

        // Create an alert with only the field defined and all other elements
        // cleared
        const thisAlert = {};
        thisAlert.field = selectedField
        thisAlert.condition = undefined;
        thisAlert.value = undefined;
        thisAlert.value2 = undefined;
        thisAlert.valueOneOf = undefined;
        thisAlert.rgb = { ...WHITE };
        thisAlert.whiteText = false;

        // Replace the previous alert with this alert
        const currentAlerts = [ ...this.state.currentAlerts ];
        currentAlerts.splice(index, 1, thisAlert);

        // Save it
        this.setState({ currentAlerts });
    }

    /**
     * Handles the user selection of a condition by saving the condition and
     * then clearing all data values and the color in the alert.
     *
     * @param {string} condition    the condition, e.g. "="
     * @param {number} index        the index of the alert being edited
     */
    handleCondition = (condition, index) => {
        const currentAlerts = [ ...this.state.currentAlerts ];

        const thisAlert = { ...currentAlerts[index] };
        thisAlert.condition = condition;
        thisAlert.value = undefined;
        thisAlert.value2 = undefined;
        thisAlert.valueOneOf = undefined;
        thisAlert.rgb = { ...WHITE };
        thisAlert.whiteText = false;

        currentAlerts.splice(index, 1, thisAlert);

        this.setState({ currentAlerts });
    }

    /**
     * Handles the user selection of a single value by saving the value then
     * then clearing all other data values.  Leave the color as it was
     * in case the user is just tweaking a value.
     *
     * @param {string} value    the field value
     * @param {number} index    the index of the alert being edited
     */
    handleValue = (value, index) => {
        const currentAlerts = [ ...this.state.currentAlerts ];
        const thisAlert = { ...currentAlerts[index] };
        thisAlert.value = value;
        thisAlert.value2 = undefined;
        thisAlert.valueOneOf = undefined;

        currentAlerts.splice(index, 1, thisAlert);

        this.setState({ currentAlerts });
    }

    /**
     * Handles the user selection of a second value by saving the value and
     * clearing valueOneOf.  Leave the color as it was in case the user is 
     * just tweaking a value.  This method should only be called when the 
     * condition is "Between".
     *
     * @param {string} value2   the field's second value in a "Between"
     * @param {number} index    the index of the alert being edited
     */
    handleValue2 = (value2, index) => {
        const currentAlerts = [ ...this.state.currentAlerts ];
        const thisAlert = { ...currentAlerts[index] };
        thisAlert.value2 = value2;
        thisAlert.valueOneOf = undefined;

        currentAlerts.splice(index, 1, thisAlert);

        this.setState({ currentAlerts });
    }

    /**
     * Handles the user selection of a "One Of" value by saving that value
     * then clearing the other non-OneOf data values.  Leave the color as it 
     * was since the user can be adding or removing a value.  This method 
     * should only be called when the condition is "One Of" for an enum field.
     *
     * @param {string} value    a selected value from an enum
     * @param {number} index    the index of the alert being edited
     */
    handleValueOneOf = (value, index) => {
        const currentAlerts = [ ...this.state.currentAlerts ];
        const thisAlert = { ...currentAlerts[index] };
        thisAlert.value = undefined;
        thisAlert.value2 = undefined;
        if (!thisAlert.valueOneOf)
        {
            thisAlert.valueOneOf = [];
        }

        // If the user is re-selecting a value that was previously selected
        // then the intent is to de-select it, so remove it from the selection.
        let valueIndex = thisAlert.valueOneOf.findIndex(elem => elem === value);
        if (valueIndex >= 0)
        {
            thisAlert.valueOneOf.splice(valueIndex, 1);
        }
        // This is a new selection; save it.
        else
        {
            thisAlert.valueOneOf.push(value);
        }

        currentAlerts.splice(index, 1, thisAlert);

        this.setState({ currentAlerts });
    }

    /**
     * Handles the user clicking a field's Color button, which will cause
     * the color picker to open.
     *
     * @param {string}  name         the user-friendly name of the field
     * @param {object}  color        the current color of the alert
     * @param {number}  index        the index of the alert being edited
     * @param {boolean} whiteText    true to display the text in white
     *                               instead of black
     * @param {object}  unusedEvent  the button click event; unused
     */
    handleColorClick = (name, color, whiteText, index, unusedEvent) => {
        this.setState({ isEditingFieldColor: true,
            editingFieldColor: color,
            editingFieldColorIndex: index,
            editingFieldColorName: name,
            editingFieldColorWhiteText: whiteText,
        });
    };

    /**
     * Handles the user selecting a color from the color picker by saving
     * the color in the alert.
     *
     * @param {object}  newColor  the new color of the field
     * @param {boolean} whiteText true if the text should be white
     * @param {number}  index     the index of the alert being edited
     */
    handleSetColorChange = (newColor, whiteText, index) => {
        const currentAlerts = [ ...this.state.currentAlerts ];
        const thisAlert = { ...currentAlerts[index] };
        thisAlert.rgb = { ...newColor };
        thisAlert.whiteText = whiteText;

        currentAlerts.splice(index, 1, thisAlert);

        this.setState({ currentAlerts });
        this.finishedEditingColor();
    }

    /**
     * Handles the user cancelling a color change from the color picker by
     * clearing the color picker flags and settings
     */
    handleCancelColorChange = () => {
        this.finishedEditingColor();
    };

    /**
     * Cleans up the state when the color picker sub-modal closes.
     */
    finishedEditingColor = () => {
        this.setState({ isEditingFieldColor: false,
            editingFieldColor: { ...WHITE },
            editingFieldColorIndex: undefined,
            editingFieldColorName: undefined,
            editingFieldColorWhiteText: false,
        });
    };

    /**
     * Handles the user clicking an alert's Remove button, which will prompt
     * the user for confirmation.  Any or all of name, condition, or valueXxx
     * may be undefined based on what is in the row and what the condition is.
     * These values are used to create the confirmation dialog message.
     *
     * @param {string} name        the user-friendly name of the field
     * @param {object} condition   the condition of the alert to remove
     * @param {number} value       the value of the alert to remove
     * @param {number} value2      the second value of the alert to remove
     * @param {number} valueOneOf  the "one of" values of the alert to remove
     * @param {number} index       the index of the color alert being edited
     * @param {object} unusedEvent the button click event; unused
     */
    handleRemoveClick = (name, condition, value, value2,
        valueOneOf, index, unusedEvent) => {
        const oneOf = (valueOneOf ? [ ...valueOneOf ] : undefined);
        this.setState({ isRemovingAlert: true,
            removingAlertIndex: index,
            removingAlertName: name,
            removingAlertCondition: condition,
            removingAlertValue: value,
            removingAlertValue2: value2,
            removingAlertValueOneOf: oneOf,
        });
    };

    /**
     * Handles the user clicking the Remove button by removing the alert
     * then clearing the parameters for the remove confirmation dialog.
     */
    handleRemove = () => {
        const currentAlerts = [ ...this.state.currentAlerts ];
        currentAlerts.splice(this.state.removingAlertIndex, 1);

        this.setState({ currentAlerts,
            isRemovingAlert: false,
            removingAlertIndex: undefined,
            removingAlertName: undefined,
            removingAlertCondition: undefined,
            removingAlertValue: undefined,
            removingAlertValue2: undefined,
            removingAlertValueOneOf: undefined,
        });
    }

    /**
     * Handles the user cancelling a remove operation by clearing the
     * remove parameters.
     */
    handleCancelRemove = () => {
        this.setState({ isRemovingAlert: false,
            removingAlertIndex: undefined,
            removingAlertName: undefined,
            removingAlertCondition: undefined,
            removingAlertValue: undefined,
            removingAlertValue2: undefined,
            removingAlertValueOneOf: undefined,
        });
    }

    /**
     * Renders the color alert table.
     */
    renderAlerts()
    {
        return (
            <Table className="color-alert--table" size="sm"
                key={this.state.currentAlerts.length}
            >
                <thead>
                  <tr>
                      <th>Field</th>
                      <th>Condition</th>
                      <th>Value</th>
                      <th>Color</th>
                      <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                {
                    this.state.currentAlerts.map((alert, index) => {
                        const colorSpec = getCssColor(alert);
                        const buttonColor = colorSpec.backgroundColor;
                        const textColor = colorSpec.textColor;
                        const fieldKey = "field-" + index;
                        const condKey = "cond-" + index;
                        const valueKey = "value-" + index;
                        const selectedField = alert.field?.key;
                        const selectedFieldType = alert.field?.type;
                        const selectedFieldName = alert.field?.title;
                        // Color button is only enabled when all other elements
                        // are set
                        const disableButton = (this.state.isEditingFieldColor ||
                            !alert.field || !alert.condition ||
                            (((alert.condition === CONDITION_BETWEEN) &&
                                    (!alert.value || !alert.value2)) ||
                                 ((alert.condition === CONDITION_ONE_OF) &&
                                    !alert.valueOneOf) ||
                             ((alert.condition !== CONDITION_BETWEEN) &&
                                 (alert.condition !== CONDITION_ONE_OF) &&
                                 !alert.value)));
                        return (
                            <tr key={index}>
                                <td>
                                  <ColorAlertField key={fieldKey}
                                    selectedField={selectedField}
                                    setSelectedField={this.handleField}
                                    index={index}
                                  />
                                </td>
                                <td>
                                  <ColorAlertCondition key={condKey}
                                    selectedFieldType={selectedFieldType}
                                    selectedCondition={alert.condition}
                                    setSelectedCondition={this.handleCondition}
                                    index={index}
                                  />
                                </td>
                                <td>
                                  <ColorAlertValue key={valueKey}
                                    selectedFieldType={selectedFieldType}
                                    selectedCondition={alert.condition}
                                    selectedValue={alert.value}
                                    selectedValue2={alert.value2}
                                    selectedValueOneOf={alert.valueOneOf}
                                    setSelectedValue={this.handleValue}
                                    setSelectedValue2={this.handleValue2}
                                    setSelectedValueOneOf={this.handleValueOneOf}
                                    index={index}
                                  />
                                </td>
                                <td>
                                  <button
                                      style={{color:textColor,
                                              backgroundColor:buttonColor }}
                                      disabled={disableButton}
                                      onClick={(e) => this.handleColorClick(
                                         selectedFieldName, alert.rgb, 
                                         alert.whiteText, index, e)}
                                  >
                                      Color
                                  </button>
                                </td>
                                <td>
                                  <button className="remove-button"
                                      onClick={(e) => this.handleRemoveClick(
                                         selectedFieldName, alert.condition,
                                         alert.value, alert.value2,
                                         alert.valueOneOf, index, e)}
                                  >
                                      X
                                  </button>
                                </td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </Table>
        );
    }

    /**
     * Displays the color alert modal or one of its sub-modals.
     *
     * @return {JSX.element} null if the dialog is not open; otherwise, returns
     *                       one of the following:
     *                       - the main table with the color alerts,
     *                       - sub-modal: the color alert picker,
     *                       - sub-modal: the remove alert confirmation, or
     *                       - sub-modal: the alert validation error messages
     */
    render()
    {
        // If it's not open, return nothing.
        if (!this.props.isOpen)
        {
            return null;
        }

        // If the user wants to edit a color, display the color picker
        if (this.state.isEditingFieldColor)
        {
            return (
                <Modal id="color-picker-modal" show={true}
                    onHide={this.handleCancelColorChange} animation={false}
                >
                    <Modal.Header>
                        <Modal.Title>
                           Choose a Color for {this.state.editingFieldColorName}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ColorAlertPicker
                            alertColor={this.state.editingFieldColor}
                            alertWhiteText={this.state.editingFieldColorWhiteText}
                            alertIndex={this.state.editingFieldColorIndex}
                            setSelectedColor={this.handleSetColorChange}
                            cancelColorChange={this.handleCancelColorChange}
                        />
                    </Modal.Body>
                </Modal>
            );
        }

        // If the user wants to remove an alert, display a confirmation
        if (this.state.isRemovingAlert)
        {
            let lineOne = "Are you sure you want to remove the alert";
            let lineOneTerminator = "?";
            let lineTwo = "";
            let lineTwoTerminator = "";
            if (this.state.removingAlertName)
            {
                lineOne = lineOne.concat(" for");
                lineTwo = this.createAlertDescription(
                    this.state.removingAlertName,
                    this.state.removingAlertCondition,
                    this.state.removingAlertValue,
                    this.state.removingAlertValue2,
                    this.state.removingAlertValueOneOf);
                lineOneTerminator = "";
                lineTwoTerminator = "'?";
            }
            return (
                <Modal id="remove-color-alert-modal" show={true}
                    onHide={this.handleCancelRemove} animation={false}
                >
                    <Modal.Header>
                        <Modal.Title>
                           Remove Alert Confirmation
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>{lineOne}{lineOneTerminator}</h5>
                        <h5>{lineTwo}{lineTwoTerminator}</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary"
                            onClick={this.handleRemove}
                        >
                            Remove
                        </Button>
                        <Button variant="secondary"
                            onClick={this.handleCancelRemove}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }

        // If an error was found when closing the window, show it.
        if (this.state.hasError)
        {
            return (
                <Modal id="color-alert-error-modal" show={true} size="xl"
                    onHide={this.acknowledgeError} animation={false}
                >
                    <Modal.Header>
                        <Modal.Title>
                           Color Alert Error
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { this.state.errorMsgs.map((msg, index) =>
                            <h4 key={index}>{msg}</h4>
                          )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary"
                            onClick={this.acknowledgeError}
                        >
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }

        // Display the color alert table
        return (
            <Modal id="color-alerts-modal" show={true} size="lg"
                onHide={this.processCancel} animation={false}
            >
                <Modal.Header>
                    <Modal.Title>Color Alerts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.renderAlerts() }
                    <Button variant="primary"
                        onClick={this.addField}
                    >
                        Add Field
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                        onClick={this.processApply}
                    >
                        Apply
                    </Button>
                    <Button variant="secondary"
                        onClick={this.processCancel}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state   the current Redux state
 *
 * @return {object} The desired Redux state properties mapped to props
 */
const mapStateToProps = (state) =>
{
    return {
        isOpen: state.modalReducer.colorAlertsModalOpen,
        colorAlerts: state.modalReducer.colorAlerts,
    };
};

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    closeColorAlertsModal,
    updateColorAlerts,
};

export default connect(mapStateToProps, mapDispatchToProps) (ColorAlertsModal);
